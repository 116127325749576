import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'sa-app-prod-stats-collaboration',
  templateUrl: './prod-stats-collaboration.component.html',
  styleUrls: ['./prod-stats-collaboration.component.scss']
})
export class ProdStatsCollaborationComponent {

  @Input() leftGaugeName: string;
  @Input() leftGauge: number
  @Input() leftGaugeHML: string
  @Input() leftGaugeColor = 'black'
  @Input() leftGaugeDescription: string

  @Input() rightGaugeName: string;
  @Input() rightGauge: number
  @Input() rightGaugeHML: string
  @Input() rightGaugeColor = 'yellow'
  @Input() rightGaugeDescription: string

  constructor() {}
}
