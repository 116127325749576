import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Action } from 'redux';
import * as _ from 'lodash'

import { IGroupsState, IGroupsHashMap, IGroup } from '../../app.state'
import { DataService } from '../../services/data.service'
import * as util from '../../services/util.service'

const trace = util.traceToggle(false)

export interface INavAction extends Action {
  id?: number,
  searchText?: string,
  groups?: any
}

@Injectable()
export class NavActions {

  static readonly FETCH_GROUPS         = 'nav bar fetch groups'
  static readonly FETCH_GROUPS_SUCCESS = 'nav bar fetch groups success'
  static readonly FETCH_GROUPS_ERROR   = 'nav bar fetch groups error'

  static readonly SELECT_GROUP = 'nav bar select group';
  static readonly DESELECT_GROUP = 'nav bar deselect group';
  static readonly EXPAND_GROUP = 'nav bar expand group';
  static readonly COLLAPSE_GROUP = 'nav bar collapse group';
  static readonly EXPAND_CLICKED = 'nav bar expand clicked'
  static readonly SELECT_CLICKED = 'nav bar select clicked'

  static readonly GROUP_SEARCH_CHANGED = 'nav bar group search changed'

  keysMap: number[] = null

  constructor(private ngRedux: NgRedux<IGroupsState>, private dm: DataService) {}

  getKeysMap( groups: IGroupsHashMap ) {
    // if (this.keysMap !== null) { return this.keysMap }
    const keys: number[] = []
    _.forEach (groups, (g: IGroup, k) => { keys[g.gid] = Number.parseInt(k, 0) })
    this.keysMap = keys
    return keys
  }

  selectClicked(gid: number): void {
    const groups: IGroupsHashMap = this.ngRedux.getState().groups['groups']
    const id = this.getKeysMap(groups)[gid]
    const isSelected = groups[id].isSelected
    if (isSelected) {
      this.ngRedux.dispatch({type: NavActions.DESELECT_GROUP, id: id})
    } else {
      this.ngRedux.dispatch({type: NavActions.SELECT_GROUP, id: id})
    }
  }

  expandClicked(gid: number): void {
    const groups: IGroupsHashMap = this.ngRedux.getState().groups['groups']
    const id = this.getKeysMap(groups)[gid]

    const isExpanded = groups[id].isExpanded
    if (isExpanded) {
      this.ngRedux.dispatch({type: NavActions.COLLAPSE_GROUP, id: id})
    } else {
      this.ngRedux.dispatch({type: NavActions.EXPAND_GROUP, id: id})
    }
  }

  selectGroup(id: number): void {
    this.ngRedux.dispatch({type: NavActions.SELECT_GROUP, id: id})
  }

  deselectGroup(id: number): void {
    this.ngRedux.dispatch({type: NavActions.DESELECT_GROUP, id: id})
  }

  expandGroup(id: number): void {
    this.ngRedux.dispatch({type: NavActions.EXPAND_GROUP, id: id})
  }

  collapseGroup(id: number): void {
    this.ngRedux.dispatch({type: NavActions.COLLAPSE_GROUP, id: id})
  }

  groupSearChanged(text: string): void {
    this.ngRedux.dispatch({type: NavActions.GROUP_SEARCH_CHANGED, searchText: text})
  }

  fetchGroups(sid: number = null, qid: number = null) {
    trace('NavActions - In fetchGroups(), sid: ', sid)
    this.ngRedux.dispatch({type: NavActions.FETCH_GROUPS})
    let params = {}
    if (qid !== null) {
      params = {sid: sid, qid: qid}
    } else {
      params = {sid: sid}
    }
    this.dm.getGroupsData(params, (res) => {
      trace('NavActions - In fetchGroups() - fetch success 1: res = ', res)
      this.fetchGroupsSuccess(res)
    })
  }

  fetchGroupsSuccess(res) {
    setTimeout(() => {
      this.ngRedux.dispatch({type: NavActions.FETCH_GROUPS_SUCCESS, groups: res.groups})
    }, 200)
  }

  fetchGroupsError() {
    this.ngRedux.dispatch({type: NavActions.FETCH_GROUPS_ERROR})
  }
}
