import * as _ from 'lodash'
import * as Util from '../../services/util.service'

import { IDynamicsAction, DynamicsActions } from './dynamics.actions';
import {
  IDynamicsState,
  IDynamicsProdStats,
  INITIAL_STATE_DYNAMICS,
  IMapData
} from '../../app.state'

import { TenBucket } from '../../types/ten-bucket'


const trace = Util.traceToggle(false);

export function dynamicsReducer(lastState: IDynamicsState, action: IDynamicsAction): IDynamicsState {
  if (lastState === undefined) { return INITIAL_STATE_DYNAMICS}
  switch (action.type) {
    case DynamicsActions.FETCH_DYNAMICS_STATS:
      trace('DynamicsActions.FETCH_DYNAMICS_STATS');
      return lastState;
    case DynamicsActions.FETCH_DYNAMICS_STATS_SUCCESS:
      trace('DynamicsActions.FETCH_DYNAMICS_STATS_SUCCESS');
      const coll = TenBucket.createFromZScore(action.prodStats.closeness)
      const syn  = TenBucket.createFromZScore( (-1) * action.prodStats.synergy )

      return Object.assign({}, lastState, {prodStats: {
                                            closeness: coll,
                                            synergy: syn
                                          }
              });
    case DynamicsActions.FETCH_DYNAMICS_TIME_PICKER_DATA:
      trace('DynamicsActions.FETCH_DYNAMICS_TIME_PICKER_DATA');
      return lastState;
    case DynamicsActions.FETCH_DYNAMICS_TIME_PICKER_DATA_SUCCESS:
      trace('DynamicsActions.FETCH_DYNAMICS_TIME_PICKER_DATA_SUCCESS');
      return Object.assign({}, lastState, {timePickerData: action.timePickerData});
    case DynamicsActions.AGGREGATOR_TYPE_CHANGED:
      trace('DynamicsActions.AGGREGATOR_TYPE_CHANGED');
      return Object.assign({}, lastState, {aggregator: action.aggregator});
    case DynamicsActions.USER_TOGGLED_VIEW_STATE:
      trace('DynamicsActions.USER_TOGGLED_VIEW_STATE');
      return Object.assign({}, lastState, {selectedView: action.selectedView});
    case DynamicsActions.FETCH_DYNAMICS_SCORES:
      trace('DynamicsActions.FETCH_DYNAMICS_SCORES');
      return lastState;
    case DynamicsActions.FETCH_DYNAMICS_SCORES_SUCCESS:
      trace('DynamicsActions.FETCH_DYNAMICS_SCORES_SUCCESS')
      return Object.assign({}, lastState, {scores: action.dynamicsScores})
    case DynamicsActions.USER_SELECTED_SEGMENTS:
      trace('DynamicsActions.USER_SELECTED_SEGMENTS')
      return Object.assign({}, lastState, {selectedSegments: action.selectedSegments})
    case DynamicsActions.FETCH_EMPLOYEES:
      trace('DynamicsActions.FETCH_EMPLOYEES');
      return lastState;
    case DynamicsActions.FETCH_EMPLOYEES_SUCCESS:
      trace('DynamicsActions.FETCH_EMPLOYEES_SUCCESS')
      return Object.assign({}, lastState, {employeeScores: action.employeeScores})

    case DynamicsActions.FETCH_EMPLOYEES_STATS:
      trace('DynamicsActions.FETCH_EMPLOYEES_STATS')
      return lastState
    case DynamicsActions.FETCH_EMPLOYEES_STATS_SUCCESS:
      trace('DynamicsActions.FETCH_EMPLOYEES_STATS_SUCCESS')
      const stats: IDynamicsProdStats = {
        closeness: null,
        synergy: TenBucket.createFromZScore( (-1) * action.employeeStats.synergy )
      }
      return Object.assign({}, lastState, {leaderboardStats: stats})

    case DynamicsActions.FETCH_DYNAMICS_GROUP_MAP:
    case DynamicsActions.FETCH_DYNAMICS_EMPLOYEE_MAP:
      trace('DynamicsActions.FETCH_DYNAMICS_GROUP_MAP')
      return lastState
    case DynamicsActions.FETCH_DYNAMICS_GROUP_MAP_FAIL:
    case DynamicsActions.FETCH_DYNAMICS_EMPLOYEE_MAP_FAIL:
      trace('DynamicsActions.FETCH_DYNAMICS_GROUP_MAP_FAIL')
      return lastState

    default:
      return lastState
  }
}


