import { Component, Input, OnInit} from '@angular/core';
import { Observable } from 'rxjs/Observable';

import * as _ from 'lodash'

import { IEmployeeScoreRow } from '../../app.state'
import * as util from '../../services/util.service'
import * as consts from '../../constants'


/////////////////////////// Types //////////////////////////////////////////////
type TEmpScoresFilter = (a: IEmployeeScoreRow[]) => IEmployeeScoreRow[]

/////////////////////////// General Definitions ////////////////////////////////
const trace = util.traceToggle(false)

/////////////////////////// Functions ////////////////////////////////
const sortByScore: TEmpScoresFilter =
         _.curryRight(_.sortBy, 2)('score')

const fixBarLength: TEmpScoresFilter = (arr: IEmployeeScoreRow[]) => {
  if (arr === undefined || arr.length === 0) { return [] }
  const max: number = arr[0].score
  return _.map(arr, (emp) => {
    emp.scoreBar = Math.round((emp.score * 100) / max)
    return emp
  })
}

/**
 * Receive array of scores, sort them, reverse the order and set the length of the bar to be displayed
 * in the UI.
 */
export const getEmpsAndSort: TEmpScoresFilter = util.compose([fixBarLength, _.reverse, sortByScore])

/////////////////////////// Component ////////////////////////////////
@Component({
  selector: 'sa-emps-time-spent',
  templateUrl: './emps-time-spent.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class EmpsTimeSpentComponent implements OnInit {

  /////////////////////////// Instance variables ////////////////////////////////
  @Input() employeeScores$: Observable<IEmployeeScoreRow[]>;
  employeeScores: IEmployeeScoreRow[]

  @Input() tabColor: string

  constructor() {}

  ngOnInit() {
    this.employeeScores$.subscribe( (state: IEmployeeScoreRow[]) => {
      trace('EmpsTimeSpentComponent - Detected employeesScores$ change, state: ', state)
      if (state === undefined) { return }
      this.employeeScores = getEmpsAndSort(state)
    })
  }
}
