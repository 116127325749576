import { Injectable } from '@angular/core'
import { NgRedux } from '@angular-redux/store'
import { Action } from 'redux'
import * as _ from 'lodash'
import { Subscription } from 'rxjs/Subscription'
import { Observable } from 'rxjs/Observable'

import {
  IInteractState,
  InteractTab,
  InteractQuestTab,
  IQuest,
  IInteractQuestion,
  IInteractParticipant,
  IParticipantStatus,
  IInteractTest,
  IFactor,
  ICompany,
  IManageUser
 } from '../../app.state'

import { DataService } from '../../services/data.service'
import * as util from '../../services/util.service'
import { AjaxService } from '../../services/ajax.service'
import { AppActions } from '../../app.actions'

const trace = util.traceToggle(false)

export interface IInteractAction extends Action {
  menuItem?: InteractTab
  mgmtMenuItem?: InteractQuestTab
  selectedQuestId?: number
  questionnaires?: IQuest[]
  questionnaire?: IQuest
  qid?: number
  qqid?: number
  errors?: string[]
  questions?: IInteractQuestion[]
  participants?: IInteractParticipant[]
  participantsOffset?: number
  testUserUrl?: string
  collaboration_results?: any[]
  collaboration_metrics_results?: any[]
  collaborationScore?: number
  synergyScore?: number
  centralityScore?: number
  searchText?: string
  participantStatus?: IParticipantStatus
  statsArr?: number[] 
  fullState?: IInteractState
  activeQuestStats?: number[]
  factors?: IFactor[]
  active_params?: any
  slider_val?: number
  uploadInfo?: string[]
  isLastImage?: boolean,
  lengthOfPhotos?: number,
  companies?: ICompany[],
  users?: IManageUser[],
  companyId?: number,
  selectedUserMap?: number
}

@Injectable()
export class InteractActions {

  static readonly INTERACT_MGMT_ERRORBOX_CLOSE = 'interact errorbox close'

  static readonly INTERACT_MGMT_SET_STATE_FROM_URL = 'interact set state from url'

  static readonly INTERACT_MENU_ITEM_CLICKED   = 'interact menu clicked'
  static readonly INTERACT_MGMT_MENU_CLICKED = 'interact mgmt menu clicked'

  static readonly INTERACT_MAP_NAV_TOGGLE = 'interact map nav toggle'

  static readonly INTERACT_MGMT_QUEST_SELECTED = 'interact mgmt quest selected'
  static readonly INTERACT_MGMT_QUEST_OPENED = 'interact mgmt quest opened'
  static readonly INTERACT_MGMT_QUEST_CLOSE  = 'interact mgmt quest close'

  static readonly INTERACT_MGMT_FETCH_QUESTIONNAIRES = 'interact mgmt fetch questionnaires'
  static readonly INTERACT_MGMT_FETCH_QUESTIONNAIRES_SUCCESS = 'interact mgmt fetch questionnaires success'
  static readonly INTERACT_MGMT_FETCH_QUESTIONNAIRES_FAIL = 'interact mgmt fetch questionnaires fail'
  static readonly INTERACT_MGMT_NEW_QUESTIONAIRE = 'interact mgmt new questionnaire'
  static readonly INTERACT_MGMT_NEW_QUESTIONAIRE_SUCCESS = 'interact mgmt new questionnaire success'
  static readonly INTERACT_MGMT_NEW_QUESTIONAIRE_FAIL = 'interact mgmt new questionnaire fail'
  static readonly INTERACT_MGMT_UPDATE_QUESTIONAIRE = 'interact mgmt update questionnaire'
  static readonly INTERACT_MGMT_UPDATE_QUESTIONAIRE_SUCCESS = 'interact mgmt update questionnaire success'
  static readonly INTERACT_MGMT_UPDATE_QUESTIONAIRE_FAIL = 'interact mgmt update questionnaire fail'
  static readonly INTERACT_MGMT_DELETE_QUESTIONAIRE = 'interact mgmt delete questionnaire'
  static readonly INTERACT_MGMT_DELETE_QUESTIONAIRE_SUCCESS = 'interact mgmt delete questionnaire success'
  static readonly INTERACT_MGMT_DELETE_QUESTIONAIRE_FAIL = 'interact mgmt delete questionnaire fail'

  static readonly INTERACT_MGMT_FETCH_QUESTIONS = 'interact mgmt fetch questions'
  static readonly INTERACT_MGMT_FETCH_QUESTIONS_SUCCESS = 'interact mgmt fetch questions success'
  static readonly INTERACT_MGMT_UPDATE_QUESTION = 'interact mgmt update question'
  static readonly INTERACT_MGMT_UPDATE_QUESTION_SUCCESS = 'interact mgmt update question success'
  static readonly INTERACT_MGMT_UPDATE_QUESTION_FAIL    = 'interact mgmt update question fail'
  static readonly INTERACT_MGMT_NEW_QUESTION = 'interact mgmt new question'
  static readonly INTERACT_MGMT_NEW_QUESTION_SUCCESS = 'interact mgmt new question success'
  static readonly INTERACT_MGMT_NEW_QUESTION_FAIL    = 'interact mgmt new question fail'
  static readonly INTERACT_MGMT_DELETE_QUESTION = 'interact mgmt delete question'
  static readonly INTERACT_MGMT_DELETE_QUESTION_SUCCESS = 'interact mgmt delete question success'
  static readonly INTERACT_MGMT_DELETE_QUESTION_FAIL    = 'interact mgmt delete question fail'
  static readonly INTERACT_MGMT_REORDER_QUESTIONS = 'interact mgmt reorder questions'
  static readonly INTERACT_MGMT_REORDER_QUESTIONS_SUCCESS = 'interact mgmt reorder questions success'
  static readonly INTERACT_MGMT_REORDER_QUESTIONS_FAIL    = 'interact mgmt reorder questions fail'
  static readonly INTERACT_MGMT_SET_ACTIVE_QUESTION = 'interact mgmt set active question'
  static readonly INTERACT_MGMT_SET_QUESTIONAIRE_ACTIVE_QUESTION = 'interact mgmt set questionnaire active question'
  static readonly INTERACT_MGMT_SET_QUESTIONAIRE_ACTIVE_QUESTION_SUCCESS = 'interact mgmt set questionnaire active question success'
  static readonly INTERACT_MGMT_SET_QUESTIONAIRE_ACTIVE_QUESTION_FAIL = 'interact mgmt set questionnaire active question fail'

  static readonly INTERACT_MGMT_FETCH_FACTORS = 'interact mgmt fetch factors'
  static readonly INTERACT_MGMT_FETCH_FACTORS_SUCCESS = 'interact mgmt fetch factors success'
  static readonly INTERACT_MGMT_SAVE_PARAMS = 'interact mgmt save params'
  static readonly INTERACT_MGMT_SAVE_PARAMS_SUCCESS = 'interact mgmt save params success'
  static readonly INTERACT_MGMT_SAVE_PARAMS_FAIL = 'interact mgmt save params fail'

  static readonly INTERACT_MGMT_SAVE_K_FACTOR = 'interact mgmt save K_factor'
  static readonly INTERACT_MGMT_SAVE_K_FACTOR_SUCCESS = 'interact mgmt save K_factor success'
  static readonly INTERACT_MGMT_SAVE_K_FACTOR_FAIL = 'interact mgmt save K_factor fail'

  
  static readonly INTERACT_MGMT_FETCH_PARTICIPANTS = 'interact mgmt fetch participants'
  static readonly INTERACT_MGMT_FETCH_PARTICIPANTS_SUCCESS = 'interact mgmt fetch participants success'
  static readonly INTERACT_MGMT_FETCH_PARTICIPANTS_FAIL = 'interact mgmt fetch participants fail'

  static readonly INTERACT_MGMT_CLEAR_PARTICIPANTS = 'interact mgmt clear participants'

  static readonly INTERACT_MGMT_REMOVE_PARTICIPANTS = 'interact mgmt remove participants'
  static readonly INTERACT_MGMT_REMOVE_PARTICIPANTS_FAIL = 'interact mgmt remove participants fail'
  static readonly INTERACT_MGMT_REMOVE_PARTICIPANTS_SUCCESS = 'interact mgmt remove participants success'

  static readonly INTERACT_MGMT_PARTICIPANTS_SET_SEARCH_TEXT = 'interact mgmt participants set search text'

  static readonly INTERACT_MGMT_UPDATE_PARTICIPANT = 'interact mgmt update participant'
  static readonly INTERACT_MGMT_UPDATE_PARTICIPANT_SUCCESS = 'interact mgmt update participant success'
  static readonly INTERACT_MGMT_UPDATE_PARTICIPANT_FAIL    = 'interact mgmt update participant fail'
  static readonly INTERACT_MGMT_NEW_PARTICIPANT = 'interact mgmt new participant'
  static readonly INTERACT_MGMT_NEW_PARTICIPANT_SUCCESS = 'interact mgmt new participant success'
  static readonly INTERACT_MGMT_NEW_PARTICIPANT_FAIL    = 'interact mgmt new participant fail'
  static readonly INTERACT_MGMT_DELETE_PARTICIPANT = 'interact mgmt delete participant'
  static readonly INTERACT_MGMT_DELETE_PARTICIPANT_SUCCESS = 'interact mgmt delete participant success'
  static readonly INTERACT_MGMT_DELETE_PARTICIPANT_FAIL    = 'interact mgmt delete participant fail'
  static readonly INTERACT_MGMT_RESEND_PARTICIPANT = 'interact mgmt resend participant'
  static readonly INTERACT_MGMT_RESEND_PARTICIPANT_SUCCESS = 'interact mgmt resend participant success'
  static readonly INTERACT_MGMT_RESEND_PARTICIPANT_FAIL    = 'interact mgmt resend participant fail'
  static readonly INTERACT_MGMT_RESET_PARTICIPANT = 'interact mgmt reset participant'
  static readonly INTERACT_MGMT_RESET_PARTICIPANT_SUCCESS = 'interact mgmt reset participant success'
  static readonly INTERACT_MGMT_RESET_PARTICIPANT_FAIL    = 'interact mgmt reset participant fail'
  static readonly INTERACT_MGMT_GET_PARTICIPANT_STATUS = 'interact mgmt get participant status'
  static readonly INTERACT_MGMT_GET_PARTICIPANT_STATUS_SUCCESS = 'interact mgmt get participant status success'
  static readonly INTERACT_MGMT_GET_PARTICIPANT_STATUS_FAIL    = 'interact mgmt get participant status fail'
  static readonly INTERACT_MGMT_PARTICIPANT_STATUS_DIALOG_CLOSE    = 'interact mgmt participant status dialog close'
  static readonly INTERACT_MGMT_GET_QUESTIONNAIRE_STATUS    = 'interact mgmt questionnaire status'
  static readonly INTERACT_MGMT_GET_QUESTIONNAIRE_STATUS_SUCCESS    = 'interact mgmt questionnaire status success'

  static readonly INTERACT_MGMT_CLOSE_PARTICIPANT_QUESTIONNAIRE = 'interact mgmt close participant questionnaire'
  static readonly INTERACT_MGMT_CLOSE_PARTICIPANT_QUESTIONNAIRE_SUCCESS = 'interact mgmt close participant questionnaire success'
  static readonly INTERACT_MGMT_CLOSE_PARTICIPANT_QUESTIONNAIRE_FAIL    = 'interact mgmt close participant questionnaire fail'

  static readonly INTERACT_MGMT_UPLOAD_PARTICIPANTS = 'interact mgmt upload participants'
  static readonly INTERACT_MGMT_UPLOAD_PARTICIPANTS_SUCCESS = 'interact mgmt upload participants success'
  static readonly INTERACT_MGMT_UPLOAD_PARTICIPANTS_FAIL = 'interact mgmt upload participants fail'
  static readonly INTERACT_MGMT_UPLOAD_PHOTO = 'interact mgmt upload photo'
  static readonly INTERACT_MGMT_UPLOAD_PHOTO_SUCCESS = 'interact mgmt upload photo success'
  static readonly INTERACT_MGMT_UPLOAD_PHOTO_FAIL = 'interact mgmt upload photo fail'

  static readonly INTERACT_MGMT_REFRESH_PARTICIPANTS = 'interact mgmt refresh participants'
  static readonly INTERACT_MGMT_REFRESH_PARTICIPANTS_SUCCESS = 'interact mgmt refresh participants success'
  static readonly INTERACT_MGMT_REFRESH_PARTICIPANTS_FAIL = 'interact mgmt refresh participants fail'
  static readonly INTERACT_MGMT_GET_USER_MAP = 'interact mgmt get user map'

  static readonly INTERACT_MGMT_RUN_QUESITONNAIRE = 'interact mgmt run questionnaire'
  static readonly INTERACT_MGMT_RUN_QUESITONNAIRE_SUCCESS = 'interact mgmt upload run questionnaire success'
  static readonly INTERACT_MGMT_RUN_QUESITONNAIRE_FAIL = 'interact mgmt run questionnaire fail'

  static readonly INTERACT_MGMT_CLOSE_QUESITONNAIRE = 'interact mgmt close questionnaire'
  static readonly INTERACT_MGMT_CLOSE_QUESITONNAIRE_SUCCESS = 'interact mgmt close questionnaire success'
  static readonly INTERACT_MGMT_CLOSE_QUESITONNAIRE_FAIL = 'interact mgmt close questionnaire fail'

  static readonly INTERACT_MGMT_UPDATE_TEST_PARTICIPANT = 'interact mgmt update test participant'
  static readonly INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_SUCCESS = 'interact mgmt update test participant success'
  static readonly INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_FAIL    = 'interact mgmt update test participant fail'

  static readonly INTERACT_COLL_FETCH_QUEST_DATA = 'interact coll fetch quest data'
  static readonly INTERACT_COLL_FETCH_QUEST_DATA_SUCCESS = 'interact coll fetch quest data success'
  static readonly INTERACT_COLL_FETCH_QUEST_DATA_FAIL = 'interact coll fetch quest data fail'

  static readonly INTERACT_COLL_FETCH_QUEST_STATS = 'interact coll fetch quest stats'
  static readonly INTERACT_COLL_FETCH_QUEST_STATS_SUCCESS = 'interact coll fetch quest stats success'
  static readonly INTERACT_COLL_FETCH_QUEST_STATS_FAIL = 'interact coll fetch quest stats fail'

  static readonly INTERACT_MGMT_COPY_QUESITONNAIRE = 'interact mgmt copy questionnaire'
  static readonly INTERACT_MGMT_COPY_QUESITONNAIRE_SUCCESS = 'interact mgmt copy questionnaire success'
  static readonly INTERACT_MGMT_COPY_QUESITONNAIRE_FAIL = 'interact mgmt copy questionnaire fail'

  static readonly INTERACT_MGMT_FOLLOWUP_QUESITONNAIRE = 'interact mgmt followup questionnaire'
  static readonly INTERACT_MGMT_FOLLOWUP_QUESITONNAIRE_SUCCESS = 'interact mgmt followup questionnaire success'
  static readonly INTERACT_MGMT_FOLLOWUP_QUESITONNAIRE_FAIL = 'interact mgmt followup questionnaire fail'

  static readonly INTERACT_MGMT_SIMULATE_QUESTIONNAIRE_REPLIES = 'interact mgmt simulate questionnaire replies'

  static readonly INTERACT_MGMT_CLEAR_INTERACT_STATE = 'interact mgmt clear interact state'
  static readonly INTERACT_MGMT_UPLOAD_INFO_CLOSE = 'interact mgmt upload info close'

  static readonly INTERACT_MGMT_GET_COMPANIES = 'interact mgmt get companies'
  static readonly INTERACT_MGMT_GET_COMPANIES_SUCCESS = 'interact mgmt get companies success'
  static readonly INTERACT_MGMT_GET_COMPANIES_FAIL = 'interact mgmt get companies fail'

  static readonly INTERACT_MGMT_GET_COMPANY_DATA = 'interact mgmt get company data'
  static readonly INTERACT_MGMT_GET_COMPANY_DATA_SUCCESS = 'interact mgmt get company data success'
  static readonly INTERACT_MGMT_GET_COMPANY_DATA_FAIL = 'interact mgmt get company data fail'

  static readonly INTERACT_UPDATE_COMPANY = 'interact update company'
  static readonly INTERACT_UPDATE_COMPANY_SUCCESS = 'interact update company sauccess'
  static readonly INTERACT_UPDATE_COMPANY_FAIL = 'interact update company fail'

  static readonly INTERACT_ADD_COMPANY = 'interact add company'
  static readonly INTERACT_ADD_COMPANY_SUCCESS = 'interact add company sauccess'
  static readonly INTERACT_ADD_COMPANY_FAIL = 'interact add company fail'

  static readonly INTERACT_MGMT_GET_USERS = 'interact mgmt get users'
  static readonly INTERACT_MGMT_GET_USERS_SUCCESS = 'interact mgmt get users success'

  static readonly INTERACT_MGMT_UPDATE_USER = 'interact mgmt update user'
  static readonly INTERACT_MGMT_UPDATE_USER_SUCCESS = 'interact mgmt update user success'
  static readonly INTERACT_MGMT_UPDATE_USER_FAIL = 'interact mgmt update user fail'
 
  static readonly INTERACT_MGMT_CREATE_USER = 'interact create user'
  static readonly INTERACT_MGMT_CREATE_USER_SUCCESS = 'interact create user success'
  static readonly INTERACT_MGMT_CREATE_USER_FAIL = 'interact create user fail'
  static readonly INTERACT_MGMT_DELETE_USER = 'interact delete user'
  static readonly INTERACT_MGMT_DELETE_USER_SUCCESS = 'interact delete user success'
  static readonly INTERACT_MGMT_DELETE_USER_FAIL = 'interact delete user fail'
  static readonly INTERACT_MGMT_COMPANY_SELECTED = 'interact mgmt company selected'
  static readonly INTERACT_MGMT_COMPANY_OPENED = 'interact mgmt company opened'
  static readonly INTERACT_MGMT_COMPANY_CLOSE = 'interact mgmt company close'

  static readonly INTERACT_MAP_FIND_PARTICIPANT = 'interact map find participant'

  constructor(private ngRedux: NgRedux<IInteractState>,
              private ds: DataService,
              private as: AjaxService,
              private appActions: AppActions) {}

  errrBoxClose = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_ERRORBOX_CLOSE})
  }

  uploadInfoClose = (qid)=>{
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_INFO_CLOSE})
    this.refreshParticipants(qid)
  }

  setStateFromIInteractState = (state: IInteractState) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_SET_STATE_FROM_URL,
       fullState: state
    })
  }
/******************* Questions ******************************/

  /**
   * Get participant status
   */
  fetchParticipantStatus = (qpid: number, qid:number) => {
    trace('InteractActions - In fetchParticipantStatus(), qpid: ', qpid)
    if (qpid === undefined) {return}
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_PARTICIPANT_STATUS})
    const params = {qpid: qpid, qid: qid}

    this.ds.getParticipantStatus(params, this.fetchParticipantStatusSuccess)
  }
  fetchParticipantStatusSuccess = (res) => {
    console.log('InteractActions - In fetchParticipantStatusSuccess() - fetch success: res = ', res.data)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_GET_PARTICIPANT_STATUS_SUCCESS,
      participantStatus: res.data
    })
  }

  closeParticipantsStatusDialog = () => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_PARTICIPANT_STATUS_DIALOG_CLOSE
    })
  }

  refreshParticipants = (qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REFRESH_PARTICIPANTS})
    this.as.get('/interact_backoffice/participants_refresh',
    {qid: qid},
    this.ds.handlePostResult(this.refreshParticipantSuccess,
                             this.refreshParticipantFail),
    this.refreshParticipantFail
)

  }

  refreshParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_REFRESH_PARTICIPANTS_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
  }
  refreshParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REFRESH_PARTICIPANTS_FAIL})
    console.error(err)
  }


  /**
   * Fetch participants
   */
  fetchParticipants = (qid: number, page: number = 0, sText: string = null) => {
    trace('InteractActions - In fetchParticipants(), qid: ', qid, ', page: ', page)
    if (qid === undefined) {return}
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_FETCH_PARTICIPANTS,
                          participantsOffset: page})

    const params = {qid: qid, page: page}
    let searchText = sText
    if (_.isNil(searchText)) {
      searchText = ( this.ngRedux.getState() as any).interact.participantsSearchText
    }

    if ( !_.isNil(searchText) )  {
      ( params as any).searchText = searchText
    }

    this.ds.getParticipants(
      params,
      this.fetchParticipantsSuccess
    )
  }
  fetchParticipantsSuccess = (res) => {
    trace('InteractActions - In fetchParticipants() - fetch success: res = ', res)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_PARTICIPANTS_SUCCESS,
      participants: res.data
    })
  }

  /**
   * Filter participants
   */
  filterParticipants = (qid: number, page: number = 0, searchText: string = null) => {
    trace('InteractActions - In filterParticipants(), qid: ', qid, ', page: ', page)
    if (qid === undefined) {return}
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLEAR_PARTICIPANTS})
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_PARTICIPANTS_SET_SEARCH_TEXT, searchText: searchText})
    this.fetchParticipants(qid, page, searchText)
  }

  /**
   * Participant update
   */
  updateParticipant = (participant: IInteractParticipant, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT, participant: participant})
    this.as.post('/interact_backoffice/participants_update',
        {participant: participant,
        qid: qid},
        this.ds.handlePostResult(this.updateParticipantSuccess,
                                 this.updateParticipantFail),
        this.updateParticipantFail
    )
  }
  updateParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
    this.appActions.spinnerOff()
    alert('Participant was updated')
  }
  updateParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to update participant')
  }

  /**
   * New participant
   */
  newParticipant = (participant: IInteractParticipant, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_PARTICIPANT, participant: participant})
    this.as.post('/interact_backoffice/participants_create',
        {participant: participant,
        qid: qid},
        this.ds.handlePostResult(this.newParticipantSuccess,
                                 this.newParticipantFail),
        this.newParticipantFail
    )
  }
  newParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_NEW_PARTICIPANT_SUCCESS,
      questionnaire: data.questionnaire,
      participants: data.participants
    })
    this.appActions.spinnerOff()
    alert('Participant was created')
  }
  newParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to create participant')
  }

  /**
   * Delete participant
   */
  deleteParticipant = (pid: number, qid:number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT})
    this.as.post('/interact_backoffice/participants_delete',
        {qpid: pid,
        qid: qid},
        this.ds.handlePostResult(this.deleteParticipantSuccess,
                                 this.deleteParticipantFail),
        this.deleteParticipantFail
    )
  }
  deleteParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
    this.appActions.spinnerOff()
    alert('Participant deleted')
  }
  deleteParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to delete participant')
  }

  getQuestionnaireStatus = (qid: number) => {
    // this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_QUESTIONNAIRE_STATUS})
    const params = {qid: qid}
    this.ds.getQuestionnaireStatus(params, this.fetchQuestionnaireStatusSuccess)
  }
  fetchQuestionnaireStatusSuccess = (res) =>{
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_GET_QUESTIONNAIRE_STATUS_SUCCESS,
      activeQuestStats: res.data
    })
  }

  /**
   * Reset participant
   */
  resetParticipant = (pid: number, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESET_PARTICIPANT})
    this.as.post('/interact_backoffice/participant_reset',
        {qpid: pid, qid:qid},
        this.ds.handlePostResult(this.resetParticipantSuccess,
                                 this.resetParticipantFail),
        this.resetParticipantFail
    )
  }
  resetParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_RESET_PARTICIPANT_SUCCESS,
      participants: data.participants
    })
    this.appActions.spinnerOff()
    alert('Questionnaire was reset')
  }
  resetParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESET_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to reset questionnaire')
  }

  /**
   * Close a participant's questionnaire
   */
    closeQuestionnaireOfParticipant = (qpid: number, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLOSE_PARTICIPANT_QUESTIONNAIRE})
    this.as.post('/interact_backoffice/close_participant_questionnaire',
        {qpid: qpid,
        qid: qid},
        this.ds.handlePostResult(this.closeQuestionnaireOfParticipantSuccess,
                                 this.closeQuestionnaireOfParticipantFail),
        this.closeQuestionnaireOfParticipantFail
    )
  }
  closeQuestionnaireOfParticipantSuccess = () => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_CLOSE_PARTICIPANT_QUESTIONNAIRE_SUCCESS
    })
    this.appActions.spinnerOff()
    alert('Questionnaire successfully closed')
  }
  closeQuestionnaireOfParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLOSE_PARTICIPANT_QUESTIONNAIRE_SUCCESS})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to reset questionnaire')
  }

  /**
   * Resend participant
   */
  resendParticipant = (pid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESEND_PARTICIPANT})
    this.as.post('/interact_backoffice/participant_resend',
        {qpid: pid},
        this.ds.handlePostResult(this.resendParticipantSuccess,
                                 this.resendParticipantFail),
        this.resendParticipantFail
    )
  }
  resendParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_RESEND_PARTICIPANT_SUCCESS,
      participants: data.participants
    })
    this.appActions.spinnerOff()
    alert('Questionnaire was re-sent')
  }
  resendParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESEND_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to re-send questionnaire')
  }

  getUserMap = (qid,eid) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_USER_MAP,
      selectedQuestId: qid,
      selectedUserMap: eid
    })
    this.menuItemClicked(1)
  }
  /******************* Questions ******************************/
  /**
   * Fetch questions
   */
  fetchQuestions = (qid) => {
    trace('InteractActions - In fetchQuestions()')
    if (qid === undefined) {return}

    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONS})
    this.ds.getQuestions(
      {qid: qid},
      this.fetchQuestionsSuccess
    )
  }
  fetchQuestionsSuccess = (res) => {
    trace('InteractActions - In fetchQuestions() - fetch success: res = ', res)
    const questions = res.data.questions
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONS_SUCCESS,
      questions: questions
    })
  }

  /**
   * Question update
   */
  updateQuestion = (question: IInteractQuestion, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTION, question: question})
    this.as.post('/interact_backoffice/questions_update',
        {question: question,
        qid: qid},
        this.ds.handlePostResult(this.updateQuestionSuccess,
                                 this.updateQuestionFail),
        this.updateQuestionFail
    )
  }
  updateQuestionSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_QUESTION_SUCCESS,
      questions: data.questions,
      questionnaire: data.questionnaire
    })
  }
  updateQuestionFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTION_FAIL, errors: err})
    console.error(err)
  }

  /**
   * New question
   */
  newQuestion = (question: IInteractQuestion, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTION, question: question})
    this.as.post('/interact_backoffice/questions_create',
        {question: question, qid: qid},
        this.ds.handlePostResult(this.newQuestionSuccess,
                                 this.newQuestionFail),
        this.newQuestionFail
    )
  }
  newQuestionSuccess = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTION_SUCCESS})
  }
  newQuestionFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTION_FAIL})
    console.error(err)
  }

  /**
   * Delete question
   */
  deleteQuestion = (qqid: number, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTION, qqid: qqid})
    this.as.post('/interact_backoffice/questions_delete',
        {qqid: qqid, qid: qid},
        this.ds.handlePostResult(this.deleteQuestionSuccess,
                                 this.deleteQuestionFail),
        this.deleteQuestionFail
    )
  }
  deleteQuestionSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_DELETE_QUESTION_SUCCESS,
      questions: data.questions
    })
  }
  deleteQuestionFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTION_FAIL,errors: err})
    console.error(err)
  }

  /**
   * Reorder questions
   */
  reorderQuestions = (questions: any[], qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REORDER_QUESTIONS})
    this.as.post('/interact_backoffice/questions_reorder',
        {questions: questions,
        qid: qid},
        this.ds.handlePostResult(this.reorderQuestionsSuccess,
                                 this.reorderQuestionsFail),
        this.reorderQuestionsFail
    )
  }
  reorderQuestionsSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_REORDER_QUESTIONS_SUCCESS})
  }
  reorderQuestionsFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REORDER_QUESTIONS_FAIL,errors: err})
    console.error(err)
  }

  /**
   * Active question in the map
   */
  setActiveQuestion = (qqid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SET_ACTIVE_QUESTION, qqid: qqid})
  }

  /**
   * Set the active question in the quesitnonaire itself
   */
  setActiveQuestionnaireQuestion = (qqid: number, qpid: number, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SET_QUESTIONAIRE_ACTIVE_QUESTION})
    this.as.post('/interact_backoffice/set_active_questionnaire_question',
        {qqid, qpid,qid},
        this.ds.handlePostResult(this.createSetActiveQuestionnaireQuestionSuccess(qqid),
                                 this.setActiveQuestionnaireQuestionFail),
        this.setActiveQuestionnaireQuestionFail
    )
  }
  createSetActiveQuestionnaireQuestionSuccess = (qqid: number) => {
    return () => {
      this.ngRedux.dispatch({
        type: InteractActions.INTERACT_MGMT_SET_QUESTIONAIRE_ACTIVE_QUESTION_SUCCESS,
        qqid: qqid
      })
    }
  }
  setActiveQuestionnaireQuestionFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SET_QUESTIONAIRE_ACTIVE_QUESTION_FAIL})
    console.error(err)
  }

  // ********************* Data mapping *************

  fetchFactors = (qid) => {
    trace('InteractActions - In fetch factors()')
    if (qid === undefined) {return}

    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_FETCH_FACTORS})
    this.ds.getFactors(
      {qid: qid},
      this.fetchFactorsSuccess
    )
  }
  fetchFactorsSuccess = (res) => {
    trace('InteractActions - In fetchfactors() - fetch success: res = ', res)
    const factors = res.data.factors
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_FACTORS_SUCCESS,
      factors: factors
    })
  }

  saveParams = (qid, factors) => {
    // this.appActions.spinnerOn()
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SAVE_PARAMS, qid: qid})
    this.as.post('/interact_backoffice/save_params',
        {qid: qid,factors: factors},
        this.ds.handlePostResult(this.saveParamsSuccess,
                                 this.saveParamsFail),
        this.saveParamsFail
    )
  }
  saveParamsSuccess = (data) => {
    // this.appActions.spinnerOff()
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_SAVE_PARAMS_SUCCESS,
      factors: data.factors
    })
    alert("params successfully updated")
  }
  saveParamsFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SAVE_PARAMS_FAIL})
    console.error(err)
    alert("update params failed")
  }

  // ***********************************************

  /******************* Questionnaires ******************************/
  /**
   * Fetch questionnaires
   */
  fetchQuestionnaires = () => {
    trace('InteractActions - In fetchQuestionnaires()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES})
    this.ds.getQuestionnaires({}, (res) => {
      trace('InteractActions - In fetchQuestionnaires() - fetch success: res = ', res)
      this.fetchQuestionnairesSuccess(res)
    })
  }
  fetchQuestionnairesSuccess = (res) => {
    const data = res.data
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES_SUCCESS,
      questionnaires: data.quests
    })
  }
  fetchQuestionnairesFail = (err) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES_FAIL,
      errors: err
    })
  }

  /**
   * New questionnair
   */
  createNewQuestionnaire = (company_id = null) => {
    trace('InteractActions - In createNewQuestionnaire()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE})
    this.as.get(
      '/interact_backoffice/questionnaire_create',
      {company_id: company_id},
      this.ds.handlePostResult(this.createNewQuestionnaireSuccess,
                               this.createNewQuestionnaireFail),
      this.createNewQuestionnaireFail
    )
  }
  createNewQuestionnaireSuccess = (res) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE_SUCCESS,
      questionnaires: res
    })
  }
  createNewQuestionnaireFail = (err) => {
    console.error(err)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE_FAIL,
      errors: err
    })
  }

  /**
   * Delete questionnair
   */
  deleteQuestionnaire = (qid) => {
    trace('InteractActions - In deleteQuestionnaire()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE})
    this.as.post(
      '/interact_backoffice/questionnaire_delete',
      {qid: qid},
      this.ds.handlePostResult(this.deleteQuestionnaireSuccess,
                               this.deleteQuestionnaireFail),
      this.deleteQuestionnaireFail
    )
  }
  deleteQuestionnaireSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE_SUCCESS,
      questionnaires: data
    })
  }
  deleteQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE_FAIL, errors: err})
    console.error(err)
  }

  /**
   * Questionnaire update
   */
  updateQuestionnaire = (quest: IQuest) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE, questionnaire: quest})
    this.as.post('/interact_backoffice/questionnaire_update',
        {questionnaire: quest, qid: quest.id},
        this.ds.handlePostResult(this.updateQuestionnaireSuccess,
                                 this.updateQuestionnaireFail),
        this.updateQuestionnaireFail
    )
  }
  updateQuestionnaireSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE_SUCCESS,
      questionnaires: data.quests,
      questionnaire: data.activeQuest
    })
  }
  updateQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE_FAIL, errors: err})
    console.error(err)
  }

  questSelected = (questId: number) => {
    trace('InteractAtions - questSelected() - questId: ', questId)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_QUEST_SELECTED,
      selectedQuestId: questId
    })
  }

  questOpened = (questId: number) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_QUEST_OPENED,
      selectedQuestId: questId
    })
  }

  openQuestionnaires = () => {
    console.log('actions = openQuestionnaires(')
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_QUEST_CLOSE
    })
  }

  /**
   * Test participant update
   */
  updateTestParticipant = (quest: IQuest) => {
    this.appActions.spinnerOn()
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT, questionnaire: quest})
    this.as.post('/interact_backoffice/update_test_participant',
        {questionnaire: quest,
        qid: quest.id},
        this.ds.handlePostResult(this.updateTestParticipantSuccess,
                                 this.updateTestParticipantFail),
        this.updateTestParticipantFail
    )
  }
  updateTestParticipantSuccess = (data) => {
    console.log('data: ', data)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_SUCCESS,
      questionnaire: data.questionnaire,
      testUserUrl: data.test_user_url
    })
    this.appActions.spinnerOff()
    alert('Test questionaire sent')
  }
  updateTestParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to send questionaire sent')
  }

  /******************* Interact actions ***************************/
  uploadParticipants = (formData: FormData) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS})
    this.as.post(
      '/interact_backoffice/actions_upload_participants',
      formData,
      this.ds.handlePostResult(
          this.uploadParticipantsSuccess,
          this.uploadParticipantsFail),
      this.uploadParticipantsFail)
  }
  uploadParticipantsSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
  }
  uploadParticipantsFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS_FAIL})
    console.error(err)
  }

  uploadParticipantPhotos = (formData: FormData,idx: number,length: number) => {
    console.log('InteractActions - In uploadParticipantPhotos()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO,
       isLastImage: (length === idx),
       lengthOfPhotos: length
      })
    this.as.post(
      '/interact_backoffice/actions_img_upload',
      formData,
      this.ds.handlePostResult(
          this.uploadParticipantPhotosSuccess,
          this.uploadParticipantPhotosFail),
      this.uploadParticipantPhotosFail)
  }
  uploadParticipantPhotosSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_SUCCESS,
      uploadInfo: [`Successfully uploaded image: ${data.img} `]
      // participants: data.participants
    })
  }
  uploadParticipantPhotosFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_FAIL, uploadInfo: err})
    console.error(err)
  }
  processParticipantPhotosFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_FAIL, uploadInfo: err})
    console.error(err)
  }

  /******************* Run questionnaire ******************************/
  runQuestionnaire = (qid: number) => {
    this.appActions.spinnerOn()
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE})
    this.as.post(
      '/interact_backoffice/quesitonnaire_run',
      {qid: qid},
      this.ds.handlePostResult(
          this.runQuestionnaireSuccess,
          this.runQuestionnaireFail),
      this.runQuestionnaireFail)
  }
  runQuestionnaireSuccess = (data) => {
    this.appActions.spinnerOff()
    alert('Questionnaire was sent successfully')
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE_SUCCESS,
      questionnaire: data.questionnaire
    })
  }
  runQuestionnaireFail = (err) => {
    this.appActions.spinnerOff()
    alert(`Error while sending questionnaire: ${err}`)
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE_FAIL})
    console.error(err)
  }

  /******************* Close questionnaire ******************************/


  closeQuestionnaire = (qid: number, email: string) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE})
    // this.appActions.spinnerOn()
    this.as.post(
      '/interact_backoffice/quesitonnaire_close',
      {qid: qid, email: email},
      this.ds.handlePostResult(
          this.closeQuestionnaireSuccess,
          this.closeQuestionnaireFail),
      this.closeQuestionnaireFail)
  }
  closeQuestionnaireSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE_SUCCESS,
      questionnaire: data.questionnaire
    })
    //this.appActions.spinnerOff()
    //alert('Questionnaire successfully closed')
  }
  closeQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE_FAIL})
    console.error(err)
    //this.appActions.spinnerOff()
    alert('Failed to close questionnaire')
  }

  /******************* Menu handling ******************************/
  menuItemClicked = (item: InteractTab ) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MENU_ITEM_CLICKED,
      menuItem: item
    })
  }

  mgmtMenuItemClicked = (item: InteractQuestTab ) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_MENU_CLICKED,
      mgmtMenuItem: item
    })
  }

  /******************** Top button actions **************************/
  downloadSample = (qid: number) => {
    console.log('calling download Sample')
    this.as.download('/interact_backoffice/actions_download_sample',
        {qid: qid},
        () => { console.log('Download sample success') }
    )
  }
  // clearParticipants = (qid: number) : void  => {
  //   //return this.as.post('/interact_backoffice/clear_participants',{qid: qid},())
  //   console.log("clear Participants")
  //   this.as.post2('/interact_backoffice/clear_participants',{qid: qid})
  //     .subscribe(value => console.log(value))
  // }

  removeParticipants = (qid) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REMOVE_PARTICIPANTS})
    this.as.post(
      '/interact_backoffice/remove_participants',{qid: qid},
      this.ds.handlePostResult(
          this.removeParticipantsSuccess,
          this.removeParticipantsFail),
      this.removeParticipantsFail)
  }
  removeParticipantsSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_REMOVE_PARTICIPANTS_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
  }
  removeParticipantsFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REMOVE_PARTICIPANTS_FAIL})
    console.error(err)
  }

  downloadEmployees = (qid: number) => {
    this.as.download('/interact_backoffice/participants_get_emps',
        {qid: qid},
        () => { console.log('Download employees success') }
    )
  }

  downloadParticipantsStatus = (qid: number) => {
    this.as.download('/interact_backoffice/actions_participants_status',
      {qid: qid},
      () => { console.log('Download status success') }
    )
  }

  /******************** Questionnaire data **************************/
  fetchQuestionnaireData = (qqid: number, gids: number[]) => {
    trace('InteractActions - In fetchQuestionnaireData()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_COLL_FETCH_QUEST_DATA})
    this.ds.getQuestionnaireData({qqid: qqid, gids: gids}, (res) => {
      this.fetchQuestionnaireDataSuccess(res)
    })
  }
  fetchQuestionnaireDataSuccess = (res) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_COLL_FETCH_QUEST_DATA_SUCCESS,
      collaboration_results: res.indeg,
      collaboration_metrics_results: res.question_scores,
      collaborationScore: res.collaboration,
      synergyScore: res.synergy,
      centralityScore: res.centrality,
      active_params: res.active_params,
      slider_val: res.slider_val
    })
  }
  fetchQuestionnaireDataFail = (err) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_COLL_FETCH_QUEST_DATA_FAIL,
      errors: err
    })
  }

  saveKFactor = (qid: number, val: number, qqid: number, gids: number[]) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SAVE_K_FACTOR, qid: qid})
    this.as.post('/interact_backoffice/save_k_factor',
        {qid: qid,
        k_factor: val,
        gids: gids,
        qqid: qqid},
        this.ds.handlePostResult(this.saveKFactorSuccess,
                                  this.saveKFactorFail),
        this.saveKFactorFail
    )
  }
  saveKFactorSuccess = (data) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SAVE_K_FACTOR_SUCCESS,
      collaboration_metrics_results: data.question_scores
    })
  }
  saveKFactorFail = (err) => {
    alert("update k failed")
  }
    
  /******************** Reports **************************/
  downloadQuestionnaireReport = (qid: number) => {
    this.appActions.spinnerOn()
    this.as.download('/interact_backoffice/reports_network',
        {qid: qid},
        () => {
          this.appActions.spinnerOff()
          console.log('Download questionnaire report success')
        }
    )
  }

  downloadBidirectionalReport = (qid: number) => {
    this.appActions.spinnerOn()
    this.as.download('/interact_backoffice/reports_bidirectional_network',
        {qid: qid},
        () => {
          this.appActions.spinnerOff()
          console.log('Download bidirectional network report success')
        }
    )
  }

  scoresReport = (qid: number) => {
    this.appActions.spinnerOn()
    this.as.download('/interact_backoffice/reports_measures',
        {qid: qid},
        () => {
          this.appActions.spinnerOff()
          console.log('Download scores report success')
        }
    )
  }

  networkMetricsReportA = (qid: number) => {
    this.appActions.spinnerOn()
    this.as.download('/interact_backoffice/reports_survey',
        {qid: qid},
        () => {
          this.appActions.spinnerOff()
          console.log('Download scores report success')
        }
    )
  }
/************************* Copy questionnaire *************************************/
  copyQuestionnaire = (qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE})
    this.as.post(
      '/interact_backoffice/quesitonnaire_copy',
      {qid: qid},
      this.ds.handlePostResult(
          this.copyQuestionnaireSuccess,
          this.copyQuestionnaireFail),
      this.copyQuestionnaireFail)
  }
  copyQuestionnaireSuccess = (res) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE_SUCCESS,
      questionnaires: res
    })
  }
  copyQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE_FAIL, errors: err})
    console.error(err)
  }

  /************************* Rerun questionnaire *************************************/
  rerunQuestionnaire = (qid: number) => {
    // alert('Running questionaire ...')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE})
    this.as.post(
      '/interact_backoffice/quesitonnaire_copy',
      {qid: qid, rerun: true},
      this.ds.handlePostResult(
          this.copyQuestionnaireSuccess,
          this.copyQuestionnaireFail),
      this.copyQuestionnaireFail)
  }

  /************************* Simulate questionnaire replies ****************************/
  simulateQuestionnaireReplies = (qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SIMULATE_QUESTIONNAIRE_REPLIES})
    this.as.post(
      '/interact_backoffice/simulate_replies',
      {qid: qid},
      this.ds.handlePostResult(
          () => {console.log('simulateQuestionnaireReplies success')},
          () => {console.log('simulateQuestionnaireReplies success')}),
      () => {console.log('simulateQuestionnaireReplies success')})
  }

  /**************************************************************/
  mapNavToggle = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MAP_NAV_TOGGLE})
  }

  clearInteractUrl = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLEAR_INTERACT_STATE})
  }



  /*****************************  Settings   *****************************/ 
  getCompanies = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_COMPANIES})
    this.ds.getCompanies(
      {},
      this.getCompaniesSuccess
    )

  }

  getCompaniesSuccess = (res) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_COMPANIES_SUCCESS,
      companies: res.data.companies,
      companyId: res.data.company_id
    })
  }

  getCompaniesFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_COMPANIES_FAIL, errors: err})
  }

  companySelected = (company_id: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COMPANY_SELECTED,
      companyId: company_id
    })
    this.ds.clearCache()

  }
  
  companyOpened = (company_id: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COMPANY_OPENED,
      companyId: company_id
    })
    this.ds.clearCache()

  }
  companyClose = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COMPANY_CLOSE })
  }


  superAdminSelectCompany = (company_id) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_COMPANY_DATA})
    this.ds.clearCache()
    // this.ds.getQuestionnaires({company_id: company_id}, (res) => {
    //   trace('InteractActions - In fetchQuestionnaires() - fetch success: res = ', res)
    //   this.fetchQuestionnairesSuccess(res)
    // })

  }
  updateCompany = (company: ICompany) => {
    this.appActions.spinnerOff()
    this.ngRedux.dispatch({type: InteractActions.INTERACT_UPDATE_COMPANY})
    this.as.post(
      '/interact_backoffice/company_update',
      {company: company},
      this.ds.handlePostResult(this.updateCompanytSuccess,
                             this.updateCompanyFail),
      this.updateCompanyFail)
  }
  updateCompanytSuccess = (data) => {
    console.log(data)
    alert("Company successfuly updated")
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_UPDATE_COMPANY_SUCCESS,
      companies: data.companies
    })
  }
  updateCompanyFail = (err) => {
    alert("Update company fail")
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_UPDATE_COMPANY_FAIL,
      errors: err
    })

  }

  createCompany = (company_name: string) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_ADD_COMPANY})
    this.as.post(
      '/interact_backoffice/company_create',
      {company_name: company_name},
      this.ds.handlePostResult(this.createCompanytSuccess,
                             this.createCompanyFail),
      this.createCompanyFail)
  }
  createCompanytSuccess = (data) => {
    console.log(data)
    alert("Company successfuly created")
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_ADD_COMPANY_SUCCESS,
      companies: data.companies,
    })

  }
  createCompanyFail = (err) => {
    alert("Add company fail")
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_ADD_COMPANY_FAIL,
      errors: err
    })
  }
  getUsers = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_USERS})
    this.ds.getUsers(
      {},
      this.getUsersSuccess
    )
  }

  updateUser = (user) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_USER})
    this.as.post('/interact_backoffice/user_update',
        {user: user},
        this.ds.handlePostResult(this.updateUserSuccess,
                                 this.updateUserFail),
        this.updateUserFail
    )
  }
  updateUserSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_USER_SUCCESS,
      users: data.users
    })
    this.appActions.spinnerOff()
    alert('User was updated')
  }
  updateUserFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_USER_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to update user')
  }

  createUser = (user) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CREATE_USER})
    this.as.post('/interact_backoffice/user_create',
        {user: user},
        this.ds.handlePostResult(this.createUserSuccess,
                                 this.createUserFail),
        this.createUserFail
    )
  }
  createUserSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_CREATE_USER_SUCCESS,
      users: data.users
    })
    this.appActions.spinnerOff()
    alert('User was successfuly added')
  }
  createUserFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CREATE_USER_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to add user')
  }  
  getUsersSuccess = (res) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_USERS_SUCCESS,
      users: res.data.users
    })
  }
  
  deleteUser = (uid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_USER})
    this.as.post('/interact_backoffice/user_delete',
        {uid: uid},
        this.ds.handlePostResult(this.deleteUserSuccess,
                                 this.deleteUserFail),
        this.deleteUserFail
    )
  }
  deleteUserSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_DELETE_USER_SUCCESS,
      users: data.users
    })
    this.appActions.spinnerOff()
    alert('User deleted')
  }
  deleteUserFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_USER_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to delete user')
  }

  goToParticipants = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MAP_FIND_PARTICIPANT})
  }
}
