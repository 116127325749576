import { Pipe, PipeTransform } from '@angular/core';

import { IScoreRow } from '../app.state'
import * as util from '../services/util.service'

@Pipe({name: 'scoreToAverage'})
export class ScoreToAverageFormatter implements PipeTransform {
  transform(value: IScoreRow): string {
    let score = util.numberToTimeRepresentation(value.curScore)
    if (value.groupSize !== undefined) {
      score = `${score} (${value.groupSize})`
    }
    return score
  }
}
