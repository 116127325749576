import { Component, Input, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms'
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import * as _ from 'lodash'

import {
  IQuest, InteractQuestState
} from '../../../app.state'
import { InteractActions } from '../interact.actions'
import { DialogElements } from '../../widgets/dialog/dialog.component'

@Component({
  selector: 'sa-app-int-test',
  templateUrl: './interact-test.component.html',
  styleUrls: ['./interact-test.component.scss']
})
export class InteractTestComponent implements OnInit {

  @Input() userRole: string
  @Input() set questionnaire (quest) {

    /** The reason for casting to IQuest here is because of a webpack bug which
     *  causes warnings in the console unless we do that. */
    this.questStateReadyToRun   = (<IQuest>quest).state === InteractQuestState.ready
    this.questStateReadyToClose = (<IQuest>quest).state === InteractQuestState.sent
    this.quest = (<IQuest>quest)
  }
  questStateReadyToRun: boolean
  questStateReadyToClose: boolean
  quest = <IQuest>null
  questForm: FormGroup

  testUrl: any
  notify_email: string = ''
  closeQuestDialogOpen = false
  displayErrorMessage = false

  constructor(
    private interactActions: InteractActions,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog) {}
    
  onSubmit = (form: any) => {
    const quest = _.cloneDeep(this.quest)
    quest.test_user_name = form['testUserName']
    quest.test_user_email = form['testUserEmail']
    quest.test_user_phone = form['testUserPhone']

    this.interactActions.updateTestParticipant(quest)
  }

  ngOnInit() {
    this.questForm = this.fb.group({
      'testUserName':  [this.quest.test_user_name, Validators.required],
      'testUserPhone': [this.quest.test_user_phone, Validators.required],
      'testUserEmail': [this.quest.test_user_email, Validators.required]
    })

    this.testUrl = this.getTestUserUrl()
  }
  
  openDialog(title: string, message: string) {
    this.dialog.open(DialogElements, {
      width: '400px',
      height: '200px',
      data: {
        title: title,
        body: message
      }
    });
  }
  // openDialog() {

  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   this.dialog.open(DialogElements, dialogConfig);
  // }

  getTestUserUrl = () => {
    if (this.quest.test_user_url === undefined) {return null}
    const url = `${this.quest.test_user_url}&mobile=true&output=embed`
    const ret = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    return ret
  }

  

  runQuestionnaire = () => {
    if (!this.questStateReadyToRun) {
      const message = this.causeUnableRun(this.quest.state)
      const title = 'הרצת סקר'
      this.openDialog(title,message)
    }
    else{
      this.interactActions.runQuestionnaire(this.quest.id)
    }
  }

  closeQuestionnaire = () => {
    if(this.notify_email == ''){
      this.displayErrorMessage = true
    }
    else{
      this.closeQuestDialogOpen = false
      let e = this.notify_email
      this.interactActions.closeQuestionnaire(this.quest.id,e)
    }
  }

  simulateQuestionnaireReplies = () => {
    console.log('In simulateQuestionnaireReplies() for quest id: ', this.quest.id)
    this.interactActions.simulateQuestionnaireReplies(this.quest.id)
  }
  openCloseQuestionnaireDialog = () => {
    if(!this.questStateReadyToClose){
      const message = this.causeUnableClose(this.quest.state)
      const title = 'סגירת סקר'
      this.openDialog(title,message)
    }else{
      this.closeQuestDialogOpen = true
      this.displayErrorMessage = false
    }
  }
  closeCloseQuestDialog = () => {
    this.closeQuestDialogOpen = false
  }
  clearErrorMsg = () => {
    this.displayErrorMessage = false
  }
  causeUnableRun = (state: InteractQuestState | string) => {
    let message: string
    switch (state) {
      case InteractQuestState.notstarted:
        message = 'לפני הרצת הסקר יש לבצע שליחה נסיונית'
        break
      case InteractQuestState.sent:
        message = 'הסקר כבר נשלח למשתתפים'
        break
      case InteractQuestState.processing:
        message = 'הסקר בתהליך סגירה'
        break
      case InteractQuestState.completed:
        message = 'הסקר סגור'
        break
      default:
        message = 'אין אפשרות להריץ את הסקר'
        break
    }
    return message
  }
  causeUnableClose = (state: InteractQuestState | string) => {
    let message: string
    switch (state) {
      case InteractQuestState.notstarted:
      case InteractQuestState.ready:
        message = 'יש להריץ תחילה את הסקר'
        break
      case InteractQuestState.processing:
        message = 'הסקר בתהליך סגירה'
        break
      case InteractQuestState.completed:
        message = 'הסקר סגור'
        break
      default:
        message = 'אין אפשרות לסגור את הסקר'
        break
    }
    return message
  }
}
