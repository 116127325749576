import {Component, Input, Output, OnInit, SimpleChanges} from '@angular/core';
import {Sort} from '@angular/material/sort';
import { random } from 'lodash';
import * as util from '../../../services/util.service'


export interface Dessert {
  name: string;
  algorithm_name: string;
  general: number;
  office: number;
  group: number;
  gender: number;
  rank: number;
  param_a: number;
  param_b: number;
  param_c: number;
  param_d: number;
  param_e: number;
  param_f: number;
  param_g: number;
  param_h: number;
  param_i: number;
  param_j: number;
  group_name?: string;
  group_size?: number;
  color?: string;
  parent_group_name?: string;
}


/**
 * @title Sorting overview
 */
@Component({
  selector: 'table-sorting-example',
  templateUrl: 'table-example.component.html',
  styleUrls: ['table-example.component.scss'],
})
export class TableSortingExample  implements OnInit {

  // selectedMetric: string
  filteredData: Dessert[]
  data: Dessert[]
  sortedData: Dessert[];
  sortedColumn = ''
  activeDynamicParams: any
  activeColumns: string[]
  staticColumns = ['general','office','group','gender','rank']
  possibleColumns =  ['param_a','param_b','param_c','param_d','param_e','param_f','param_g','param_h','param_i','param_j']

  @Input() set activeParams(actives: any){
    this.activeDynamicParams = actives
    if(this.activeDynamicParams){
      const result = this.possibleColumns.filter(s => this.activeDynamicParams[s] !== undefined);
      this.activeColumns = this.staticColumns.concat(result)
    }
  }
  
  @Input() filterBy: string
  @Input() set dataSource(data:  Dessert[]) {
    
    if( data === undefined) { return}
    this.data = data
    const filterString = this.filterBy
    this.filteredData  = this.data.filter(function (el) {
      return el.algorithm_name === filterString
    });
    this.sortedData = this.filteredData.slice();
    this.sortedColumn = ''
  }
  @Input() aggregator: string

  ngOnChanges(changes: SimpleChanges) {

    if(this.data && changes.filterBy && changes.filterBy.currentValue){
      this.filteredData  = this.data.filter(function (el) {
        return el.algorithm_name == changes.filterBy.currentValue
      });
      this.sortedData = this.filteredData.slice()
      this.sortedColumn = ''
    }
    // changes.prop contains the old and the new value...
  }


  // @Input() set algorithmFilter(algorithm_name: string) {
  //   this.filteredData  = this.data.filter(function (el) {
  //     return el.algorithm_name == algorithm_name  
  //   });
  //   this.sortedData = this.filteredData.slice();
  // }


  // desserts: Dessert[] = [
  //   {name: 'emp A', office: 0.159, group: 0.6, gender: 0.24, rank: 0.4},
  //   {name: 'emp B', office: 0.237, group: 0.9, gender: 0.7, rank: 0.4},
  //   {name: 'emp C', office: 0.262, group: 0.16, gender: 0.24, rank: 0.6},
  //   {name: 'emp D', office: 0.305, group: 0.4, gender: 0.67, rank: 0.4},
  //   {name: 'emp E', office: 0.356, group: 0.16, gender: 0.49, rank: 0.4},
  // ];

  ngOnInit() {
  }

  constructor() {
  
  }

  sortData(sort: Sort) {
    const data = this.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedColumn = ''
      this.sortedData = data;
      return;
    }
    this.sortedColumn = sort.active
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'general': return compare(Number(a.general), Number(b.general), isAsc);
        case 'office': return compare(Number(a.office), Number(b.office), isAsc);
        case 'group': return compare(Number(a.group), Number(b.group), isAsc);
        case 'gender': return compare(Number(a.gender), Number(b.gender), isAsc);
        case 'rank': return compare(Number(a.rank), Number(b.rank), isAsc);
        case 'param_a': return compare(Number(a.param_a), Number(b.param_a), isAsc);
        case 'param_b': return compare(Number(a.param_b), Number(b.param_b), isAsc);
        case 'param_c': return compare(Number(a.param_c), Number(b.param_c), isAsc);
        case 'param_d': return compare(Number(a.param_d), Number(b.param_d), isAsc);
        case 'param_e': return compare(Number(a.param_e), Number(b.param_e), isAsc);
        case 'param_f': return compare(Number(a.param_f), Number(b.param_f), isAsc);
        case 'param_g': return compare(Number(a.param_g), Number(b.param_g), isAsc);
        case 'param_h': return compare(Number(a.param_h), Number(b.param_h), isAsc);
        case 'param_i': return compare(Number(a.param_i), Number(b.param_i), isAsc);
        case 'param_j': return compare(Number(a.param_j), Number(b.param_j), isAsc);
        default: return 0;
      }
    });
  }
  columnDisplayName(column: string){
    if(this.activeDynamicParams[column] !== undefined)
      return this.activeDynamicParams[column].toUpperCase();
    return column.toUpperCase();
  }
  parentGroupName(dessert: Dessert){
    if(dessert.parent_group_name !== undefined && dessert.parent_group_name !== null)
      return `(${dessert.parent_group_name})`
    return ''
  }

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}