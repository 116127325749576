import { Pipe, PipeTransform } from '@angular/core';
import * as util from '../services/util.service'

@Pipe({name: 'toFloatRepresentation'})
export class ToFloatRepresentationFormatter implements PipeTransform {
  transform(value: number): string {
    if (util.isFloat(value)) {
      const rvalue = util.round2(value)
      return rvalue.toString()
    }
    return `${value}.0`
  }
}
