import {
  Directive,
  ElementRef,
  Renderer,
  Input,
  Renderer2
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from '@angular/forms'

import * as Texts from '../../texts'

/**
 * This directive is used on form fields to augment the behavior upon
 * validation errors.
 * It will color the border red and add a text to the control's title.
 *
 * To use it add the attribute: [appError]="formControlName" to an input
 * elemnt in a form.
 */
@Directive({
  selector: '[appError]',
})
export class ErrorDirective {

  @Input() appError: AbstractControl

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {

    this.appError.valueChanges.subscribe((val: any) => {
      /** Set the border to red if there are any errors */
      if (!this.appError.valid) {
        this.renderer.setStyle(this.el.nativeElement, 'border', 'red solid 1px')
      } else {
        this.renderer.removeStyle(this.el.nativeElement, 'border')
      }

      /** Set the title to an error message */
      if (!this.appError.valid) {
        this.setTitleToErrorMsg()
      } else {
        this.renderer.setAttribute(this.el.nativeElement, 'title', '')
      }
    })
  }

  /**
   * Look for the validation error in the FormControl and display
   * an appropriate message in the tooltip
   */
  setTitleToErrorMsg = () => {
    let msg = Texts.VALIDATION_MSG_GENERAL
    if (this.appError.hasError('required')) {
      msg = Texts.VALIDATION_MSG_FIELD_REQUIRED
    } else if (this.appError.hasError('minlength')) {
      msg = Texts.VALIDATION_MSG_FIELD_LENGTH_SHORT
    } else if (this.appError.hasError('maxlength')) {
      msg = Texts.VALIDATION_MSG_FIELD_LENGTH_LONG
    }

    this.renderer.setAttribute(this.el.nativeElement, 'title', msg)
  }

}
