﻿import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUser, IManageUser, IQuest, ICompany } from '../../../app.state'
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms'
import { Subscription } from 'rxjs/Subscription'
import { select } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'

import { InteractActions } from '../../interact/interact.actions'
import { AppActions } from 'app/app.actions'
import * as _ from 'lodash'
import { IDropdownSettings } from '../../widgets/multiselect-dropdown/multiselect.model'
// import { IDropdownSettings } from 'ng-multiselect-dropdown'
// import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';


@Component({
  selector: 'sa-settings-user-management',
  templateUrl: './settings-user-management.component.html',
  styleUrls: ['./settings-user-management.component.scss', '../settings.component.scss']
})
export class SettingsUserManagementComponent implements OnInit, OnDestroy {

  userList: IManageUser[]
  ep: FormGroup
  editrid = -1
  highlightedUser:number
  questionnairesList = []
  
  // dropdownList = [];
  // selectedItems = [];
  permitted_quests = []
  dropdownSettings: IDropdownSettings;
  submitClicked = false
  companiesList: ICompany[]
  companyName: string
  
  @select(['interact', 'users']) readonly users$: Observable<IManageUser[]>
  @select(['interact', 'quests']) readonly quests$: Observable<IQuest[]>
  @select(['interact', 'selectedCompanyId']) readonly selectedCompanyId$: Observable<number>
  @select(['interact', 'companies']) readonly companies$: Observable<ICompany[]>



  questsSub: Subscription
  usersSub: Subscription
  companyIdSub: Subscription
  companiesSub: Subscription


  constructor(    
    private actions: InteractActions,
    private fb: FormBuilder,
    private appActions: AppActions) {}

  ngOnDestroy() {
    this.questsSub.unsubscribe()
    this.usersSub.unsubscribe()
    this.companyIdSub.unsubscribe()
    this.companiesSub.unsubscribe()
  }
  ngOnInit() {
   
    
    this.questsSub = this.quests$.subscribe((quests: IQuest[]) => {
      _.each(quests, (q) => {
        this.questionnairesList.push({item_id: q.id,item_text: q.name})
      })
    })
    this.usersSub = this.users$.subscribe((users: IManageUser[]) => {
      if(users !== undefined){
        this.userList = users
        this.editrid = -1
      }
    })
    this.companiesSub = this.companies$.subscribe((companies: ICompany[]) => {
      this.companiesList = companies
    })

    this.companyIdSub = this.selectedCompanyId$.subscribe((cid: number) => {
      if(this.companiesList.length > 0) {
        _.each(this.companiesList, (c) => {
          if(c.id == cid)
            this.companyName = ` - ${c.name}`
        })
      }else {
        this.actions.getCompanies()
      }
    })

    if (this.questionnairesList.length === 0) {
      this.actions.fetchQuestionnaires()
    }

    // this.dropdownList = [
    //   { item_id: 1, item_text: 'Mumbai' },
    //   { item_id: 2, item_text: 'Bangaluru' },
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' },
    //   { item_id: 5, item_text: 'New Delhi' },
    //   { item_id: 11, item_text: 'Mumbai' },
    //   { item_id: 12, item_text: 'Bangaluru' },
    //   { item_id: 13, item_text: 'Pune' },
    //   { item_id: 14, item_text: 'Navsari' },
    //   { item_id: 15, item_text: 'New Delhi' }    ];
    
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }
  mouseIsOver(uid) {
    this.highlightedUser = uid
  }

  rowEdit(u) {
    this.permitted_quests = []
    this.submitClicked = false
    this.createFormGroup(u.id)
    this.editrid = u.id
    let quest: any
    _.each(u.permitted_quests, (permit) => {
      quest = _.find(this.questionnairesList, q => q.item_id === permit.questionnaire_id)
      if(quest !== undefined) {
        this.permitted_quests.push({item_id: quest.item_id,item_text: quest.item_text, level: permit.level})
      }
    })
  }
  roleSelected(event,user) {
    if (event === 'Admin') {
      this.ep.patchValue({user_type: 'admin'})
      user.user_type = 'admin'
    // } else if (event === 'Editor') {
    //   this.ep.patchValue({user_type: 'editor'})
    } else {
      this.ep.patchValue({user_type: 'editor'})
      user.user_type = 'editor'
      // throw new Error(`Illegal gender identifier: ${event}`)
    }

  }

  createFormGroup(rid) {
    const user: IManageUser = _.find(this.userList, u => u.id === rid)
    const id = (user === undefined ? -1 : user.id)

    this.ep = this.fb.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'email': ['', [Validators.required, Validators.email]],
      'user_type': ['', Validators.required],
      'password': ['', [Validators.required, Validators.minLength(6)]],
      'is_allowed_create_questionnaire': [false],
      'is_allowed_add_users': [false],
      'id': [id]
    })
    if(user !== undefined){
      this.ep.patchValue({user_type: user.user_type})
      this.ep.patchValue({is_allowed_add_users: user.is_allowed_add_users})
      this.ep.patchValue({is_allowed_create_questionnaire: user.is_allowed_create_questionnaire})
    }
  }
  getRoles(r) {
    let list = ['Admin','Editor']
    const sRole = (r == 'admin' ? 'Admin' : 'Editor')
    list = _.pull(list, sRole)
    list.unshift(sRole)
    return list
  }

  saveClicked(ep) {
    if (ep.valid) {
      const user = ep.value
      user.permitted_quests = this.permitted_quests
      if (user.id === -1) {
        this.appActions.spinnerOn()
        this.actions.createUser(user)
      } else {
        this.appActions.spinnerOn()
        this.actions.updateUser(user)
      }
      this.editrid = -1
    }
    this.submitClicked = true
  }
  rowDeleted(u) {    
    if (confirm('Do you really want to delete this user?')) {
      this.appActions.spinnerOn()
      this.actions.deleteUser(u.id)
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  changeItemLevel(items: any) {
    this.permitted_quests = items
  }

  addClicked() {
    console.log('received a request to add ...')
    this.userList.unshift({
      id: -1,
      first_name: null,
      last_name: null,
      email: null,
      user_type: 'editor',
      password: null,
      permitted_quests: []
    })
    this.editrid = -2
    this.createFormGroup(this.editrid)

  }
}
