﻿import { Component, OnInit } from '@angular/core'

import * as _ from 'lodash'

import { AjaxService } from '../../../services/ajax.service'

@Component({
  selector: 'sa-settings-jobs',
  templateUrl: './settings-jobs.component.html',
  styleUrls: ['./settings-jobs.component.scss', '../settings.component.scss']
})
export class SettingsJobsComponent implements OnInit {

  jobsList: any[]

  constructor(private ajaxSrv: AjaxService) {
    console.log('Constructing ...')
  }

  ngOnInit() {
    console.log('Initing ..')
    this.ajaxSrv.get(
      'v3/jobs_status', {},
      (res) => {
        // console.log(res)
        const resObj = JSON.parse(res['_body'])
        console.log(resObj)
        this.jobsList = _.values(resObj)
      },
      (err) => {console.log(err)}
    )
  }
}
