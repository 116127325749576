import { Pipe, PipeTransform } from '@angular/core';

import { IScoreRow } from '../app.state'
import * as util from '../services/util.service'

@Pipe({name: 'decimalToTime'})
export class DecimalToTimeFormatter implements PipeTransform {
  transform(value: number): string {
    return `${util.numberToTimeRepresentation(value)}`

  }
}
