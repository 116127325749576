import { Pipe, PipeTransform } from '@angular/core';
import * as util from '../services/util.service'

@Pipe({name: 'toPercentChange'})
export class ToPercentChangeFormatter implements PipeTransform {
  transform(value: {curScore: number, prevScore: number}): number {
    if (value === null) { return 0 }
    if (value.prevScore === 0) { return 0 }
    return util.round1(100 * ((value.curScore - value.prevScore) / value.prevScore))
  }
}
