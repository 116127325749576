﻿import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  ResponseContentType
} from '@angular/http';

import * as Consts from '../constants'
import * as _ from 'lodash'

@Injectable()
export class AjaxService {

  private static JWT_TOKEN = 'jwtToken'
  private static remoteUrlInited = false;
  private static cachedRemoteUrl = '';

  /**
   * This little oddity is what is causing the browser to open it's saveAs
   * dialog (or in Chrome's case just go ahead and download the file)
   */
  saveData = (() => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    return (blob: Blob, fileName) => {
        const url  = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
  })()

  static formatParams = (p: {[key: string]: any}): {[key: string]: any} => {
    const ret = {}
    _.forIn(p, (v: any, k: string) => {
      if (v instanceof Array) {
        ret[k] = _.reduce(v, (str: string, e: any) => str === '' ? `${e}` : `${str},${e}`, '')
      } else {
        ret[k] = v
      }
    })
    return ret
  }

  static getServerUrl() {
    // Cache the url
    if (!AjaxService.remoteUrlInited) {
      AjaxService.cachedRemoteUrl = _.find(Consts.SERVER_REMOTE_URL, url_hash => url_hash.is_selected).url;
      AjaxService.remoteUrlInited = true;
    }
    return AjaxService.cachedRemoteUrl;
  }

  constructor(private http: Http, private router: Router) { }

  private defaultOnError = (err: any) => {
    console.log(err)
    if (err.status === 401) {
      // console.log('Got status 401, redirect to login page')
      this.router.navigate(['login'])
    } else if (err.status === 404) {
      console.log('Got status 404 - No route')
    } else if (err.status === 500) {
      console.log('Got status 500 - Server error')
    } else {
      alert('Server is not responding')
      console.error('HTTP request failed with error: ', err)
    }
  }

  /////////////////////// Public /////////////////////////////////////////

  /**
   * Use this function to download files using an AJAX call
   */
  download(
    partialUrl: string,
    params: {[key: string]: any} = {},
    onSuccess?: () => any,
    onError?: (err: any) => any ): void {

    const jwt = localStorage.getItem(AjaxService.JWT_TOKEN)
    if (jwt === null) {
      this.router.navigate(['login'])
      return
    }

    let token = null
    try {
      token = JSON.parse(jwt)['auth_token']
    } catch (ex) {
      console.error('Caught exception while trying to login. Try accessing a V3 server')
      console.exception(ex)
    }
    const headers = new Headers({
      'Authorization': 'Bearer ' + token
    });

    const completeUrl = AjaxService.getServerUrl() + partialUrl

    const formattedParams = AjaxService.formatParams(params)
    this.http.get(completeUrl, {headers: headers, params: formattedParams, responseType: ResponseContentType.Blob})
      .subscribe(this.downloadFile(onSuccess), onError)
  }

  /**
   * The download success callback
   */
  downloadFile = (onSuccess: () => any) => {
   return (res: Response) => {
      if (onSuccess !== undefined) {
        onSuccess()
      }

      const headers = res.headers['_headers']
      let fileName = headers.get('content-disposition')
      fileName = fileName[0]

      // Get the part that's right of the equal sign
      fileName = fileName.split('=')[1]

      // remove the double qoutes
      fileName = fileName.slice(1, -1)

      const encodedData = res.blob()
      this.saveData(encodedData, fileName);
    }
  }

  /**
   * For Get AJAX calls
   */
  get(partialUrl: string,
      params: {[key: string]: any} = {},
      onSuccess: (res: Response) => any,
      onError?: (err: any) => any ): void {

    const jwt = localStorage.getItem(AjaxService.JWT_TOKEN)
    if (jwt === null) {
      this.router.navigate(['login'])
      return
    }

    let token = null
    try {
      token = JSON.parse(jwt)['auth_token']
    } catch (ex) {
      console.error('Caught exception while trying to login. Try accessing a V3 server')
      console.exception(ex)
    }
    const headers = new Headers({
      'Authorization': 'Bearer ' + token
    });

    const completeUrl = AjaxService.getServerUrl() + partialUrl

    if (onSuccess === undefined || onSuccess === undefined) { throw new Error('Null onSuccess in get()') }
    if (onError === undefined) { onError = this.defaultOnError }

    const company_id = localStorage.getItem('company_id')
    if(company_id != null)
      Object.assign(params, {company_id: company_id})
    const formattedParams = AjaxService.formatParams(params)
    this.http.get(completeUrl, {headers: headers, params: formattedParams})
      .subscribe(onSuccess, onError)
  }

  /**
   * For Post AJAX calls
   */
  post(partialUrl: string,
      params: { [key: string]: any },
      onSuccess: (res: Response) => any,
      onError?: (err: any) => any): void {

    const jwt = localStorage.getItem(AjaxService.JWT_TOKEN)

    if (jwt === null) {
      this.router.navigate(['login'])
      return
    }

    const token = JSON.parse(jwt)['auth_token']
    const headers = new Headers({
      'Authorization': 'Bearer ' + token
    });

    const completeUrl = AjaxService.getServerUrl() + partialUrl

    if (onSuccess === undefined || onSuccess === undefined) { throw new Error('Null onSuccess in get()') }
    if (onError === undefined) { onError = this.defaultOnError }

    const requestOptions = new RequestOptions({ headers: headers});
    const company_id = localStorage.getItem('company_id')
    if(company_id != null)
      Object.assign(params, {company_id: company_id})

    this.http.post(completeUrl, params, requestOptions)
      .subscribe(onSuccess, onError)
  }
}
