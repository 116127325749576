import { IMapState, INITIAL_STATE_MAP } from 'app/app.state'
import { stateToLocalStorage, stateFromLocalStorage, MAP_FILTERS } from 'app/services/state-persist.service'

export const clearMapStateInLocalService = () => {
    window.localStorage.removeItem(MAP_FILTERS)
}

export const mapStateToLocalStorage = (mapState: IMapState) => {
    stateToLocalStorage(MAP_FILTERS, mapState)
}

export const mapStateFromLocalStorage = (): IMapState => {
    const mapState = stateFromLocalStorage(MAP_FILTERS)
    if (mapState === undefined || mapState === null) {return INITIAL_STATE_MAP}
    return mapState
}
