﻿import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Http, Response, Headers, RequestOptionsArgs } from '@angular/http'
import { Router } from '@angular/router'

import * as _ from 'lodash'

import * as Consts from '../../constants'
import { AppActions } from '../../app.actions'
import { UserModel, UserType } from '../user/user.model'
import { AjaxService } from '../../services/ajax.service'
import { IScheduler } from 'rxjs/Scheduler';

import 'rxjs/add/operator/timeout'

@Component({
  selector: 'sa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  showError = true
  loginErrorMessage = 'qqq'

  constructor(
    fb: FormBuilder,
    public http: Http,
    private router: Router,
    private appActions: AppActions,
    private ajaxService: AjaxService) {

    this.loginForm = fb.group({
      'username': [''],
      'password': ['']
    })

    this.showError = false
    this.loginErrorMessage = ''
  }

  ngOnInit() {
    if (localStorage.getItem('jwtToken') !== null) {
    //   const fakeUser = {email: 'someone@email.com', first_name: 'someone', last_name: '', user_type: 'admin',
    //   reports_encryption_key: 'abcdefg', session_timeout: 0, password_update_interval: 0, max_login_attempts: 3
    // }
    //   const userData: UserModel = new UserModel(fakeUser);
    //   this.appActions.userSignedInAction(userData)
    }
  }

  onSubmit(value: any): void {
    this.signin(value.username, value.password)
  }

  signin(username: string, password: string): void {
    if ( Consts.CLIENT_FAKE_LOGIN_MODE ) {
      this.signin_deubg_mode(username, password);
      return;
    }
    this.signin_real_mode(username, password);
  }

  signin_deubg_mode(username: string, password: string): void {
    const jwtToken = '{"auth_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozfQ.gRS0fJ16pHuZaXR8ZZKI1ojyAdfS115_HTaIrPKuD84","user":{"id":3,"email":"danny@spectory.com"}}'
    localStorage.setItem('jwtToken', jwtToken)

    // Mock returned data from server
    const fakeUser = {email: 'someone@email.com', first_name: 'someone', last_name: '', user_type: 'admin',
      reports_encryption_key: 'abcdefg', session_timeout: 0, password_update_interval: 0, max_login_attempts: 0
    }
    const userData = new UserModel(fakeUser);

    this.appActions.userSignedInAction(userData);
    this.router.navigate(['/'])
  }

  signin_real_mode(username: string, password: string): void {
    const data = {
      email: username,
      password: password,
      // jwtToken: '{"auth_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozfQ.gRS0fJ16pHuZaXR8ZZKI1ojyAdfS115_HTaIrPKuD84","user":{"id":7,"email":"michael.kislev@spectory.com"}}'
    }

    const serverUrl = AjaxService.getServerUrl()
    this.http.post(`${serverUrl}/API/signin`, data)
      .timeout(3000)
      .subscribe((res: Response ) => {
        const json_res = JSON.parse( res['_body'] )
        const jwtToken = JSON.stringify(json_res['login_token'])
        localStorage.setItem('jwtToken', jwtToken)

        const user_info = json_res['user_info']
        const userData: UserModel = new UserModel(user_info)
        this.loginErrorMessage = ''
        this.showError = false

        this.appActions.userSignedInAction(userData)
        this.router.navigate(['/'])

      }, (err: any) => {
        console.log('Login fail')

        if (err.status === 401) {
          console.log('Got status 401, redirect to login page')
          this.router.navigate(['login'])
        } else if (err.status === 550) {
          console.log('Got status 550, login failed')

          let msg = null
          try {
            msg = JSON.parse(err['_body'])['msg']
          } catch (error) {
            console.warn('Authentication result parsing failed with: ', error.msg)
          }
          this.loginErrorMessage = _.isNil(msg) ? 'Authentication error' : msg

          this.showError = true
          this.router.navigate(['login'])
        } else if (err.name === 'TimeoutError') {
          this.loginErrorMessage = 'Server is not responding'
          this.showError = true
        } else {
          console.error('HTTP request failed with error: ', err)
        }
      })
  }

  signout(): void {
    localStorage.removeItem('jwtToken')
    this.appActions.userSignedOutAction()
  }
}
