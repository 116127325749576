﻿import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NgRedux, select } from '@angular-redux/store';
import { IGlobalState, IUser } from '../../app.state'
import { LogoutService } from '../../services/logout.service'
import { AjaxService } from '../../services/ajax.service'

import { UserModel, UserType } from '../user/user.model'
import * as Texts from '../../texts'
import { CLIENT_VERSION } from '../../constants'
import { InteractActions} from '../interact/interact.actions'

enum Setting { PersonalInfo, Management, Security, Logout,X, CompanyManagement}

@Component({
  selector: 'sa-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  readonly ADMIN_MESSAGE: string
  readonly MANAGER_MESSAGE: string
  readonly ADMING_MESSAGE_TOOLTIP: string
  readonly MANAGER_MESSAGE_TOOLTIP: string
  readonly SUPER_ADMIN_MESSAGE: string
  clientVersion = CLIENT_VERSION

  // @select(['global']) readonly currentUserState$: Observable<IGlobalState>
  @select(['global', 'currentUser']) readonly currentUserState$: Observable<UserModel>
  // @select(['interact', 'errors']) readonly errors$: Observable<string[]>
  
  currentUser: UserModel

  currentSetting: Setting

  showInfoTooltip = false

  constructor(private ngRedux: NgRedux<IGlobalState>,
    private logoutService: LogoutService,
    private ajaxSrv: AjaxService,
    private interactActions: InteractActions) {

    // this.currentUserState$.subscribe(globalState => {
    //   if (globalState.currentUser === undefined) { return }
    //   this.currentUser = <UserModel>globalState.currentUser
    // })
    this.currentUserState$.subscribe(user => {
      if (user === undefined) { return }
      this.currentUser = user
    })
    this.SUPER_ADMIN_MESSAGE = Texts.SETTINGS_SUPER_ADMIN_MESSAGE
    this.ADMIN_MESSAGE = Texts.SETTINGS_ADMIN_MESSAGE
    this.MANAGER_MESSAGE = Texts.SETTINGS_NON_ADMIN_MESSAGE
    this.ADMING_MESSAGE_TOOLTIP = Texts.SETTINGS_ADMIN_MESSAGE_TOOLTIP
    this.MANAGER_MESSAGE_TOOLTIP = Texts.SETTINGS_NON_ADMIN_MESSAGE_TOOLTIP

    this.currentSetting = Setting.PersonalInfo
  }

  settingClicked(button: number) {
    this.currentSetting = button
    if (this.currentSetting === Setting.Logout) {
      this.logoutService.logout()
    }
    else if(this.currentSetting == Setting.CompanyManagement){
      this.interactActions.getCompanies()
    }
    else if(this.currentSetting == Setting.Management){
      this.interactActions.getUsers()
    }
  }
}