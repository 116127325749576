import { Component, Input, OnInit} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash'

import * as util from '../../services/util.service'
import { KNotationFormatter } from '../../formatters/k-notation.formatter'

import { IInterfacesRow } from '../../app.state';

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-group-relations',
  templateUrl: './groups-relation.component.html',
  styleUrls: ['./groups-relation.component.scss']
})
export class GroupRelationsComponent {

  @Input() leaderboard$: Observable<IInterfacesRow[]>
  @Input() tabColor: string
  @Input() outTrafficSize$: number

  constructor() {}

}
