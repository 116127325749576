import { Component, OnInit, Input } from '@angular/core';
import { IFactor } from '../../../app.state'
import { InteractActions } from '../interact.actions'
import * as _ from 'lodash'

@Component({
  selector: 'sa-app-int-data-mapping',
  templateUrl: './data-mapping.component.html',
  styleUrls: ['./data-mapping.component.scss']
})

export class DataMappingComponent implements OnInit {
  factorList: IFactor[]
  @Input() activeQuestId: number
  @Input() set factors(f: any){
    this.factorList = []
    if (f === undefined || f.length === 0) {return}
    this.factorList = f.map(item => {
      return {id: item.id, name: item.factor_name, display_name: item.display_name}
    })
  }

  constructor(private actions: InteractActions) {}

  ngOnInit() {
    // if (this.factors === undefined || this.factors.length === 0 ) {
      this.actions.fetchFactors(this.activeQuestId)
    // }
  }

  saveParams() {
    this.actions.saveParams(this.activeQuestId, this.factorList) 
  }
  

}
