import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICompany } from 'app/app.state';
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'
import { select } from '@angular-redux/store'
import { InteractActions } from '../../interact/interact.actions'
import { Router } from '@angular/router'


@Component({
  selector: 'sa-settings-company-management',
  templateUrl: './settings-company-management.component.html',
  styleUrls: ['./settings-company-management.component.scss']
})
export class SettingsCompanyManagementComponent implements OnInit, OnDestroy {

  highlightedCompanyId = -1
  preventClick = false
  timer: any
  newCompanyMode = false
  addCompanyStr = "+ Add Company"
  newCompanyName: string
  companyEditinig: ICompany
  // companies: ICompany[]

  @select(['interact', 'companies']) readonly companies$: Observable<ICompany[]>
  @select(['interact', 'selectedCompanyId']) readonly selectedCompanyId$: Observable<number>
  @select(['interact', 'companyIsOpened']) readonly companyIsOpened$: Observable<boolean>

  companyIsOpenedSub: Subscription
  // companiesSub: Subscription


  constructor(private interactActions: InteractActions,private router: Router) {}

  // ngOnDestroy() {
  //   this.companiesSub.unsubscribe()
  // }

  ngOnInit() {
    this.newCompanyName = this.addCompanyStr
    // this.companiesSub = this.companies$.subscribe((state: ICompany[]) => {
    //   this.companies = state
    // })
    this.companyIsOpenedSub = this.companyIsOpened$.subscribe( (state: boolean) => {
      if(state){
        this.interactActions.companyClose()
        this.router.navigate(['/interact'])
      }
      // updateUrlParams('questIsOpened', state)

    })

  }
  ngOnDestroy() {
    this.companyIsOpenedSub.unsubscribe()
  }

  mouseIsOver(company_id) {
    this.highlightedCompanyId = company_id
  }

  mouseLeave() {
    this.highlightedCompanyId=-1
  }
  rowClicked(company_id) {
    console.log('----rowClicked')
    if(this.companyEditinig === undefined  || this.companyEditinig.id !== company_id) {
      this.preventClick = false
      this.interactActions.clearInteractUrl()
      localStorage.setItem('company_id', company_id)
      this.timer = setTimeout(() => {
        if (!this.preventClick) {
          this.interactActions.companySelected(company_id)
        }
      }, 300)
    }
  }

  rowDoubleClicked(company_id) {
    clearTimeout(this.timer)
    this.preventClick = true
    this.interactActions.companyOpened(company_id)
    // this.router.navigate(['/interact'])
    console.log('rowDoubleClicked')
  }

  // companyClicked(company_id) {
  //   if(this.companyEditinig === undefined  || this.companyEditinig.id !== company_id) {
  //     this.interactActions.superAdminSelectCompany(company_id)
  //     localStorage.setItem('company_id', company_id)
  //     this.interactActions.clearInteractUrl()
  //   }
  // }  
  // companyClicked(company_id) {
  //   if(this.companyEditinig === undefined  || this.companyEditinig.id !== company_id) {
  //     this.interactActions.superAdminSelectCompany(company_id)
  //     localStorage.setItem('company_id', company_id)
  //     this.interactActions.clearInteractUrl()
  //     this.router.navigate(['/interact'])
  //   }
  // }

  companyEdit(company) {
    this.companyEditinig = company
  }
  companyUpdate(company) {
    if(company.name != ''){
      this.interactActions.updateCompany(company)
      this.companyEditinig = undefined
    }
  }
  companyCreate() {
    if(this.newCompanyName != ''){
      this.interactActions.createCompany(this.newCompanyName)
      this.clearNewCompany()
    }
  }
  companyNew() {
    if(!this.newCompanyMode)
      this.newCompanyName = ''
    this.newCompanyMode = true
  }
  clearNewCompany() {
    this.newCompanyMode = false
    this.newCompanyName = this.addCompanyStr
  }


}
