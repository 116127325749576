import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit, Inject } from '@angular/core';

// // @Component({
// //   selector: 'app-dialog',
// //   templateUrl: './dialog.component.html',
// //   styleUrls: ['./dialog.component.scss']
// // })
// // export class DialogComponent implements OnInit {

// //   constructor(public dialog: MatDialog) {}

// //   ngOnInit() {
// //   }
// //   openDialog() {
// //     this.dialog.open(DialogElements);
// //   }

// // }
// @Component({
//   selector: 'dialog-elements-example-dialog',
//   templateUrl: 'dialog-elements-example-dialog.html',
// })
// export class DialogElements {}

import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'dialog-elements',
    templateUrl: './dialog-elements.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogElements implements OnInit {
  title: string
  body: string

  constructor(
      private dialogRef: MatDialogRef<DialogElements>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.body = this.data.body
    this.title = this.data.title
  }

  close() {
    console.log('CLOSE------------------------')
  }
}
