
/**
 * This service will persist parts of the state of the applicatin in the local
 * storage.
 */

export const MAP_FILTERS = 'map_filters'

export const stateFromLocalStorage = (key: string): any => {
    const serializedState = window.localStorage.getItem(key)
    if (serializedState === undefined) {return undefined}
    return JSON.parse(serializedState)
}

export const stateToLocalStorage = (key: string, state: any): void => {
    window.localStorage.setItem(key, JSON.stringify(state))
}
