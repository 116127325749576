import {
  Component,
  Input,
  Output,
  EventEmitter
 } from '@angular/core'

@Component({
  selector: 'sa-toggler',
  template: `<div class="toggler-cont" (click)='toggleClicked()'>
               <div class="toggler-bar" [ngClass]="{'on': state}">
                 <div class="toggler-circle" [ngClass]="{'on': state}">
                 </div>
               </div>
             </div>
            `,
  styleUrls: ['./toggler.component.scss']
})
export class TogglerComponent {
  @Input() state: boolean
  @Output() onToggle: EventEmitter<boolean>

  constructor() {
    this.onToggle = new EventEmitter()
  }

  toggleClicked = () => {
    this.onToggle.emit(this.state)
  }
}
