import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit
 } from '@angular/core'

@Component({
  selector: 'sa-selectbox',
  templateUrl: './selectbox.component.html',
  styleUrls: ['./selectbox.component.scss']
})
export class SelectboxComponent  implements OnInit {
  @Input() list: string[]
  @Input() theme: string

  @Output() onItemSelected: EventEmitter<string>
  isOpened = false
  selectedItem: string

  constructor() {
    this.onItemSelected = new EventEmitter()
  }

  ngOnInit() {
    this.selectedItem = this.list[0]
  }

  itemClicked = (item: string) => {
    this.selectedItem = item
    this.onItemSelected.emit(item)
    this.isOpened = false
  }

  toggleOpen = () => {
    this.isOpened = !this.isOpened
  }
}