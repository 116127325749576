import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core'

/**
 * Creates a wedge form (^) either pointing up or down and
 * provides a click event.
 * Holds no internal state, it only responds to an external one.
 */
@Component({
  selector: 'sa-open-close-wedge',
  template: `<div class='int-open-close-wedge'
                 (click)='onWedgeClicked()'>
               <img *ngIf="isOpen"
                    src='/assets/images/ic_arrow_up_menu.svg'
                    class='int-open-close-wedge-image'>
               <img *ngIf="!isOpen"
                    src='/assets/images/ic_arrow_down_menu.svg'
                    class='int-open-close-wedge-image'>
             </div>
            `,
  styles: [
    '.int-open-close-wedge {cursor: pointer;}',
    '.int-open-close-wedge-image {height: 0.3rem;}'
  ]
})
export class OpenCloseWedgeComponent {

  @Input() isOpen: boolean
  @Output() wedgeClicked: EventEmitter<string>

  constructor() {
    this.wedgeClicked = new EventEmitter()
  }

  onWedgeClicked() {
    this.wedgeClicked.emit('wedgeClicked')
  }
}
