import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from 'lodash'

import * as util from '../../services/util.service'
import * as Consts from '../../constants'
import { IntervalType } from '../../constants'

import {
  IGlobalState,
  IAppState,
  IScoreRow,
  ISnapshot,
  IGroup,
  ITimePickerValue,
  ITimeSpentStats,
  ITimeSpentState,
  IEmployeeScoreRow
 } from '../../app.state'

import { AppActions } from '../../app.actions'
import { TimeSpentActions } from './timespent.actions'
import { traceToggle } from '../../services/util.service';

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-timespent',
  templateUrl: './timespent.component.html',
  styleUrls: ['./timespent.component.scss']
})
export class TimeSpentComponent implements OnInit {

  /** Main tab color */
  // tabColor = '#4577A9';
  tabColor = '#000000';

  itemsList: string[]

  aggregatorsList: string[]

  timePickerData$: Observable<any>
  timePickerDataSub: Subscription

  showComingSoonMessage = false

  timeSpentAid = ''
  leftStatHeading = ''
  rightStatHeading = ''

  /** Data to display time picker widget is passed using this subject */
  timePickerDataSubject: BehaviorSubject<any> = new BehaviorSubject(null)

  @select(['global', 'timeIntervalType']) readonly currentTimeIntervalType$: Observable<string>
  currentTimeIntervalTypeSub: Subscription
  timeIntervalTypeStr: string;
  timeIntervalType: IntervalType;

  @select(['global', 'timeInterval']) readonly timeInterval$: Observable<{currentInterval: string, previousInterval: string}>;
  timeIntervalSub: Subscription
  currentInterval: string;
  previousInterval: string;

  @select(['global', 'aggregatorType']) readonly aggregator$: Observable<string>
  aggregatorSub: Subscription
  aggregator: string

  @select(['groups', 'groups']) readonly groups$: Observable<IGroup[]>
  groupsSub: Subscription
  selectedGroups: number[]

  @select(['groups', 'numberOfSelected']) readonly empsNumber$: Observable<number>
  empsNumberSub: Subscription
  empsNumber: number

  @select(['global', 'showChart']) readonly showChart$: Observable<boolean>;
  showChartSub: Subscription
  showChart: boolean

  // These are overridden from emails and meetings
  timeSpentScores$: Observable<IScoreRow[]>
  employeeScores$: Observable<IEmployeeScoreRow[]>
  timeSpent$: Observable<ITimeSpentStats>
  timeSpentSub: Subscription

  totalTimeSpent: number
  averageTimeSpent: number

  constructor(
    public actions: AppActions,
    public timeSpentActions: TimeSpentActions,
    public ngRedux: NgRedux<IGlobalState>
  ) {
    this.itemsList = Consts.SNAPSHOT_INTERVAL_TYPES;
    this.aggregatorsList = Consts.AGGREGATOR_TYPES_PRODUCTIVITY;
  }

  ngOnInit() {
    this.timeIntervalSub = this.timeInterval$.subscribe(state => {
      if (this.currentInterval === state.currentInterval &&
        this.previousInterval === state.previousInterval ) { return }

      this.currentInterval = state.currentInterval
      this.previousInterval = state.previousInterval

      if (this.currentInterval === undefined || this.currentInterval.match(/[a-zA-Z0-9\/]/) === null) { return }
      if (this.previousInterval === undefined || this.previousInterval.match(/[a-zA-Z0-9\/]/) === null) { return }
      if (this.selectedGroups === undefined) { return }

      trace('TimeSpentComponent - fetching scores from currentTimeInterval$')
      this.fetchTimeSpentData(
        this.selectedGroups,
        this.currentInterval,
        this.previousInterval,
        Consts.AGGREGATION_METHOD_GROUPS,
        this.timeIntervalTypeStr)
    });

    this.showChartSub = this.showChart$.subscribe( (state: boolean) => {
      this.showChart = state
    })

    this.currentTimeIntervalTypeSub = this.currentTimeIntervalType$.subscribe(intervalType => {
      this.timeIntervalTypeStr = intervalType
      this.timeIntervalType = util.getTimeIntervalType(this.timeIntervalTypeStr)

      if (this.selectedGroups !== undefined) {
        this.getTimePickerData()
      }
    })

    this.aggregatorSub = this.aggregator$.subscribe( state => {
       this.aggregator = state
    })

    this.actions.fetchSnapshots({limit: 20});
    this.actions.fetchTimePickerSnapshots({limit: 20});

    this.groupsSub = this.groups$.debounceTime(1000).subscribe((groups: IGroup[]) => {
      trace('TimeSpentComponent - In subscribe for groups')
      this.selectedGroups = _.filter(groups, (g: IGroup) => g.isSelected).map((g: IGroup) => g.gid)

      this.getTimePickerData();
      if (this.currentInterval === undefined || this.currentInterval.match(/[a-zA-Z0-9\/]/) === null) { return }
      if (this.previousInterval === undefined || this.previousInterval.match(/[a-zA-Z0-9\/]/) === null) { return }

      trace('TimeSpentComponent - fetching scores from groups$')
      this.fetchTimeSpentData(
        this.selectedGroups,
        this.currentInterval,
        this.previousInterval,
        Consts.AGGREGATION_METHOD_GROUPS,
        this.timeIntervalTypeStr)
    })

    this.empsNumberSub = this.empsNumber$.subscribe( (state: number) => {
      this.empsNumber = state
      if (this.totalTimeSpent !== undefined && this.empsNumber > 0) {
        this.averageTimeSpent = util.round2( this.totalTimeSpent / this.empsNumber )
      }
    })

    this.timePickerDataSub = this.timePickerData$.subscribe(scores => {
      this.timePickerDataSubject.next({data: scores})
    })

    this.timeSpentSub = this.timeSpent$.subscribe( (timeSpent: ITimeSpentStats) => {
      this.totalTimeSpent = timeSpent.totalTimeSpent.size
      if (this.empsNumber !== undefined && this.empsNumber > 0) {
        this.averageTimeSpent = util.round2( this.totalTimeSpent  / this.empsNumber )
      }
    })
  }

  ngOnDestroy() {
    this.timeSpentSub.unsubscribe()
    this.timePickerDataSub.unsubscribe()
    this.empsNumberSub.unsubscribe()
    this.groupsSub.unsubscribe()
    this.timeIntervalSub.unsubscribe()
    this.showChartSub.unsubscribe()
    this.currentTimeIntervalTypeSub.unsubscribe()
    this.aggregatorSub.unsubscribe()
  }

  readonly fetchTimeSpentData = (_selectedGroups: number[],
                              _currInterval: string,
                              _prevInterval: string,
                              _aggMethod: string,
                              _intervalType: string) => {
    trace('TimeSpentComponent - In fetchScoresData(), currInt: ',
          _currInterval, ' prevInt: ', _prevInterval, ' intervalType: ', _intervalType)
    if (_selectedGroups === undefined || _selectedGroups.length === 0) { return }
    const selectedGidsStr = JSON.stringify(_selectedGroups).slice(1, -1)
    if (selectedGidsStr.length === 0) {return}
    if (_currInterval === undefined) { return }

    // Get the scores
    this.timeSpentActions.fetchScores({
      'gids': selectedGidsStr,
      'curr_interval': _currInterval,
      'prev_interval': _prevInterval === 'NA' ? undefined : _prevInterval,
      'agg_method': _aggMethod,
      'interval_type': _intervalType
    })

    // Get the stats at the top of the page
    this.timeSpentActions.fetchTimeSpentStats({
      gids: selectedGidsStr,
      curr_interval: _currInterval,
      prev_interval: this.previousInterval,
      interval_type: this.timeIntervalTypeStr
    })

    // Get the goods for the leaderboard
    this.timeSpentActions.fetchEmployees({
      gids: selectedGidsStr,
      curr_interval: _currInterval,
      agg_method: _aggMethod,
      interval_type: _intervalType
    })
  }

  getTimePickerData() {
    trace('TimeSpentComponent - In getTimePickerData()')
    const snapshots = this.ngRedux.getState()['global']['timePickerSnapshots'];

    if (this.timeIntervalType === IntervalType.NotDefined || this.selectedGroups === undefined ||
      this.selectedGroups.length === 0 || snapshots === undefined) {return}

    const sids = _.map(snapshots, (s: ISnapshot) => s.sid);
    const sidsStr = util.arrayToStr(sids);
    const gidsStr = util.arrayToStr(this.selectedGroups);

    const params = {
      interval_type: util.convertTimeIntervalTypeToString(this.timeIntervalType),
      sids: sidsStr,
      gids: gidsStr,
      segment_type: this.aggregator
    };

    this.timeSpentActions.fetchTimeSpentVolume(params);
  }
}
