﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingsComponent } from './settings.component';
import { SettingsPersonalInfoComponent } from './settings-personal-info/settings-personal-info.component';
import { SettingsSecurityComponent } from './settings-security/settings-security.component';
import { SettingsUserManagementComponent } from './settings-user-management/settings-user-management.component';
import { SettingsJobsComponent } from './settings-jobs/settings-jobs.component';
import { SettingsCompanyManagementComponent } from './settings-company-management/settings-company-management.component'
import { WidgetsModule  } from '../widgets/widgets.module'

@NgModule({
  declarations: [
    SettingsComponent,
    SettingsPersonalInfoComponent,
    SettingsSecurityComponent,
    SettingsUserManagementComponent,
    SettingsJobsComponent,
    SettingsCompanyManagementComponent  ],
imports: [
  BrowserModule,
  FormsModule,
  ReactiveFormsModule,
  WidgetsModule
],
exports: [
  SettingsComponent,
  SettingsPersonalInfoComponent,
  SettingsSecurityComponent
]
})

export class SettingsModule {}
