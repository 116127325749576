import { Injectable } from '@angular/core';
import * as _ from 'lodash'
import * as Util from '../../services/util.service'

import * as Consts from '../../constants'
import { IScoreRow } from '../../app.state'

/** Column data for interfaces table*/
export interface ITableHeader {
  id: number,
  name: string
};

/** Row structure for interfaces table*/
export interface ITableRow {
  row_category: string,
  rowid: number,
  scores: {
    id: number,
    col_name: string,
    col_score: number,
    col_score_percent: number,
    current_length_percent: number,
    min: number,
    max: number
  }[],
};

const trace = Util.traceToggle(false)

@Injectable()
export class BarsTableUtilService {

  /**
   * Set headers names
   */
  static setHeaders = (rawData: IScoreRow[]): ITableHeader[]  => {
    // Get an entry for each algorithm
    const uniqueRowsByAid = _.uniqBy(rawData, r => {
      return r.aid;
    });
    const res = _.map(uniqueRowsByAid, r => {
      return {id: r.aid, name: Util.getAlgorithmDisplayName(r.aid, r.algoName)};
    });

    return _.orderBy(res , r => r.id);
  }

  static parseRawData = (rawData: IScoreRow[], aggregator: string): ITableRow[] => {

    if (Util.isNotDefinedOrEmpty(rawData)) {return}

    // Get segment names
    let aggregatorStr = '';

    if (aggregator === Consts.AGGREGATOR_TYPE_DEPARTMENT) {
      aggregatorStr = 'groupName';
    } else {
      aggregatorStr = 'officeName';
    }
    const segments = <string[]>_.uniq(_.map(rawData, aggregatorStr));

    let rows = BarsTableUtilService.transformDataToRowEntries(rawData, segments, aggregatorStr);

    rows = BarsTableUtilService.setMissingScores(rows);

    return BarsTableUtilService.sortScoresById(rows);
  }

  /**
   * Transform data structure to match the table view. Returns a structure where each entry represents
   * a row in the table. See ITableRow interface
   * Example: returns an entry -
   * {
   *  row_category: 'IT',
   *  rowid: 999,
   *  scores: [{algorithm_name: 'Bottlenecks', score: 20.0}, {algorithm_name: 'Isolated', score: 30.0},..., {}]
   * }
   * @param rows - raw data to transform
   */
  static transformDataToRowEntries = (rawData: IScoreRow[], segments: string[], aggregatorStr: string): ITableRow[] => {

    /*
     * If we only have one group we can compare among groups, so we compare among algorithms.
     */
    if (segments.length === 1) {
      const maxOfMaxes = _.reduce(rawData, (max, r) => max <  r['original_score'] ? r['original_score'] : max , -10)
      const minOfMins  = _.reduce(rawData, (min, r) => min >= r['original_score'] ? r['original_score'] : min , 10)
      return [
        {
          row_category: segments[0],
          rowid: rawData[0].gid,
          scores: _.map( rawData, (d) => {
            const percent_score = BarsTableUtilService.lengthFromRow(d['original_score'], minOfMins, maxOfMaxes)
            return {
              col_name: d.algoName,
              col_score: d.curScore,
              col_score_percent: percent_score,
              current_length_percent: 0,
              id: d.aid,
              min: d['min'],
              max: d['max']
            }
          })
        }
      ]
    }

    /*
     * Compare among groups
     */
    return _.map(segments, g => {
      const filteredRows = _.filter(rawData, d => d[aggregatorStr] === g)
      const ret = {
        row_category: g,
        rowid: filteredRows[0].gid,
        scores: filteredRows.map(d => {
                  const percent_score = BarsTableUtilService.lengthFromRow(d['curScore'], d['min'], d['max'])
                  return {
                    col_name: d.algoName,
                    col_score: d.curScore,
                    col_score_percent: percent_score,
                    current_length_percent: 0,
                    id: d.aid,
                    min: d['min'],
                    max: d['max']
                  }
                })
              }
        return ret
    });
  }

  static lengthFromRow = (score, min, max) => {
    trace(`score: ${score}, min: ${min}, max: ${max}`)

    if (Math.abs(score - min) < 0.001) {
      // If score is zero - give it the minimal visible length
      return 1; // 1% percent length
    } else if (score === -1000000) {
      // If bad score - give it 0
      return 0;
    } else {

      return max !== 0 ? (score / max) * 100 : 0
    }
  }

  /**
   * Set segments with missing scores to some defualt value
   */
  static setMissingScores = (rows: ITableRow[]): ITableRow[] => {
    // Number of columns every segment should have
    let numOfColumns = 0;

    // Index of row with the most columns. Set all rows to the same structure
    let indexOfRow = 0;

    // Find row and max num of columns
    _.forEach(rows, (r, i) => {
      if (r.scores.length >= numOfColumns) {
        numOfColumns = r.scores.length;
        indexOfRow = i;
      }
    });

    return _.map(rows, r => {
      return {
        row_category: r.row_category,
        rowid: r.rowid,
        scores: _.map(rows[indexOfRow].scores, scorePattern => {
          const element = _.find(r.scores, s2 => scorePattern.id === s2.id);
            if (element === undefined) {
              return {
                id: scorePattern.id,
                col_name: scorePattern.col_name,
                col_score: 0,
                col_score_percent: -1,
                current_length_percent: 0,
                min: scorePattern.min,
                max: scorePattern.max
              }
            } else {
              return element;
            }
          })
        }
      })
  }

  /**
   * Sort all scores by algorithm name, so all entries are in the same order
   */
  static sortScoresById = (rows: ITableRow[]): ITableRow[] => {
    return _.map(rows, (r) => {
      return {row_category: r.row_category, rowid: r.rowid,
        scores: _.orderBy(r.scores, (d) => {
          return d.id;
      })}
    });
  }

  /**
   * Sort rows by values of specific column.
   *
   * @param colIndex - column by which to sort the rows
   * @param dataToSort - data to sort
   * @param order - order of data - ascending or descending. Default it ascending.
   */
  static sortRowsByColumn = (colIndex: number, dataToSort: ITableRow[], order: 'asc' | 'desc'): ITableRow[]  => {
    if (dataToSort === undefined) {return}

    return _.orderBy(dataToSort, (d) => {
      if ((<ITableRow>d).scores[colIndex] !== undefined) {
        return (<ITableRow>d).scores[colIndex].col_score_percent
      } else {
        return 0
      }
    }, order)
  }

  /**
   * Get the row index by the category of the row. This method is doing string equation.
   *
   * @param category - category of the row, by which to get the index
   * @param rows - the rows in which to search for the category
   */
  static getRowIndexByCategory = (category: string, rows: ITableRow[]): number => {
    return _.findIndex(rows, r => r.row_category.toLowerCase() === category.toLowerCase());
  }

  static getSortString = (sortOrder: boolean): 'desc' | 'asc' => {
    let order: 'desc' | 'asc' = 'asc'
    if (sortOrder) {
      order = 'desc'
    }
    return order
  }
  }
