import { Component, ViewChild, ElementRef, Input, OnInit, HostListener, OnDestroy } from '@angular/core'
import { select } from '@angular-redux/store'
import { Observable, fromEvent } from 'rxjs'
// import { Observable } from 'rxjs/Observable'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Subscription } from 'rxjs/Subscription'
import * as _ from 'lodash'

import { AppActions } from '../../app.actions'
import { ITimePickerValue } from '../../app.state'
import * as Util from '../../services/util.service'

import { DataPoint, TimePickerUtilService } from './time-picker-util.service'

import * as Consts from '../../constants'
import * as Texts from '../../texts'

@Component({
  selector: 'sa-app-time-picker-widget',
  templateUrl: './time-picker-widget.component.html',
  styleUrls: ['./time-picker-widget.component.scss']
})
export class TimePickerWidgetComponent implements OnInit, OnDestroy {

  /** Cavnas DOM element */
  @ViewChild('myCanvas') myCanvas: ElementRef;
  public context: CanvasRenderingContext2D;

  /** Chart DOM element */
  @ViewChild('chartArea') chart: ElementRef;

  /** Main widget color - as hex */
  @Input() mainColorHex: string;

  /** Color of highlight */
  highlightColorRGB: string;

  @Input() selectedAction: any;

  @Input() datasetSubject: BehaviorSubject<any>;
  datasetSubscription: Subscription;

  @select(['global', 'navOpened']) readonly isNavBarOpen$: Observable<boolean>;
  isNavOpened: boolean;

  @Input() currentIntervalType = Consts.IntervalType.NotDefined;

  @Input() toolTipText: string = Texts.TIME_PICKER_DEFAULT_TOOL_TIP

  @Input() measureType: string

  COLLAPSE_BUTTON_TEXT_MONTHLY = Texts.TIME_PICKER_COLLAPSE_BUTTON_TEXT_MONTHLY
  COLLAPSE_BUTTON_TEXT_QARTERLY = Texts.TIME_PICKER_COLLAPSE_BUTTON_TEXT_QARTERLY
  COLLAPSE_BUTTON_TEXT_AVG = Texts.TIME_PICKER_COLLAPSE_BUTTON_TEXT_AVG
  COLLAPSE_BUTTON_TEXT_SIX_MONTHS = Texts.TIME_PICKER_COLLAPSE_BUTTON_TEXT_SIX_MONTHS
  COLLAPSE_BUTTON_TEXT_YEARLY = Texts.TIME_PICKER_COLLAPSE_BUTTON_TEXT_YEARLY

  @select(['global', 'showChart']) readonly showChart$: Observable<boolean>;
  showChart: boolean;

  /** Dataset for drawing - should contain all data that is necessary for drawing */
  data: DataPoint[];

  canvasHeight: number;

  /** This is binded to the canvas width attribute */
  canvasWidthBinded = 900;

  /** Defines the width of the widget. Roughly correlates to the number of visible points  */
  currentWidthMode = -1;

  xAxisLegendHeight = 30;

  yAxisLegendWidth = 20;

  /** The active area for drawing */
  chartHeight: number;

  /** Interval between points on x axis */
  deltaX = 100;

  /** Margin of first point from y axis - all x positions are shifted by this value */
  firstPointXMarginLeft = 120;

  /** Margin of y axis from left edge */
  yAxisLeftMargin = 5;

  /** Where to draw top grid line in relation to top edge of chart */
  marginFromTopEdge = 20;

  /** Step size between values shown on the y axis */
  yAxisStepSize: number;

  /** min/max values of received data */
  yMin: number;
  yMax: number;

  /** min/max values to show on y axis - should be rounded (inferred from data) */
  yAxisMin: number;
  yAxisMax: number;

  /** Highlight params */
  highlightLeftEdge: number;
  highlightRightEdge: number;

  /** The triangle size - this is the size of the side that is sticked to the left/right edge */
  hightlightTriangleSize = 20;

  /** Where to start drawing the triangle on the Y axis */
  hightlightTriangleYShift = 10;

  /** Selected point */
  currentPoint = -1;

  /** How many points are visible currently in the chart */
  numOfVisiblePoints = -1;

  /**
   * Draw values or low/high - this means y axis won't display numbers, and highlight will
   * display high/low according to condition
  */
  drawValues = true;

  /**
   * Threshold numbers - under low display 'low', between - 'medium' and above - 'high'
   */
  lowThreshold: number;
  highThreshold: number;

  constructor(private actions: AppActions) {
    // Listen on window resize - check the parent width when window resizes
    Observable.fromEvent(window, 'resize')
      .debounceTime(100)
      .subscribe(() => {
        this.checkWidth(this.chart.nativeElement.clientWidth, false);
    });
  }

  ngOnInit() {
    this.highlightColorRGB = Util.hex2rgb(this.mainColorHex, 0.15).str;

    this.showChart$.subscribe( (state: boolean) => {
      this.showChart = state;
      if (this.showChart) {
        if (this.context === undefined) {
          this.context = this.myCanvas.nativeElement.getContext('2d');
        }

        this.canvasHeight = this.context.canvas.height;
        this.draw();
      }
    });
  }

  ngAfterViewInit(): void {
    this.context = this.myCanvas.nativeElement.getContext('2d');

    // Set the canvas property directly, and not with the binded variable - otherwise causes error 'ExpressionChangedAfterItHasBeenCheckedError'
    this.context.canvas.width = this.chart.nativeElement.clientWidth;

    this.canvasHeight = this.context.canvas.height;
    this.chartHeight = this.canvasHeight - this.xAxisLegendHeight;

    this.isNavBarOpen$.subscribe(state => {
      setTimeout(this.checkWidthWithDelay(), 50);
    });

    this.checkWidth(this.chart.nativeElement.clientWidth, true);

    this.subscribeToMouseClick();

    this.datasetSubscription = this.datasetSubject.subscribe(rawData => {
      if (rawData === undefined || rawData === null || Util.isNotDefinedOrEmpty(rawData.data)) {return}
      this.parseRawData(rawData);
      this.setSelectedPoint(this.data.length - 1);
    });
  }

  ngOnDestroy() {
    this.datasetSubscription.unsubscribe();
  }

  /**
   * Check the width with a delay - this is due to an issue when listening on the observable isNavOpen$ -
   * the boolean is toggled before the width of the chart area in the DOM is updated.
   * This way, checking the width whe the value is toggled is meaningless. This is why we added a delay
   * here. 50 milisecs delay was chosen arbitrarily.
   */
  checkWidthWithDelay() {
    return () => {
      this.checkWidth(this.chart.nativeElement.clientWidth, true);
    }
  }

  parseRawData(rawData: any) {
    if (Util.isNotDefinedOrEmpty(rawData.data)) {return}
    if (rawData.displayValues !== undefined) {
      this.drawValues = rawData.displayValues;
    }
    this.lowThreshold = rawData.low;
    this.highThreshold = rawData.high;
    this.data = this.parseDataStep1(rawData.data, this.firstPointXMarginLeft, this.deltaX);
    this.calcYAxisProperties(this.data);
    this.parseDataStep2(this.yAxisMin, this.yAxisMax);
  }

  onSelection(currentPoint: number) {
    const scores = _.map(this.data, (d: DataPoint) => ({time_period: d.label, score: d.value}))
    if (currentPoint === -1) {
      this.selectedAction('', '', scores);
      return;
    }

    this.selectedAction(this.data[currentPoint].label, (currentPoint !== 0) ? this.data[currentPoint - 1].label : 'NA', scores);
  }

  /********** PARSE DATA **********/

  parseDataStep1(rawData: ITimePickerValue[], leftMargin: number, intervalBetweenPoints: number): DataPoint[] {
    const newData: DataPoint[] = _.map(rawData, (
      r: {time_period: string, score: number},
      i: number) => {
      const pointData = {
        xPos: leftMargin + (i * intervalBetweenPoints),
        value: Math.abs( Util.round2(r.score) ),
        yPosPercent: 0,
        label: r.time_period
      }
      return new DataPoint(pointData);
    });
    return newData;
  }

  parseDataStep2(min: number, max: number) {
    const range = max - min;

    for (const h of this.data) {
      h.yPosPercent = (h.value - min) / range;
    }
  }

  /****************************************/

  /********** CALCULATIONS **********/

  calcYAxisProperties(data: DataPoint[]) {
    const yValues: number[] = [];

    for (const h of data) { yValues.push(h.value); }

    let values = Util.getMinMaxValues(yValues);
    this.yMin = values.min;
    this.yMax = values.max;

    this.yAxisStepSize = TimePickerUtilService.calcYAxisStepSize(this.yMin, this.yMax);

    values = TimePickerUtilService.calcYAxisMinMax(this.yMin, this.yMax, this.yAxisStepSize);
    this.yAxisMin = values.min;
    this.yAxisMax = values.max;
  }

  checkWidth(currentWidth: number, firstTime: boolean) {
    let newWidthMode = -1;

    for (let i = 4; i < 13; i++) {
      if ((currentWidth > this.getWidthByNumberOfSections(i)) && (currentWidth <= this.getWidthByNumberOfSections(i + 1))) {
        newWidthMode = i;
        break;
      }
    }
    if (newWidthMode === -1) { // Couldn't find suitable width mode  - fallback case
      newWidthMode = 7;
    }

    // Draw only on change
    if (this.currentWidthMode !== newWidthMode) {
      if (firstTime) {
         // When setting the width for the first time - set it directly on the element and not on the binded variable.
         // Otherwise, this causes and error 'ExpressionChangedAfterItHasBeenCheckedError'
        this.context.canvas.width = this.getWidthByNumberOfSections(newWidthMode);
      } else {
        this.canvasWidthBinded = this.getWidthByNumberOfSections(newWidthMode);
      }

      this.numOfVisiblePoints = newWidthMode + 1;

      // Reset selected point if it is no longer visible
      if (this.currentPoint > this.numOfVisiblePoints - 1) {
        this.resetCurrentPoint();
        this.onSelection(this.currentPoint);
      }

      setTimeout(() => this.draw(), 0.01);
    }
    this.currentWidthMode = newWidthMode;
  }

  setSelectedPoint = (pointIndex: number) => {
    if (this.data === undefined || pointIndex < 0 || pointIndex >= this.data.length) {return}

    this.highlightLeftEdge = this.data[pointIndex].xPos - (this.deltaX / 2);
    this.highlightRightEdge = this.data[pointIndex].xPos + (this.deltaX / 2);
    this.currentPoint = pointIndex;
    this.draw();
    this.onSelection(pointIndex);
  }

  resetCurrentPoint() {
    this.currentPoint = -1;
  }

  resetSelectedIntervalInState() {
    this.onSelection(-1);
  }

  reset() {
    this.resetCurrentPoint();
    this.resetSelectedIntervalInState();
  }

  /****************************************/

  /********** DRAWING METHODS **********/

  /** Main draw method */
  draw() {
    if (this.data === undefined || !this.showChart) {return}

    this.drawBackground('white');
    this.drawXAxis(this.data);
    this.drawYAxis();
    this.drawGrid();
    this.drawDataset(this.data);
    this.drawHighlight();
  }

  drawXAxis(data: DataPoint[]) {
    for (let i = 0 ; i < data.length; i++) {
      this.drawAxisLabel(data[i].label, TimePickerUtilService.getXPosForPoint(i, this.deltaX, this.firstPointXMarginLeft) - 15, this.canvasHeight - 10);
    }
  }

  drawYAxis() {
    const lineColor = '#B2B5B5';

    const bottomLabelText = this.drawValues ? String(this.yAxisMin) : 'Low';
    const topLabelText = this.drawValues ? String(Util.round2(this.yAxisMax)) + ' hours' : 'High';
    this.drawYAxisLabel(bottomLabelText, this.yAxisLeftMargin, this.getYCoorByPercentageValue(0.05));
    this.drawYAxisLabel(topLabelText, this.yAxisLeftMargin, this.getYCoorByPercentageValue(1.05), String(this.yAxisMax).length);
    if (this.drawValues) {
      this.drawHorizontalLine(this.yAxisLeftMargin, this.yAxisLegendWidth, this.getYCoorByPercentageValue(1), lineColor, 1);
    }
  }

  drawYAxisLabel(text: string, xPos: number, yPos: number, digitNumber: number = 2) {
    if (digitNumber === 3) {
      xPos -= 4;
    }
    this.drawAxisLabel(text, xPos, yPos);
  }

  drawAxisLabel(text: string, xPos: number, yPos: number) {
    this.drawText(text, xPos, yPos, 'RobotoThin', 13, 'black');
  }

  drawGrid() {
    const lineColor = '#B2B5B5';

    this.drawHorizontalLine(this.drawValues ? this.yAxisLegendWidth + 3 : 5, window.innerWidth, this.getYCoorByPercentageValue(1), lineColor, 1, true);
    this.drawHorizontalLine(5, window.innerWidth, this.getYCoorByPercentageValue(0.66), lineColor, 1, true);
    this.drawHorizontalLine(5, window.innerWidth, this.getYCoorByPercentageValue(0.33), lineColor, 1, true);
    this.drawHorizontalLine(5, window.innerWidth, this.getYCoorByPercentageValue(0), lineColor, 1, false);
  }

  drawDataset(data: DataPoint[]) {

    this.context.fillStyle = 'black';

    let x1: number;
    let y1: number;

    let x2: number;
    let y2: number;

    let firstPoint: boolean;

    for ( let i = 0 ; i < data.length; i++) {
      firstPoint = i === 0;
      if (!firstPoint) {
        x1 = x2;
        y1 = y2;
      }
      x2 = TimePickerUtilService.getXPosForPoint(i, this.deltaX, this.firstPointXMarginLeft);
      y2 = this.getYCoorByPercentageValue(data[i].yPosPercent);

      if (data.length === 1) {
        this.context.fillRect(x2, y2, 3, 3);
      }

      if (!firstPoint) {
        this.drawLine(x1, y1, x2, y2, this.mainColorHex, 3, false);
      }
    }
  }

  drawHighlight() {
    if (this.currentPoint < 0 || this.currentPoint > this.numOfVisiblePoints - 1 ) {return; } // No point chosen yet or point is not visible
    this.drawVerticalLine(0, this.canvasHeight, this.highlightLeftEdge, this.mainColorHex, 1);
    this.drawVerticalLine(0, this.canvasHeight, this.highlightRightEdge, this.mainColorHex, 1);

    this.drawHighlightTriangles(this.highlightLeftEdge, this.highlightRightEdge, this.currentPoint === this.numOfVisiblePoints - 1);

    const width = this.highlightRightEdge - this.highlightLeftEdge;
    this.drawRectangle(this.highlightLeftEdge, 0, width, window.innerHeight, this.highlightColorRGB);

    const value = this.data[this.currentPoint].value
    const text = this.drawValues ? Util.numberToTimeRepresentation(value) : this.getDisplayTextFromValue(value);
    const xPos = ((this.highlightRightEdge + this.highlightLeftEdge) / 2) + TimePickerUtilService.getHightlightTextXShift(text);
    const yPos = TimePickerUtilService.getHighlightTextYPos(this.data[this.currentPoint], this.canvasHeight, this.chartHeight, this.xAxisLegendHeight, this.marginFromTopEdge);
    this.drawText(text, xPos, yPos, 'OswaldRegular', this.drawValues ? 20 : 17, this.mainColorHex );
  }

  drawHighlightTriangles(leftLineXPos: number, rightLineXPos: number, lastPoint: boolean) {
    let p1 = {x: leftLineXPos, y: this.hightlightTriangleYShift};
    let p2 = {x: leftLineXPos, y: this.hightlightTriangleSize + this.hightlightTriangleYShift};
    let p3 = {x: leftLineXPos - 10, y: this.hightlightTriangleSize};
    this.drawTriangle(p1, p2, p3, this.mainColorHex);

    p1 = {x: rightLineXPos, y: this.hightlightTriangleYShift};
    p2 = {x: rightLineXPos, y: this.hightlightTriangleSize + this.hightlightTriangleYShift};
    p3 = {x: rightLineXPos + 10, y: this.hightlightTriangleSize};

    // Don't draw right arrow if this is the last point
    if (!lastPoint) {
      this.drawTriangle(p1, p2, p3, this.mainColorHex);
    }
  }

  drawTriangle(p1: {x: number, y: number}, p2: {x: number, y: number}, p3: {x: number, y: number}, color: string) {
    this.context.beginPath();
    this.context.moveTo(p1.x, p1.y);
    this.context.lineTo(p2.x, p2.y);
    this.context.lineTo(p3.x, p3.y);
    this.context.fillStyle = color;
    this.context.fill();
  }

  drawBackground(color: string) {
    this.context.fillStyle = color;
    this.context.fillRect(0, 0, window.innerWidth, window.innerHeight);
  }

  drawHorizontalLine(x1: number, x2: number, y: number, color: string, width: number, dashed: boolean = false) {
    this.drawLine(x1, y, x2, y, color, width, dashed);
  }

  drawVerticalLine(y1: number, y2: number, x: number, color: string, width: number, dashed: boolean = false) {
    this.drawLine(x, y1, x, y2, color, width, dashed);
  }

  drawLine(x1: number, y1: number, x2: number, y2: number, color: string, width: number, dashed: boolean = false) {

    if (width === 1 ) {
      y1 -= 0.5; // Canvas issue with 1px width line - canvas calculates from half pixel
      y2 -= 0.5;
    }

    this.context.beginPath();
    this.context.setLineDash(dashed ? [2, 3] : []);
    this.context.moveTo(x1, y1);
    this.context.lineTo(x2, y2);

    this.context.strokeStyle = color;
    this.context.lineWidth = width;

    this.context.stroke();
  }

  drawText(text: string, xPos: number, yPos: number, font: string, size: number, color: string) {
    const fontString = `${size}px ${font}`;
    this.context.font = fontString;
    this.context.fillStyle = color;
    this.context.fillText(text, xPos, yPos);
  }

  drawRectangle(x0: number, y0: number, width: number, height: number, color: string) {
    this.context.fillStyle = color;
    this.context.fillRect(x0, y0, width, height);
  }

  clearCanvas() {
    this.context.clearRect(0, 0, this.canvasWidthBinded, this.canvasHeight);
  }

  /****************************************/

  /********** GETTERS **********/

  getWidthByNumberOfSections(sections: number) {
    // Added 2 pixels when setting the width for the right line of the highlight - without it the right line is drawn
    // at the edge of the canvas and get clipped a little. This is strictly for UI purposes.
    return (sections * this.deltaX) + (this.deltaX / 2) + this.firstPointXMarginLeft + 2;
  }

  /** Wrapper for the getYCoorFromPercentageValueFix() - sends all the instance values instead of writing them in each call */
  getYCoorByPercentageValue(percent: number): number {
    return TimePickerUtilService.getYCoorByPercentageValue(this.canvasHeight, percent,  this.chartHeight, this.xAxisLegendHeight, this.marginFromTopEdge);
  }

  /**
   * Method to get string when graph is qualitative
   */
  getDisplayTextFromValue = (value: number): string => {
    let res =  '';
    if (this.lowThreshold === undefined || this.highThreshold === undefined) {return res}
    if (value <= this.lowThreshold) {
      res = 'Low';
    } else if (this.lowThreshold < value && value <= this.highThreshold) {
      res = 'Medium';
    } else if (this.highThreshold < value) {
      res = 'High';
    }
    return res;
  }

  /****************************************/

  /********** MOUSE EVENTS AND POSITION CALCULATIONS **********/

  isClickedOnPoint(xPos: number): boolean {
    for (let i = 0; i < this.data.length; i++) {
      if (this.isMouseInPointZone(i, xPos) && this.currentPoint !== i) {
        this.setSelectedPoint(i);
        return true;
      }
    }
    return false;
  }

  /**
   * Check if event X position is in the defined zone of a point, given by its ID
   *
   * @param pointId - the id of the point in the array containing the points
   * @param eventXPos - the x position of the event
   */
  isMouseInPointZone(pointId: number, eventXPos: number): boolean {
    return ((eventXPos) < (this.data[pointId].xPos + (this.deltaX / 2))) && ((eventXPos) > (this.data[pointId].xPos - (this.deltaX / 2)));
  }

  subscribeToMouseClick() {
    this.context.canvas.addEventListener('click', (evt: MouseEvent) => this.onMouseClick(evt), false);
  }

  onMouseClick(evt: MouseEvent) {
    if (this.isClickedOnPoint(evt.offsetX)) {
      this.onSelection(this.currentPoint);
    }
  }

  toggleChart = () => {
    this.actions.snapshotsChartToggled();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    },
    50)
  }

  /****************************************/
}
