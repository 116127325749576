import { Component, Input, OnInit} from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';

import {
  IAppState,
  IGroupsState,
  ISnapshot,
  IGlobalState } from '../../app.state'
import * as U from '../../services/util.service'

@Component({
  selector: 'sa-breadcrumbs',
  template: `<div class='sa-breadcrubs-container'>{{breadcrumbsStr}}</div>`
})
export class BreadCrumbsComponent implements OnInit {
  breadcrumbsStr: string

  favorite: string

  @select(['groups', 'numberOfSelected'] ) readonly empsNumber$: Observable<number>;
  empsNumber: number

  @select(['global', 'snapshots'] ) readonly snapshots$: Observable<ISnapshot[]>;
  snapshots: string

  @select(['global', 'currentTab'] ) readonly tab$: Observable<string>;
  tab: string


  constructor() {
    this.favorite = 'Free'
    this.empsNumber = 0
    this.snapshots = 'Latest'
    this.tab = 'Emails'
    this.render()
  }

  ngOnInit() {

    this.empsNumber$.subscribe((state: number) => {
      this.empsNumber = state
      this.render()
    })

    this.snapshots$.subscribe((state: ISnapshot[]) => {
      if (state[0] === undefined || state[1] === undefined) {
        this.snapshots = 'Latest'
      } else {
        this.snapshots = state[0].name
      }
      this.render()
    })

    this.tab$.subscribe(state => {
      this.tab = U.capitalize(state)
      this.render()
    })
  }

  readonly render = (): void => {
    this.breadcrumbsStr = `${this.favorite} > ${this.empsNumber} > ${this.snapshots} > ${this.tab}`
  }
}
