import { Injectable } from '@angular/core';
import * as _ from 'lodash'
import * as util from '../../services/util.service'

import { NetworkType, Tid, IFilter } from '../../app.state'

const COMBO_IDS_RANGE     = '100000'
const INT_COMBO_IDS_RANGE = 100000
const COMBO           = 'combo'
const OVERLAY_ENTITY  = 'overlay_entity'

const trace        = util.traceToggle(false)
const longtrace    = util.traceToggle(false)
const traceWeights = util.traceToggle(false)

export interface ICsMap {
  nodes: INode[]
  links: ILink[]
  groups: IDept[]
  orig_groups?: any
  type: NetworkType
  redraw: boolean
  filters: IFilter[]
  hideNames: boolean
  uniColors: boolean
  questionnaireName?: string
  questionTitle?: string
  colorBy?: string
  filtersStr?: string
  department?: string
  clickToIsolateNodeId?: string
  edgesFromFilter?: number
  edgesToFilter?: number
  showCoreNetwork?: boolean
  factorNames?: any
}

export interface ILink {
  from_id: Tid
  from_type: string
  to_id: Tid
  to_type: string
  isBiDirectional?: boolean
  weight?: number
  inner_links?: string[]
  hi?: boolean
  c?: string
  hardHide?: boolean
}

export interface INode {
  id: Tid
  type: string
  to_links?: string[]
  from_links?: string[]
  gid: Tid
  orig_gid?: Tid
  containing_group_ref?: string // The group under which this node resides
  sons_count?: number
  hi: boolean
  fi?: boolean                   // Whehter the node should be filtered out
  combo_type?: string
  image_url?: string
  group_name?: string
  rate?: number
  color?: string
  color_id?: number
  name?: string
  combo_group_ref?: string       // The group this combo represents
  contained_nodes_refs?: INode[],
  rank?: number,
  role?: string,
  office?: string,
  job_title?: string,
  gender?: string,
  age?: string,
  e?: number,
  ha0?: any,
  param_a?: string
  param_b?: string
  param_c?: string
  param_d?: string
  param_e?: string
  param_f?: string
  param_g?: string
  param_h?: string
  param_i?: string
  param_j?: string
}

export interface IDept {
  id: Tid
  parentId: Tid
  parent_ref?: string
  name: string
  son_nodes_refs_list?: string[]
  son_groups_refs_list?: string[]
  cardinality?: number
  internal_index?: number
  color_id?: number
}

/**
 * Pretty print to string
 */
export const ppts = (arr, depth = 1) => {
  let ret = ''
  let ii = 0
  const tab = Array(depth).fill('  ').join('')
  _.forEach(arr, (e: any) => {
    ret = `${ret}\n${tab}[${ii}] -`
    _.forIn(e, (v, k) => {
      if (_.isObject(v)) {
        ret = `${ret} ${k}: ${ppts(v, depth + 1)}`
      } else {
        ret = `${ret} ${k}: ${v}`
      }
    })
    ii += 1
  })
  return ret
}

/******************************************************************************************
 * The code is generally partitioned into the following sections:
 *
 * - Utilities
 * - Hash and Key functions
 * - Prepare indexes
 * - Creators
 * - Core combine
 * - APIs
 *
 * This section describes the basic service's logic:
 * ---------------------------------------------------------
 * To optimize access to the various entities (nodes, links, groups) all input arrays are
 * indexed into hash_tables, and all cross references among object are hash-keys. The stage
 * creating the indexes is calle: "Prepare" and it happens proir to any actuall calculations.
 *
 * - Node keys start with 'N', ie the key to a node whose ID is 17 is 'N-17'
 * - Group keys start with 'G', ie a group whose ID is 7 is 'G-7', and a group whose name is 'male'
 *   is: 'G-male'
 * - Cobmo keys start also with 'N' but they will have the number: 100000 appended to them, so
 *   group's 17 combo's key is: 'N-100017', and group's 'Netanya's combo key is:
 *   'N-100000Netanya'
 * - Link keys begin with 'L', followed by from node's ID and then to node's ID. For example:
 *   'L-18-100003' is a link from node 17 to combo of group 3
 *
 * In order to avoid maintaining complicated states, every change in network presentation
 * rebuilds entire network sections from the ground up. The only state that's maintained is
 * which combos exist.
 * Because a combo represents both a group as well as a node in Keylines, it doesn't necesssarily
 * have a clear place. Since keylines will need them in the nodes array then that is where they
 * are maintained (as well as in nodes_hash).
 * Every time a combo is created or destroyed all links, node rates and link weights related to
 * it are recalculated. The sections below describe this:
 *
 * - combine a group:
 *   - A combo is created
 *   - All nodes (and combos) under it are hidden
 *   - All links related to nodes anywhere under the group (including under sub-groups) change
 *     references. Example: If we combine G-3 having node N-17 under it with link L-17-25
 *     which points to a node not under G-3, then a new link is created: L-100017-25.
 *   - Link weights are recalculated
 *
 * - uncombine:
 *   - The combo is removed
 *   - repeat procedure above for links
 *   - perform combine for every group under the group which is uncombined
 *   - Recalculate link weights
 *
 *
 * NOTE: A note about weights - When calculating weights between groups, it is important to remember
 *       that the weight is not calcualted comapre with the rest of the organization, rather it's
 *       calculated as a fraction of the total posible traffic between the groups.
 *
******************************************************************************************/

@Injectable()
export class CombineService {

  /** exporting this member for testing purposes only */
  public priv: any = {}

  // ======== Indexes  =============
  private links_hash:  {[key: string]: ILink}    = {}
  private nodes_hash:  {[key: string]: INode}    = {}
  private groups_hash: {[key: string]: IDept}   = {}
  // ======== Indexes  =============

  links_added = false
  group_nodes_refs_prepared = false
  groups_tree_prepared = false

  constructor() {
    this.priv.prepareDataStructures = this.prepareDataStructures
    this.priv.createOrGetComboLink = this.createOrGetComboLink
    this.priv.createNewLink = this.createNewLink
    this.priv.peekAtIndexes = this.peekAtIndexes
    this.priv.uniqueLinksArray = this.uniqueLinksArray
    this.priv.markBidirectionalLinks = this.markBidirectionalLinks
    this.priv.isAncestor = this.isAncestor
    this.priv.link_is_an_inner_link = this.link_is_an_inner_link
    this.priv.calculateGroupCardinality = this.calculateGroupCardinality
    this.priv.calculateAllGroupsCardinality = this.calculateAllGroupsCardinality
    this.priv.groups_hash = this.groups_hash
  }

  // ================== Hash Function ==================================================== //

  // --------------- Groups -------------------------------
  private groupKeyFromId = (group_id: Tid): string => {
    const ret = `G-${group_id}`
    return ret
  }

  private groupKey = (group: IDept): string => {
    if (group === undefined || group === null) { return undefined; }
    return this.groupKeyFromId( group.id )
  }

  private getGroup = (group_key: string): IDept => {
    // if (group_key.charAt(0) !== 'G') {
    //    group_key = this.groupKeyFromId(group_key)
    // }
    return this.groups_hash[group_key]
  }

  private getGroupFromId = (id: Tid): IDept => {
    return this.getGroup( this.groupKeyFromId(id))
  }

  // --------------- Nodes -------------------------------
  private nodeKey = (node: INode): string => {
    return `N-${node.id}`
  }

  private nodeKeyFromId = (node_id: Tid): string => {
    return `N-${node_id}`
  }

  private getNodeFromGroupId = (group_id: Tid): INode => {
    const num = typeof group_id === 'string' ? COMBO_IDS_RANGE + group_id : INT_COMBO_IDS_RANGE + group_id
    return this.nodes_hash[`N-${num}`]
  }

  private getNode = (node_key: string): INode => {
    const charatZero = node_key.charAt(0)
    if (charatZero !== 'N') {
      if (charatZero === 'G') {   // In case its a combo
        const num = INT_COMBO_IDS_RANGE + parseInt(node_key.substring(2), 10)
        node_key = `N-${num}`
      }
    }
    return this.nodes_hash[node_key]
  }

  private getNodeFromId = (id: Tid): INode => {
    return this.getNode( this.nodeKeyFromId(id))
  }

  private isNodeCombo = (node: INode): boolean => {
    return (node.type === COMBO)
  }

  private isNodeOpen = (nodes: INode[], node: INode): boolean => {
    const res = _.find(nodes, _.matches({ id: node.id, type: node.type }))
    return res !== undefined
  }

  // ------------ Links -----------------------------------
  private linkKeyFromIds = (from_id: Tid, to_id: Tid): string => {
    return `L-${from_id}-${to_id}`
  }

  private linkKey = (link: ILink): string => {
    return this.linkKeyFromIds(link.from_id, link.to_id)
  }

  private getLink = (link_key: string): ILink => {
    return this.links_hash[link_key]
  }

  private createLinksHashByFromIdAndToId = (links: ILink[]) => {
    if (links === undefined || links === null) {return {}}
    const res = _.map(links, (l: ILink) => res[this.linkKey(l)] = l )
    this.links_hash = res
    return res
  }
// ============== End Hash Function ================================================= //

// ================= Prepare Indexes ================================================ //

  ////////////////////////////////////////////////////////
  // The reason to augment the links to the nodes is that
  //   later on it is much faster to access them as direct
  //   references from their nodes.
  ////////////////////////////////////////////////////////
  private augmentLinkReferencesToEmps = (nodes: INode[], links: ILink[]): void => {
    trace('In augmentLinkReferencesToEmps()')
    if (this.links_added) { return; }

    // And add nodes to the golbal hash table
    _.forEach(nodes, (node: INode) => {
      this.nodes_hash[this.nodeKey(node)] = node
    })

    const tmp_to_links   = {}
    const tmp_from_links = {}

    // Prepare lists of links
    _.forEach(links, (link: ILink): void => {

      const tokey = this.nodeKeyFromId(link.to_id)
      let to_list = tmp_to_links[tokey]
      if (to_list === undefined) {
        to_list = []
        tmp_to_links[tokey] = to_list
      }
      to_list.push( this.linkKey(link) )

      const fromkey = this.nodeKeyFromId(link.from_id)
      let from_list = tmp_from_links[fromkey]
      if (from_list === undefined) {
        from_list = []
        tmp_from_links[fromkey] = from_list
      }
      from_list.push( this.linkKey(link) )

      // Now also add the link to the global hash table
      this.links_hash[this.linkKey(link)] = link
    })

    // Add the links lists to the nodes
    _.forEach(nodes, (node: INode) => {
      const node_key = this.nodeKey(node)
      node.to_links   = ( tmp_to_links[ node_key ]   === undefined ? [] : tmp_to_links[node_key] )
      node.from_links = ( tmp_from_links[ node_key ] === undefined ? [] : tmp_from_links[node_key] )
    });

    this.links_added = true
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  // Prepare references to nodes from the groups. Handels both hierarchical groups (structure)
  //   as well as groups from a flat structure like age and office
  //////////////////////////////////////////////////////////////////////////////////////////////
  private prepareGroupNodesReferences = (nodes: INode[], groups: IDept[]) => {
    trace('In prepareGroupNodesReferences()')
    if (this.group_nodes_refs_prepared) { return }

    _.forEach(nodes, (node: INode) => {
      if ( this.isNodeCombo(node) ) { return }
      let containing_group: IDept
      if (node.gid === null || node.gid === 'NA') {
        containing_group = _.find(groups, g => g.id === 'NA' )
        if (!containing_group) {
          containing_group = {
            id: 'NA',
            parentId: 'NA',
            name: 'NA'
          }
          groups.push(containing_group)
        }
      } else {
        containing_group = _.find(groups, (g) => {
          if (node.gid !== undefined) {
            return (g.id === node.gid)
          }
          return null
        })
      }
      if (containing_group === undefined) {return}
      node.containing_group_ref = this.groupKey(containing_group)
      let son_nodes_refs = containing_group.son_nodes_refs_list
      if (son_nodes_refs === undefined) {
        son_nodes_refs = []
        containing_group.son_nodes_refs_list = son_nodes_refs
      }
      son_nodes_refs.push( this.nodeKey(node) )
      node.hi = false
      node.sons_count = 1
    })
    this.group_nodes_refs_prepared = true
  }

  private calculateGroupCardinality = (group: IDept): number => {
    longtrace('In calculateGroupCardinality() - working on group: ', group.id)
    if (group.cardinality !== undefined ) { return group.cardinality }

    let cardinality    = (group.son_nodes_refs_list === undefined) ? 0 : group.son_nodes_refs_list.length
    longtrace('group.son_groups_refs_list: ', group.son_groups_refs_list)
    _.forEach(group.son_groups_refs_list, (gkey: string) => {
      const son_group: IDept = this.getGroup(gkey)
      longtrace('son_gropup: ', son_group)
      if (son_group === undefined) {
        return cardinality
      }
      if (son_group.cardinality !== undefined) { cardinality += son_group.cardinality }
      if (son_group.cardinality === undefined) {
        const son_cardinality = this.calculateGroupCardinality(son_group)
        son_group.cardinality = son_cardinality
        cardinality += son_cardinality
      }
    })
    group.cardinality = cardinality
    return cardinality
  }

  private calculateAllGroupsCardinality = (): {[key: string]: IDept} => {
    trace('In calculateAllGroupsCardinality()')
    _.forEach(this.groups_hash, (g: IDept) => {
      g.cardinality = g.cardinality === undefined ? this.calculateGroupCardinality(g) : g.cardinality
    })
    return this.groups_hash
  }

  ////////////////////////////////////////////////////////
  // Preparing a tree structure of references in order to
  //   speed up acess to the strucutre
  ////////////////////////////////////////////////////////
  private prepareGroupReferencesTree = (groups: IDept[]): void => {
    trace('In prepareGroupReferencesTree()')
    if (this.groups_tree_prepared) { return }
    const new_groups = _.clone(groups)
    _.forEach(new_groups, (gg: IDept, i: number) => {

      const parent: IDept = _.find(groups, {id: gg.parentId});
      gg.parent_ref = this.groupKey(parent)
      const refs               = _.filter(groups, {parentId: gg.id} )
      gg.son_groups_refs_list = _.map(refs, (g) => this.groupKey(g) )
      gg.son_nodes_refs_list = []
      gg.internal_index = i;
      this.groups_hash[this.groupKey(gg)] = gg

    })
    this.groups_tree_prepared = true;
  }

  private prepareDataStructures = (nodes: INode[], links: ILink[], groups: IDept[]) => {
    trace('In: prepareDataStructures()')
    this.augmentLinkReferencesToEmps(nodes, links)
    this.prepareGroupReferencesTree(groups)
    this.prepareGroupNodesReferences(nodes, groups)
    if (!this.links_hash) { this.links_hash = this.createLinksHashByFromIdAndToId(links) }
    this.calculateAllGroupsCardinality()
    this.priv.gropus_hash = this.groups_hash
  }
// ============= End Prepare Indexes ==================================================== //

// ================= Creators =========================================================== //
  private createNewLink = (from_id: Tid, from_type: string, to_id: Tid,
                           to_type: string, weight: number, inner: string[]): ILink => {
    const new_link = {
      from_id: from_id,
      from_type: from_type,
      to_id: to_id,
      to_type: to_type,
      isBiDirectional: false,
      weight: weight,
      inner_links: inner
    }
    return new_link
  }

  private createCombo = (group: IDept): INode => {
    trace('In: createCombo() for group: ', group.id)
    const comboId: Tid = (typeof group.id === 'string' ? COMBO_IDS_RANGE.concat( group.id ) : INT_COMBO_IDS_RANGE + group.id )

    let combo = this.getNodeFromId( comboId )
    const  containing_group_ref = (group.parentId === null ? null : this.groupKeyFromId(group.parentId))
    if (combo === undefined) {
      combo =  {
        id: comboId,
        gid: group.parentId,
        type: 'combo',
        combo_type: 'single',
        image_url: '/assets/images/group.svg',
        group_name: group.name,
        rate: null,
        color: util.col(group.color_id, true),
        hi: false,
        name: `${group.name} - ${group.cardinality}`,
        containing_group_ref: containing_group_ref,
        combo_group_ref: this.groupKey(group),
        to_links: [],
        from_links: [],
        sons_count: 0,
        contained_nodes_refs: []
      }
    }

    this.nodes_hash[this.nodeKey(combo)] = combo
    const parent_group = this.groups_hash[group.parent_ref]
    if (parent_group !== undefined) {
      parent_group.son_nodes_refs_list.push( this.nodeKey(combo) )
    }
    return combo
  }
// ============= End Creators =========================================================== //

// ============= The mess starts here =================================================== //

  private isAncestor = (descendant_key: string, ancestor_key: string): boolean => {
    longtrace(`isAncestor() - ${descendant_key} and ${ancestor_key}`)
    if (!descendant_key)                        {return false}
    if (descendant_key === ancestor_key)        {return true}

    const descendant = this.getGroup(descendant_key)
    if (descendant.parent_ref === ancestor_key) { return true }
    if (!descendant.parent_ref)    { return false }
    return this.isAncestor(descendant.parent_ref, ancestor_key)
  }

  private link_is_an_inner_link = (link: ILink, combine_root: string): boolean => {
    longtrace(`link_is_an_inner_link() for link:  ${this.linkKey(link)}, combine_root: ${combine_root}`)
    const toNode   = this.getNodeFromId(link.to_id)
    const fromNode = this.getNodeFromId(link.from_id);
    if (!fromNode || !toNode) { return true }
    const combine_root_group_key = this.getNode(combine_root).combo_group_ref
    const ret1 = this.isAncestor( toNode.containing_group_ref,   combine_root_group_key)
    const ret2 = this.isAncestor( fromNode.containing_group_ref, combine_root_group_key)
    return ret1 && ret2
  }

  ////////////////////////////////////////////////////////////////
  // Will look if there's already an existing link between node
  //   and combo. If not will create a new one and return it.
  ////////////////////////////////////////////////////////////////
  private createOrGetComboLink = (from_id: Tid, from_type: string, to_id: Tid, to_type: string, links: ILink[]): ILink => {
    trace(`In createOrGetComboLink() - from_id: ${from_id}, from_type: ${from_type}, to_id: ${to_id}, to_type: ${to_type}`)
    const link_key = this.linkKeyFromIds(from_id, to_id)
    let new_link = this.links_hash[link_key]
    if (new_link === undefined) {
      new_link = this.createNewLink(from_id, from_type, to_id, to_type, 0, []);
    }

    if (_.isNil(links)) { links = [] }
    links.push(new_link)
    this.links_hash[link_key] = new_link
    return new_link
  }

  private findAllSonNodes = (curr_group: IDept, son_nodes: string[] ): string[] => {
    trace('In findAllSonNodes() for group: ', curr_group.id)
    let ret_son_nodes: string[] = _.clone(son_nodes)
    const contained_nodes = curr_group.son_nodes_refs_list
    const son_groups_refs = curr_group.son_groups_refs_list

    _.forEach(contained_nodes, (nodeKey: string) => {
      ret_son_nodes.push(nodeKey)
    })

    _.forEach(son_groups_refs, (groupKey) => {
      const group = this.getGroup(groupKey)
      ret_son_nodes = _.union( ret_son_nodes, this.findAllSonNodes(group, ret_son_nodes) )
    })
    return ret_son_nodes
  }

  private hideAllNodes(nodesList: INode[]): INode[]  {
    const ret_nodes_list = _.clone(nodesList)
    _.forEach(ret_nodes_list, (node: INode) => {
      longtrace('Hideing node: ', this.nodeKey(node))
      node.hi = true
    });
    return ret_nodes_list
  }

  private highestVisibleParentByGroup = (group_id: Tid, visible_node: INode): INode => {
    const group = this.getGroupFromId(group_id)
    const node  = this.getNodeFromGroupId(group_id)
    trace(`highestVisibleParentByGroup() - 1 - group_id: ${group_id}, group: ${group.id}, node: ${node} `)

    if (node !== undefined && node.hi === false) { visible_node = node }
    if (group.parentId === null) { return visible_node }

    const ret = this.highestVisibleParentByGroup(group.parentId, visible_node)
    trace(`highestVisibleParentByGroup() - 2 - group: ${group_id} returning: ${(ret === null ? 'null' : ret.id)}` )
    return ret
  }

  private highestVisibleParentByNode = (node_id: Tid): INode => {
    trace('In highestVisibleParentByNode() for node: ', node_id)
    const node = this.getNodeFromId(node_id)
    const groupKey = node.containing_group_ref
    const node_is_visible = !node.hi
    const containing_group = this.getGroup(groupKey)
    let visible_node = null
    if (node_is_visible) { visible_node = node }
    return this.highestVisibleParentByGroup(containing_group.id, visible_node)
  }

  private reasignLinksOfNode = (node: INode, id_to_reasign_to: Tid, touched_links_list: ILink[], links: ILink[]): ILink[] => {
    trace(`In reasignLinksOfNode for node: ${node.id}, id_to_reasign_to: ${id_to_reasign_to}`)
    if (node.type === 'combo') { return touched_links_list }

    const to_links = _.clone(node.to_links)
    _.forEach(to_links, (lkey: string) => {
      longtrace(`handling to_list, lkey: ${lkey}`)
      const link_to_hide = this.getLink(lkey)
      if (this.link_is_an_inner_link(link_to_hide, this.nodeKeyFromId(id_to_reasign_to) ) ) { return }
      const from_node = this.highestVisibleParentByNode(link_to_hide.from_id)
      if (from_node !== null) {
        longtrace(`Found from_node: ${from_node.id}`)
        const visible_link = this.createOrGetComboLink(from_node.id, from_node.type, id_to_reasign_to, 'combo', links)
        const linkToHideKey: string = this.linkKey(link_to_hide)
        visible_link.inner_links = _.union( visible_link.inner_links, [linkToHideKey] )
        touched_links_list = _.union(touched_links_list, [ visible_link] )
      }
    })

    const from_links = _.clone(node.from_links)
    _.forEach(from_links, (lkey: string) => {
      longtrace(`handling from_list, lkey: ${lkey}`)
      const link_to_hide = this.getLink(lkey)
      if (this.link_is_an_inner_link(link_to_hide, this.nodeKeyFromId( id_to_reasign_to))) { return }
      const to_node = this.highestVisibleParentByNode(link_to_hide.to_id)
      if (to_node !== null) {
        longtrace(`Found visible node: ${to_node} for highestVisibleParentByNode() of: ${link_to_hide.to_id}`)
        const visible_link = this.createOrGetComboLink(id_to_reasign_to, 'combo', to_node.id, to_node.type, links)
        const linkToHideKey: string = this.linkKey(link_to_hide)
        visible_link.inner_links = _.union(visible_link.inner_links, [linkToHideKey] )
        touched_links_list = _.union(touched_links_list, [ visible_link] )
      }
    })
    return touched_links_list
  }

  private reassignLinksFromNodesListToVisibleLinks = (nodes: INode[], id_to_reassign_to: Tid,
                                                      links: ILink[]): ILink[] => {
    trace(`In reasign_links_from_nodes_list_to_visible_links with id_to_reasign_to: ${id_to_reassign_to}`)
    let touched_links_list = []
    _.forEach(nodes, (node: INode) => {
      touched_links_list = this.reasignLinksOfNode(node, id_to_reassign_to, touched_links_list, links)
    })
    return touched_links_list
  }

  private reasignLinksFromComboToParentNode = (node: INode, touched_links_list: ILink[], links: ILink[]): ILink[] => {
    trace(`In reasignLinksFromComboToParentNode() for node: ${node.id}`)
    if (node.type === 'combo') { return touched_links_list }

    const to_links = _.clone(node.to_links)
    _.forEach(to_links, (lkey: string) => {
      trace(`to_list, lkey: ${lkey}`)
      const link_to_hide = this.getLink(lkey)
      const from_node = this.highestVisibleParentByNode(link_to_hide.from_id)
      const visible_link = this.createOrGetComboLink(from_node.id, from_node.type, node.id, node.type, links)
      const linkToHideKey: string = this.linkKey(link_to_hide)
      visible_link.inner_links = _.union( visible_link.inner_links, [linkToHideKey] )
      touched_links_list = _.union(touched_links_list, [ visible_link] )
    })

    const from_links = _.clone(node.from_links)
    _.forEach(from_links, (lkey: string) => {
      trace(`from_list, lkey: ${lkey}`)
      const link_to_hide = this.getLink(lkey)
      const to_node = this.highestVisibleParentByNode(link_to_hide.to_id)
      trace(`Found visible node: ${to_node} for highestVisibleParentByNode() of: ${link_to_hide.to_id}`)
      const visible_link = this.createOrGetComboLink(node.id, node.type, to_node.id, to_node.type, links)
      const linkToHideKey: string = this.linkKey(link_to_hide)
      visible_link.inner_links = _.union(visible_link.inner_links, [linkToHideKey] )
      touched_links_list = _.union(touched_links_list, [ visible_link] )
    })
    return touched_links_list
  }

  private reassignLinksFromComboToSonNodes = (son_nodes_list: string[], links: ILink[]): ILink[] => {
    trace(`In reassignLinksFromComboToSonNodes()`)
    let touched_links_list = []
    _.forEach(son_nodes_list, (nKey) => {
      const node = this.getNode(nKey)
      touched_links_list = this.reasignLinksFromComboToParentNode(node, touched_links_list, links)
    })
    return touched_links_list
  }

  private containingGroupCardinality = (nodeKey: string): number => {
    const node = this.getNode(nodeKey)
    if ( this.isNodeCombo(node) ) {
      const group_key = node.combo_group_ref
      return this.getGroup(group_key).cardinality
    }
    return 1
  }

  private isLinkSingleToSingle = (linkKey: string): boolean => {
    const link = this.getLink(linkKey)
    const from_node = this.getNodeFromId( link.from_id)
    if (this.isNodeCombo(from_node)) { return false }
    const to_node = this.getNodeFromId(link.to_id)
    if (this.isNodeCombo(to_node)) { return false }
    return true
  }

  private recalculateLinksWeights = (links_list: ILink[], measure_type: number) => {
    traceWeights('In recalculateLinksWeights')
    if (links_list === undefined) { return }
    _.forEach( links_list, (link: ILink) => {
      let weight = 0
      traceWeights(`Working on link: ${this.linkKey(link)}`)
      _.forEach( link.inner_links, (inner_key: string) => {
        if ( this.isLinkSingleToSingle(inner_key) ) {
          const inner_link = this.getLink(inner_key)
          traceWeights(`inner link key: ${inner_key} with weight: ${inner_link.weight}`)
          weight += inner_link.weight
        }
      })

      let count = -1
      traceWeights(`weight before normailizing: ${weight}`)
      if (measure_type !== NetworkType.emails) {
        traceWeights('Not an EMAILS weight calculation')
        weight *= 5
        const from_group_count = this.containingGroupCardinality( this.nodeKeyFromId(link.from_id) )
        const to_group_count   = this.containingGroupCardinality( this.nodeKeyFromId(link.to_id) )
        count = from_group_count * to_group_count
        link.weight = Math.round(weight / count) + 1
      } else {
        traceWeights('EMAILS weight calculation')
        count = _.filter(link.inner_links, (l: string) => {
          return (this.getLink(l).to_type !== COMBO && this.getLink(l).from_type !== COMBO)
        }).length
        link.weight = Math.round(weight / count)
      }
      traceWeights(`weight after normailizing: ${link.weight}`)
    })
  }

  private breakSymetry = (id1: any, id2: any): boolean => {
    if (id1 > id2) { return true }
    if (typeof id1 === 'string') { return true }
    return false
  }

  private markBidirectionalLinks = (links: ILink[], link_type = NetworkType.boolean): ILink[] => {
    trace('In markBidirectionalLinks()')
    if (links === undefined) { return }
    _.forEach( links, (link: ILink) => {
      const reciprocal_link = this.getLink( this.linkKeyFromIds(link.to_id, link.from_id) )
      if (!reciprocal_link)                       { return }
      if (reciprocal_link.weight !== link.weight && link_type ===  NetworkType.boolean && 
          !this.isLinkSingleToSingle(this.linkKeyFromIds(link.from_id, link.to_id))) { return }
      if (this.breakSymetry(link.from_id, reciprocal_link.from_id)) { return }
      link.isBiDirectional = true
      reciprocal_link.hi = true
      reciprocal_link.hardHide = true
    })
    return links
  }

  // ======================== The mess ends about here .. =================== //

  private uniqueLinksArray = (links: ILink[]): ILink[] => {
    trace('uniqing')
    let link
    const tmp_links_hash = {}
    while (links.length) {
      link = links.pop()
      tmp_links_hash[this.linkKey(link)] = link
    }
    _.forEach(tmp_links_hash, (l: ILink) => { links.push(l) })
    return links
  }

  private combine = (links: ILink[], groupId: Tid): ILink[] => {
    trace(`In combine for group: ${groupId}`)
    if (groupId === undefined) { groupId = -1 }
    const curr_group    = this.getGroupFromId(groupId)
    const curr_combo    = this.createCombo(curr_group)
    const all_son_nodes_keys   = this.findAllSonNodes(curr_group, [])

    // Need to filter out undefined (isNil) nodes becuase that is what combos turn out to
    // be some of the time.
    let all_son_nodes: INode[] = _.map(all_son_nodes_keys, (k: string) => this.getNode(k) )
    all_son_nodes = _.filter(all_son_nodes, (nd: INode) => (nd !== undefined) )

    trace(`all_son_nodes: ${ppts(all_son_nodes)}`)
    curr_combo.hi = false
    all_son_nodes = this.hideAllNodes(all_son_nodes)
    const touched_links = this.reassignLinksFromNodesListToVisibleLinks(all_son_nodes, curr_combo.id, links )

    let rate = 0
    curr_combo.sons_count = 0
    _.each(all_son_nodes, (node: INode) => {
      rate += node.rate
      curr_combo.sons_count += node.sons_count
    })

    rate /= (<any>all_son_nodes).length
    curr_combo.rate = isNaN(rate) ? 0 : rate
    this.uniqueLinksArray(links)

    return touched_links
  }

  private combineAll = (links: ILink[], groupId: Tid): ILink[] => {
    trace(`In combineAll() for group_value: ${groupId}`)
    _.forEach(this.nodes_hash, (v: INode) => {
      v.hi = true
    })
    if (groupId === undefined) { groupId = -1 }
    const curr_group    = this.getGroupFromId(groupId)
    const curr_combo    = this.createCombo(curr_group)
    curr_combo.hi = false

    const touched_links = []
    _.forEach(this.groups_hash, (g: IDept) => {

      if ( _.isNil(g.parentId) ) {
        trace(`Going to combine group: ${g.id}`)
        this.combine(links, g.id)
      }
    })

    return touched_links
  }

  private uncombine = (links: ILink[], groupId: Tid, curr_combo: INode, link_type: number = NetworkType.emails) => {
    trace(`In uncombine for group: ${groupId}`)
    const curr_group     = this.getGroupFromId(groupId)
    curr_combo.hi = true

    const direct_son_nodes = curr_group.son_nodes_refs_list
    _.forEach(direct_son_nodes, (nKey: string) => {
      const node = this.getNode(nKey)
      if (node !== undefined) {
        node.hi = false
      }
    })
    const son_groups = curr_group.son_groups_refs_list

    trace(`son_groups: ${son_groups}`)
    let touched_links = []
    _.forEach(son_groups, (gKey: string) => {
      const group = this.getGroup(gKey)
      touched_links = _.union(touched_links, this.combine(links, group.id))
    })

    touched_links = _.union(touched_links, this.reassignLinksFromComboToSonNodes(direct_son_nodes, links))
    this.recalculateLinksWeights(touched_links, link_type)
    this.uniqueLinksArray(links)
    this.markBidirectionalLinks(links, link_type)
  }

  private getOnlyNodesInDisplay = (nodes: INode[]): INode[] => {
    trace('In getOnlyNodesInDisplay()')
    while (nodes.length) { nodes.pop() }
    _.forEach(this.nodes_hash, (n: INode) => {
      longtrace(`working on node: ${this.nodeKey(n)}`)
      let show_node = (n.hi === false)
      if (this.isNodeCombo(n)) {
        const group = this.getGroup(n.combo_group_ref)
        show_node = show_node && (group.cardinality > 0)
      }
      if (show_node) { nodes.push(n) }
    })
    longtrace('Done getOnlyNodesInDisplay()')
    return nodes
  }

  // ========================= API ===============================//

  //////////////////////////////////////////////////////
  // Preapare the internal data structures
  //////////////////////////////////////////////////////
  public combineServiceInitialize = (map: ICsMap): ICsMap => {
    this.prepareDataStructures(map.nodes, map.links, map.groups)
    map.nodes = this.colorNodes(map.nodes)
    map.links = this.markBidirectionalLinks(map.links)
    return map
  }

  public handleBidirectionalLinks = (nodes: INode[], links: ILink[], groups: IDept[]) => {
    trace('In: handleBidirectionalLinks')
    this.prepareDataStructures(nodes, links, groups)
    this.markBidirectionalLinks(links)
  }

  //////////////////////////////////////////////////////
  // Group one node
  //////////////////////////////////////////////////////
  public collapseBranchUnderGroup = (inmap: ICsMap, groupId: Tid): ICsMap => {
    const outmap = _.cloneDeep(inmap)
    trace(`In: collapseBranchUnderGroup() - group: ${groupId}`)
    this.prepareDataStructures(outmap.nodes, outmap.links, outmap.groups)
    const touched_links = this.combine(outmap.links, groupId)
    this.recalculateLinksWeights(touched_links, outmap.type)
    this.markBidirectionalLinks(outmap.links)
    this.getOnlyNodesInDisplay(outmap.nodes)
    return outmap
  }

  //////////////////////////////////////////////////////
  // Group all the way
  //////////////////////////////////////////////////////
  public collapseAll = (map: ICsMap, linksType: number): ICsMap => {
    const cmap = _.cloneDeep(map)
    trace('In collapseAll')
    const rootGroup = _.find(map.groups, (g: IDept) => g.parentId === null)
    const groupId = rootGroup.id

    this.prepareDataStructures(cmap.nodes, cmap.links, cmap.groups)
    const touched_links = this.combineAll(cmap.links, groupId )
    this.recalculateLinksWeights(touched_links, linksType)
    this.markBidirectionalLinks(cmap.links)
    this.getOnlyNodesInDisplay(cmap.nodes)
    return cmap
  }

  //////////////////////////////////////////////////////
  // Ungroup a single combo
  //////////////////////////////////////////////////////
  public ungroupComboOnceById = (inmap: ICsMap, comid: number|string): ICsMap => {
    const outmap = _.cloneDeep(inmap)
    const nodes: INode[] = outmap.nodes
    const links: ILink[] = outmap.links
    const linksType      = outmap.type

    trace(`In: ungroupComboOnceById for combo: ${comid}`)
    const curr_combo = this.getNodeFromId( comid )
    trace(`Group of curr_combo: ${curr_combo.combo_group_ref}`)
    const curr_group = this.getGroup( curr_combo.combo_group_ref )
    this.uncombine(links, curr_group.id, curr_combo, linksType)
    const ret_nodes = this.getOnlyNodesInDisplay(nodes)
    return outmap
  }

  //////////////////////////////////////////////////////
  // Ungroup everything under a given combo
  //////////////////////////////////////////////////////
  public ungroupAll = (inmap: ICsMap): ICsMap => {
    trace('In: recursivelyUngroupCombo')
    const nh = {}
    const lh = {}
    _.forEach(this.nodes_hash, (n: INode, k: any) => {
      if (n.type !== COMBO) {
        nh[k] = n
      }
    })
    this.nodes_hash = nh
    _.forEach(this.links_hash, (l: ILink, k: any) => {
      if (l.from_type !== COMBO && l.to_type !== COMBO) {
        lh[k] = l
      }
    })
    this.links_hash = lh
   
    let touched_links = []
    _.forEach(this.links_hash, (l: ILink) => {
      touched_links.push(l)
    })
    touched_links = this.markBidirectionalLinks(touched_links)

    const ret_nodes = this.getAllNodes()
                       .map( (n: INode) => {
                         n.hi = false
                         return n
                       } )

    const outmap: ICsMap = {
      nodes: ret_nodes,
      links: _.cloneDeep(touched_links),
      groups: _.cloneDeep(inmap.groups),
      type: inmap.type,
      redraw: true,
      filters: inmap.filters,
      hideNames: inmap.hideNames,
      uniColors: inmap.uniColors
    }
    return outmap
  }

  //////////////////////////////////////////////////////
  // Reset combine data structures
  //////////////////////////////////////////////////////
  public resetData = () => {
    trace(`In resetData`)
    this.links_added = false
    this.group_nodes_refs_prepared = false
    this.groups_tree_prepared = false
    this.links_hash    = {}
    this.nodes_hash    = {}
    this.groups_hash   = {}
  }


  public isCombo = (nodeId: Tid): boolean => {
    const key = this.nodeKeyFromId(nodeId)
    const node = this.nodes_hash[key]
    return node.type === COMBO
  }

  public groupIdFromNode = (nodeId: Tid): Tid => {
    const nid: number = _.isString(nodeId) ? parseInt(<string>nodeId, 0) : <number>nodeId
    const node = this.getNodeFromId(nid)
    return node.gid
  }

  public nodeFromId = (nodeId: Tid): INode => {
    const nid: number = _.isString(nodeId) ? parseInt(<string>nodeId, 0) : <number>nodeId
    return this.getNodeFromId(nid)
  }

  /**
   * When the colorby property is changed the underlying groups also change,
   * so need to update the groups structures.
   */
  public isGrouped = (): boolean => {
    const nodeKeys = Object.keys( this.nodes_hash)
    const combo = _.find( nodeKeys , (key: string) => {
      const sKey = key.slice(2)
      if ( !_.isNaN(sKey) ) {
        return (parseInt(sKey, 10) > INT_COMBO_IDS_RANGE)
      }
      return sKey.slice(0, 6) === COMBO_IDS_RANGE
    })

    return combo !== undefined
  }

  /*
   * Get the nodes as they're kept in the index.
   * @param withCombos - Signals whether to include combos in the result
   *                     or not.
   */
  public getAllNodes = (withCombos: boolean = false): INode[] => {
    if (!withCombos) {
      return _.filter(this.nodes_hash, (n) => !this.isCombo(n.id))
              .map((n) => n)
    }
    return _.map(this.nodes_hash, (n) => n)
  }

  /**
   * If node of nid is already isolated then un-isolate it.
   * If not then color all connecte links.
   */
  public clickToIsolate = (map: ICsMap , nid: string): ICsMap => {
    trace('In: clickToIsolate')
    const node: INode = this.getNodeFromId(nid)
    const isolateOn = map.clickToIsolateNodeId !== nid

    node.to_links.forEach(  (lkey) => {
      const link = this.getLink(lkey)
      link.c = isolateOn ? '#000000' : undefined
    })
    node.from_links.forEach((lkey) => {
      const link = this.getLink(lkey)
      link.c = isolateOn ? '#000000' : undefined
    })

    map.links = _.values( this.links_hash)
    map.clickToIsolateNodeId = isolateOn ? nid : undefined
    return map
  }

  /**
   * Show only links whose weight is between min and max
   */
  public filterEdges = (map: ICsMap): ICsMap => {
    trace('In filterEdges()')
    _.map(this.links_hash, (l: ILink) => {
      l.hi = l.weight <= map.edgesFromFilter || l.weight >= map.edgesToFilter || (l.hi && l.hardHide)
    })
    map.links = _.values( this.links_hash)
    return map
  }

  /**
   * Show only the most robust part of the network. In case of boolean
   * networks it means bidirecetional links. In case of none boolean network
   * we'll see.
   */
  public showCoreNetwork = (map: ICsMap): ICsMap => {
    trace('In showCoreNetwork()')
    _.map(this.links_hash, l => l.hi = map.showCoreNetwork ? !l.isBiDirectional : (l.hi && l.hardHide ?  true : false))
    map.links = _.values( this.links_hash)
    return map
  }

  // ========================= Utilities ===============================//
  /**
   * peek at indexes
   */
  private peekAtIndexes = () => {
    return [this.nodes_hash, this.links_hash, this.groups_hash]
  }

  private colorNodes = (nodes: INode[]): INode[] => {
    return _.map(nodes, (n) => {
        const group = this.getGroupFromId( n.gid )
        if (group === undefined)
          return 
        n.color = util.col(group.color_id, true)
        return n
      })
  }
}
