import { Component, OnInit, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { select } from '@angular-redux/store';
import * as _ from 'lodash'

import {
  IAlert,
  IGroup,
  IAppState
} from '../../app.state'
import { AppActions } from '../../app.actions'

import * as util from '../../services/util.service'
import * as gs from '../../services/groups.service'
import { AlertActions } from './alerts.actions';

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  @select(['alerts', 'alerts']) readonly alertsList$: Observable<IAlert[]>
  alertsList: IAlert[]
  numOfAlerts = 0

  @select(['global', 'alertsOpened']) readonly alertsOpened$: Observable<boolean>;
  alertsIsOpened = false

  @select(['groups', 'groups']) readonly groups$: Observable<IGroup[]>
  selectedGroups: number[]

  @select(['global', 'timeInterval']) readonly timeInterval$: Observable<{currentInterval: string, previousInterval: string}>;
  currentInterval: string;

  constructor(
    public appActions: AppActions,
    public alertActions: AlertActions
  ) {}

  ngOnInit() {
    trace('Initing ....')
    this.alertsOpened$.subscribe( state => {
      this.alertsIsOpened = state
    })

    this.alertsList$.subscribe(state => {
      this.alertsList = _.filter(state, a => a.state === 'pending' )
      this.numOfAlerts = this.alertsList.length
    })

    this.timeInterval$.subscribe(state => {
      trace('AlertsComponent - TimeInterval changed')
      this.currentInterval = state.currentInterval
      this.fetchAlertsData(this.selectedGroups, this.currentInterval)
    })

    this.groups$.subscribe( (state: IGroup[]) => {
      trace('AlertsComponent - Groups changed')
      this.selectedGroups = gs.getActiveGroups(state)
      this.fetchAlertsData(this.selectedGroups, this.currentInterval)
    })
  }

  fetchAlertsData = (gids: number[], currentInterval: string): void => {
    trace('AlertsComponent - Trying to fetch alerts')
    if (currentInterval === undefined || currentInterval.match(/[a-zA-Z0-9\/]/) === null) { return }
    if (gids === undefined) { return }
    trace('AlertsComponent - going to fetch alerts')
    this.alertActions.fetchAlerts({gids: gids, curr_interval: currentInterval})
  }

  bellClicked = () => {
    this.appActions.alertsToggled()
  }

  acknowledgeClicked = (aid: number) => {
    trace('alert with ID: ', aid, ' was acknowledged')
    this.alertActions.acknowledgeAlert(aid)
  }

  ignoreClicked = (aid: number) => {
    trace('alert with ID: ', aid, ' was ignored')
  }
}
