﻿import { Component, OnInit, ReflectiveInjector } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { NgRedux, select } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'

import { UserModel } from './components/user/user.model'

import { AjaxService } from './services/ajax.service'
import { LogoutService } from './services/logout.service'
import { IGlobalState } from './app.state'
import { AppActions } from './app.actions'

import * as util from './services/util.service'

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @select(['global', 'navOpened']) readonly navOpened$: Observable<boolean>
  @select(['global', 'currentTab']) readonly currentTab$: Observable<string>
  @select(['global', 'currentUser']) readonly currentUser$: Observable<string>

  constructor(
    public ajaxsrv: AjaxService,
    private router: Router,
    private appActions: AppActions,
    private ngRedux: NgRedux<IGlobalState>,
    private logoutService: LogoutService
    ) {

      this.currentUser$.subscribe((user: any) => {
        if (user !== undefined && user.email !== undefined) {
          appActions.fetchConfigParams()
        }

        /** For questionnaire customers show only the Interact tab **/
        if (user.product_type === 'questionnaire_only') {
          (<any>window.location) = '/#/interact'
        }
      })
    }

  ngOnInit() {
    this.checkLogin()
  }

  checkLogin(): void {
    this.ajaxsrv.get('/v3/get_user_details', {}, (res) => {
      const user_info = JSON.parse( res['_body'] )
      trace('AppComponent - checkLogin() - result from server: ', user_info)
      const userData: UserModel = new UserModel(user_info)
      this.appActions.userSignedIsInAction(userData)
    })
  }

  getGlobalState: () => IGlobalState = function() {
    return this.ngRedux.getState().global
  }

  isLoggedIn() {
    if ( localStorage.getItem('jwtToken') === null ) { return false }
    return true
  }

  isSpinnerOn() {
    const state: IGlobalState = this.getGlobalState()
    return state.spinner
  }
}
