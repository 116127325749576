import { Injectable } from '@angular/core'
import { NgRedux } from '@angular-redux/store'
import { Action } from 'redux'
import * as _ from 'lodash'
import {traceToggle} from '../../services/util.service'

import {
  IInterfacesState,
  IGlobalState,
  IInterfacesRow,
  IGroupsHashMap,
  IGroup
} from '../../app.state'
import { DataService } from '../../services/data.service'

export interface IInterfacesAction extends Action {
  selectedView?: string,
  selectedRow?: number,
  groupsArr?: IGroup[],
  aggregator?: string,
  scores?: IInterfacesRow,
  intraffic?: number,
  volume?: number,
  receiving?: number,
  prodStats?: any,
  timePickerData?: any,
  mapData?: {nodes: any[], links: any[], result_type: string}
}

const trace = traceToggle(false)

@Injectable()
export class InterfacesActions {

  static FETCH_INTERFACES_STATS                    = 'interfaces fetch stats'
  static FETCH_INTERFACES_STATS_SUCCESS            = 'interfaces fetch stats success'

  static FETCH_INTERFACES_TIME_PICKER_DATA         = 'interfaces fetch time picker'
  static FETCH_INTERFACES_TIME_PICKER_DATA_SUCCESS = 'interfaces fetch time picker success'

  static AGGREGATOR_TYPE_CHANGED                   = 'interfaces aggregator type changed'

  static USER_TOGGLED_VIEW_STATE                   = 'interfaces user toggled view';

  static FETCH_INTERFACES_MAP                      = 'interfaces fetch map'
  static FETCH_INTERFACES_MAP_SUCCESS              = 'interfaces fetch map success'
  static FETCH_INTERFACES_MAP_FAIL                 = 'interfaces fetch map fail'

  static FETCH_INTERFACES_SCORES                   = 'interfaces fetch scores'
  static FETCH_INTERFACES_SCORES_SUCCESS           = 'interfaces fetch scores success'

  static USER_SELECTED_ROW                         = 'interfaces user selected row';

  constructor(
    private ngRedux: NgRedux<IInterfacesState>,
    private ngReduxGlobal: NgRedux<IGlobalState>,
    private ds: DataService
  ) {}

  fetchInterfacesStats = (params) => {
    this.ngRedux.dispatch({type: InterfacesActions.FETCH_INTERFACES_STATS})
    this.ds.getInterfacesStats(params, (stats) =>
      this.fetchInterfacesStatsSuccess(stats));
  }

  private fetchInterfacesStatsSuccess = (stats) => {
    this.ngRedux.dispatch({
      type: InterfacesActions.FETCH_INTERFACES_STATS_SUCCESS,
      prodStats: stats
    })
  }

  fetchTimePickerData = (params) => {
    this.ngRedux.dispatch({type: InterfacesActions.FETCH_INTERFACES_TIME_PICKER_DATA})
    this.ds.getInterfacesTimePickerData(params, (timePickerData) =>
      this.fetchTimePickerDataSuccess(timePickerData));
  }

  private fetchTimePickerDataSuccess = (timePickerData) => {
    this.ngRedux.dispatch({
      type: InterfacesActions.FETCH_INTERFACES_TIME_PICKER_DATA_SUCCESS,
      timePickerData: timePickerData
    })
  }

  aggregatorTypeChanged = (aggregator: string): void => {
    this.ngRedux.dispatch({
      type: InterfacesActions.AGGREGATOR_TYPE_CHANGED,
      aggregator: aggregator
    })
  }

  setViewStateFromToggler = (selectedView: string) => {
    trace('InterfacesActions - setViewStateFromToggler()')
    this.ngRedux.dispatch({
      type: InterfacesActions.USER_TOGGLED_VIEW_STATE,
      selectedView: selectedView
    })
  }

  /**
   * Same as setViewState above only it doens't fetch new
   * map data.
   */
  toggleViewState = (selectedView: string) => {
    trace('In toggleViewState(), selectedView: ', selectedView)
    this.ngRedux.dispatch({
      type: InterfacesActions.USER_TOGGLED_VIEW_STATE,
      selectedView: selectedView
    })
  }

  fetchInterfacesMap = () => {
    trace('InterfacesActions - fetchInterfacesMap()')
    const state = this.ngRedux.getState()
    const interval: string = (<any>state).global.timeInterval.currentInterval
    const groupId: number = (<any>state).interfaces.selectedRow
    const groups: IGroupsHashMap = (<any>state).groups.groups
    const gids: number[] = _.values(groups).map(g => g.gid)

    if (groupId === -1) {
        console.warn('Nothing selected')
        alert('Cannot display map without a group')
        return 'no group selected'
    }

    this.ds.getInterfacesMap({
      gid: groupId,
      gids: gids,
      interval: interval,
    }, (mapData) => {
      this.fetchInterfacesMapSuccess(mapData);
    })

    this.ngRedux.dispatch({ type: InterfacesActions.FETCH_INTERFACES_MAP })
    return 'ok'
  }

  fetchInterfacesMapSuccess = (mapData) => {
    this.ngRedux.dispatch({
      type: InterfacesActions.FETCH_INTERFACES_MAP_SUCCESS,
      mapData: mapData
    })
  }

  fetchInterfacesMapFail = () => {
    this.ngRedux.dispatch({
      type: InterfacesActions.FETCH_INTERFACES_MAP_FAIL
    })
  }

  setSelectedInterfacesRow = (selectedRow: number, intraffic: number, volume: number, receiving: number) => {
    const state = this.ngRedux.getState()
    const groups: IGroupsHashMap = (<any>state).groups.groups
    const groupsArr: IGroup[] = _.values(groups)

    this.ngRedux.dispatch({
      type: InterfacesActions.USER_SELECTED_ROW,
      selectedRow: selectedRow,
      intraffic: intraffic,
      volume: volume,
      receiving: receiving,
      groupsArr: groupsArr
    })

    this.fetchInterfacesMap()
  }

  fetchInterfacesScores = (params) => {
    this.ngRedux.dispatch({type: InterfacesActions.FETCH_INTERFACES_SCORES})
    this.ds.getInterfacesScores(params, (scores) =>
      this.fetchInterfacesScoresSuccess(scores));
  }

  private fetchInterfacesScoresSuccess = (scores) => {
    const state = this.ngRedux.getState()
    const groups: IGroupsHashMap = (<any>state).groups.groups
    const groupsArr: IGroup[] = _.values(groups)

    this.ngRedux.dispatch({
      type: InterfacesActions.FETCH_INTERFACES_SCORES_SUCCESS,
      scores: scores,
      groupsArr: groupsArr
    })

    this.fetchInterfacesMap()
  }
}
