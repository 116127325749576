﻿import { Component, OnInit } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { NgRedux, select } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'

import { AppActions } from '../../app.actions'
import { IGlobalState } from '../../app.state'
import { AjaxService } from '../../services/ajax.service'

@Component({
  selector: 'sa-main-menu-comp',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss', '../../app.component.scss']
})
export class MainMenuComponent implements OnInit {

  tabClass = 'sa-header-tab active-tab'
  clicked = false

  showComingSoonMessage4 = false
  showComingSoonMessage2 = false

  @select(['global', 'configParams', 'product_type']) readonly productType$: Observable<string>;
  productType: string
  isQuestionnaireOnly = false

  constructor(
    private router: Router,
    private appActions: AppActions,
    private ngRedux: NgRedux<IGlobalState>,
    private ajaxService: AjaxService) {
  }

  ngOnInit() {
    // subscribe to router events
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe(this.routerEventHandler)

    this.productType$.subscribe((state) => {
      this.productType = state
      this.isQuestionnaireOnly = (state === 'questionnaire_only')
    })
  }

  routerEventHandler = (event) => {
    let url = event['url']
    if (url === '/') { return; }
    url = url.slice(1)

    if (url === 'settings') {
      this.appActions.hideNavBar();
    }
    this.appActions.tabChanged(url)
  }

  isCurrentTab(tabName: string) {
    return  (tabName === this.ngRedux.getState()['global']['currentTab'])
  }
}
