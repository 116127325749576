import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ITimeSpentStats, ISizeWithDiff } from '../../app.state'

import * as _ from 'lodash'
import * as util from '../../services/util.service'

const trace = util.traceToggle(true)

@Component({
  selector: 'sa-prod-stats',
  templateUrl: './prod-stats.component.html',
  styleUrls: ['./prod-stats.component.scss']
})
export class ProdStatsComponent implements OnInit {

  // Do not replace this, the template's using it because it has to reference a class member
  readonly u = util

  @Input() rightHeading: string
  @Input() leftHeading: string
  @Input() timeSpentStats$: Observable<ITimeSpentStats>
  totalTimeSpent: number = -1
  totalTimeSpentDiff: number = -1
  averageTimeSpent: number = -1
  averageTimeSpentDiff: number = -1

  constructor() {}

  ngOnInit() {
    this.timeSpentStats$.subscribe((state: ITimeSpentStats) => {
      this.totalTimeSpent = state.totalTimeSpent.size
      this.totalTimeSpentDiff = state.totalTimeSpent.diff
      this.averageTimeSpent = state.averageTimeSpent.size
      this.averageTimeSpentDiff = state.averageTimeSpent.diff
    })

  }

  readonly getPercentChangedColor = (change: number) => {
    return util.getPercentChangedColor(change)
  }
}
