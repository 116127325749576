import { Injectable } from '@angular/core'
import { InjectionToken } from '@angular/core'
import { NgRedux } from '@angular-redux/store'
import { Action } from 'redux'
import * as _ from 'lodash'

import { ITimeSpentState, IScoreRow, IEmployeeScoreRow } from '../../app.state'

import { DataService, TgetFunction } from '../../services/data.service'
import { mockEmployeeEmailAlgoScores } from '../../services/mocks.service'
import * as util from '../../services/util.service'
import { traceToggle } from '../../services/util.service';

const trace = util.traceToggle(false)

export interface ITimeSpentAction extends Action {
  names?: string[]
  scoresData?: IScoreRow[]
  employeesData?: IEmployeeScoreRow[]
  timePickerData?: any
  stats?: {
    total_time_spent: number,
    total_time_spent_diff: number,
    num_quantity_avg: number,
    num_quantity_diff: number
  }
}

@Injectable()
export class TimeSpentActions {

  static FETCH_TIME_SPENT         = 'fetch time spent'
  static FETCH_TIME_SPENT_STOPPED = 'fetch time spent stopped'
  static FETCH_TIME_SPENT_SUCCESS = 'fetch time spent success'
  static FETCH_TIME_SPENT_FAIL    = 'fetch time spent fail'

  static FETCH_EMPLOYEES         = 'fetch employees'
  static FETCH_EMPLOYEES_SUCCESS = 'fetch employees success'
  static FETCH_EMPLOYEES_FAILE   = 'fetch employees fail'

  static FILTER_EMPLOYEES        = 'filter employees'

  static FETCH_TIME_SPENT_VOLUME         = 'fetch volume'
  static FETCH_TIME_SPENT_VOLUME_SUCCESS = 'fetch volume success'

  static FETCH_TIME_SPENT_STATS         = 'fetch stats'
  static FETCH_TIME_SPENT_STATS_SUCCESS = 'fetch stats success'
  static FETCH_TIME_SPENT_STATS_FAIL    = 'fetch stats fail'

  protected actionPrefix = 'time spent'

  public constructor(
    public ngRedux: NgRedux<ITimeSpentState>,
    public ds: DataService
  ) {}

  /** The functions below should be implemented in the inheriting actions */
  public getTimeSpentVolume(params: {[key: string]: any}, successFunc: (data: any) => void): void {}
  public getTimeSpentStats(params: {[key: string]: any}, successFunc: (data: any) => void): void {}
  public getScoresData(params: {[key: string]: any}, successFunc: (data: any) => void): void {}
  public getEmployeesScores(params: {[key: string]: any}, successFunc: (data: any) => void): void {}

  protected constructActionType = (action: string): string => {
    return `${this.actionPrefix}-${action}`
  }

  fetchScores(params): void {
    trace('TimeSpentActions - In fetchScores()')
    if ( params['gids'].length === 0) {
      this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_STOPPED)})
      return
    }

    this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT)})
    this.getScoresData(params, (scoresData) => {
      this.fetchScoresSuccess(scoresData)
    })
  }

  private fetchScoresSuccess(scoresData): void {
    this.ngRedux.dispatch({
      type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_SUCCESS),
      scoresData: scoresData
    })
  }

  private fetchScoresFail(): void {
    this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_FAIL)})
  }

  fetchEmployees = (params): void => {
    trace('TimeSpentActions - In fetchEmployees() - params: ', params)
    if ( params['gids'].length === 0) {
      this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_STOPPED)})
      return
    }

    this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_EMPLOYEES)})
    this.getEmployeesScores(params, (empsData) => {
      this.fetchEmployeesSuccess(empsData)
    })
  }

  fetchEmployeesSuccess(empsData): void {
    this.ngRedux.dispatch({
      type: this.constructActionType(TimeSpentActions.FETCH_EMPLOYEES_SUCCESS),
      employeesData: empsData
    })
  }

  fetchEmployeesFail(): void {
    this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_EMPLOYEES_FAILE)})
  }

  fetchTimeSpentVolume(params): void {
    trace('TimeSpentActions - In fetchTimeSpentVolume() - start timepicker query')
    this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_VOLUME)})
    this.getTimeSpentVolume(params, (timeSpentVolume) => {
      trace('TimeSpentActions - Got time spent reply !!!!!!!!!!!!!!!!!!!!!!')
      this.fetchTimeSpentVolumeSuccess(timeSpentVolume)
    })
  }

  private fetchTimeSpentVolumeSuccess(timeSpentVolume): void {
    trace('TimeSpentActions - In fetchTimeSpentVolumeSuccess()')
    this.ngRedux.dispatch({
      type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_VOLUME_SUCCESS),
      timePickerData: timeSpentVolume
    })
  }

  fetchTimeSpentStats(params): void {
    this.ngRedux.dispatch({type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_STATS)})
    trace('TimeSpentActions - In fetchTimeSpentStats()')
    this.getTimeSpentStats(params, (timeSpent) => {
      this.fetchTimeSpentStatsSuccess(timeSpent)
    })
  }

  private fetchTimeSpentStatsSuccess(stats): void {
    this.ngRedux.dispatch({
      type: this.constructActionType(TimeSpentActions.FETCH_TIME_SPENT_STATS_SUCCESS),
      stats: stats
    })
  }
}
