﻿import { NgModule } from '@angular/core'

import {
  KlComponent,
  KlComponents,
  KlComponentsService
 } from './angular-keylines'

@NgModule({
  declarations: [
    KlComponent,
    KlComponents
  ],
  imports: [],
  providers: [KlComponentsService],
  exports: [
    KlComponent,
    KlComponents
  ]
})

export class KlModule {
  constructor() {}
}
