﻿import { Injectable } from '@angular/core';
import { AppActions } from '../app.actions'
import { InteractActions } from '../components/interact/interact.actions'

import * as util from './util.service'
import { DataService } from './data.service';

const trace = util.traceToggle(true)

@Injectable()
export class LogoutService {

  constructor(private appActions: AppActions, private interactActions: InteractActions, private ds: DataService) { }

  logout() {
    trace('Loggin out now')
    localStorage.removeItem('jwtToken')
    this.ds.clearCache()
    this.appActions.userSignedOutAction()
    this.interactActions.clearInteractUrl()
  }
}
