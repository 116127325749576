﻿import { NgModule } from '@angular/core';

import { ToPercentChangeFormatter } from './to-percent-change.formatter'
import { ToFloatRepresentationFormatter } from './to-float-representation.formatter'
import { AppendServerUrlFormatter } from './append-server-url.formatter'
import { ScoreToAverageFormatter } from './score-to-average.formatter'
import { DecimalToTimeFormatter } from './decimal-to-time.formatter'
import { KNotationFormatter } from './k-notation.formatter'
import { PercentFormatter } from './percent.formatter'


@NgModule({
  declarations: [
    ToPercentChangeFormatter,
    ToFloatRepresentationFormatter,
    AppendServerUrlFormatter,
    ScoreToAverageFormatter,
    DecimalToTimeFormatter,
    KNotationFormatter,
    PercentFormatter
  ],
  exports: [
    ToPercentChangeFormatter,
    ToFloatRepresentationFormatter,
    AppendServerUrlFormatter,
    ScoreToAverageFormatter,
    DecimalToTimeFormatter,
    KNotationFormatter,
    PercentFormatter
  ],
  providers: []
})
export class FormattersModule {
  constructor() {}
}
