import {
  InteractQuestState,
  IInteractState,
  INITIAL_STATE_INTERACT,
  InteractTab,
  InteractQuestTab,
  InteractCollaborationTab
} from '../../app.state'
import { traceToggle } from '../../services/util.service'

const trace = traceToggle(false)

export interface IUrlParams {
  openedTab: InteractTab,
  settingsTab: InteractQuestTab,
  collaborationTab: InteractCollaborationTab,
  activeQuestId: number,
  activeQuestionId: number,
  questIsOpened: boolean,
  hideNav: boolean,
  groups: string[]
}
export const INITIAL_PARAMS: IUrlParams = {
  openedTab: 2,                  // InteractTab.settings,
  settingsTab: 0,             // InteractQuestTab.quests,
  collaborationTab: 2,  // InteractCollaborationTab.questions,
  activeQuestId: -1,
  activeQuestionId: -1,
  questIsOpened: false,
  hideNav: false,
  groups: []
}

export const questionnaireStateToEnum = (s: any): InteractQuestState => {
  let ret = null
  switch (s) {
    case 'created':
      ret = InteractQuestState.created
      break
    case 'delivery_method_ready':
      ret = InteractQuestState.delivery_method_ready
      break
    case 'questions_ready':
      ret = InteractQuestState.questions_ready
      break
    case 'notstarted':
      ret = InteractQuestState.notstarted
      break
    case 'ready':
      ret = InteractQuestState.ready
      break
    case 'sent':
      ret = InteractQuestState.sent
      break
    case 'completed':
      ret = InteractQuestState.completed
      break
    default:
      throw new Error(`Illegal inetract state: ${s}`);
  }
  return ret
}

export const isInteractStateInUrlEmpty = (): boolean => {
  const params = window.location.href.split('?')
  if (params.length < 2) { return true }
  const paramsArr = params[1].split('&')
  if (paramsArr.length === 0) { return true }
  return false
}

/**
 * Parse the URL params into an IInteractState
 */
export const stateFromUrl = (): IUrlParams => {
  trace('util - interactStateFromUrl()')

  const params = window.location.href.split('?')
  if (params.length < 2) { return INITIAL_PARAMS }
  const paramsArr = params[1].split('&')
  if (paramsArr.length === 0) { return INITIAL_PARAMS }
  const paramsHash = {}

  trace('paramsArr: ', paramsArr)
  paramsArr.forEach((p) => {
    const paramKeyVal = p.split('=')
    if (paramKeyVal === undefined) { return }
    const paramKey = paramKeyVal[0]
    const paramVal = paramKeyVal[1]
    paramsHash[paramKey] = paramVal
  })

  const p: IUrlParams = {
    openedTab:                  parseInt(paramsHash['openedTab'], 10),
    settingsTab:             parseInt(paramsHash['settingsTab'], 10),
    collaborationTab:  parseInt(paramsHash['collaborationTab'], 10),
    activeQuestId:         parseInt(paramsHash['activeQuestId'], 10),
    activeQuestionId:  parseInt(paramsHash['activeQuestionId'], 10),
    hideNav:                       (paramsHash['hideNav'] === 'true'),
    questIsOpened:         paramsHash['questIsOpened'],
    groups:                         paramsHash['groups'].split(',')
  }
  return p
}

/**
 * Parse the URL params into an IInteractState
 */
export const interactStateFromUrl = (): IInteractState => {
  trace('util - interactStateFromUrl()')

  const params = window.location.href.split('?')
  if (params.length < 2) { return INITIAL_STATE_INTERACT }
  const paramsArr = params[1].split('&')
  if (paramsArr.length === 0) { return INITIAL_STATE_INTERACT }
  const paramsHash = {}

  trace('paramsArr: ', paramsArr)
  paramsArr.forEach((p) => {
    const paramKeyVal = p.split('=')
    if (paramKeyVal === undefined) { return }
    const paramKey = paramKeyVal[0]
    const paramVal = paramKeyVal[1]
    // trace('Setting param: ', paramKey, ', to val: ', paramVal)
    paramsHash[paramKey] = paramVal
  })

  const s: IInteractState = {
    openedTab:                  parseInt(paramsHash['openedTab'], 10),
    settingsTab:             parseInt(paramsHash['settingsTab'], 10),
    collaborationTab:  parseInt(paramsHash['collaborationTab'], 10),
    activeQuestId:         parseInt(paramsHash['activeQuestId'], 10),
    activeQuestionId:  parseInt(paramsHash['activeQuestionId'], 10),
    hideNav:                       (paramsHash['hideNav'] === 'true'),
    questIsOpened:        paramsHash['questIsOpened'],
    quests: INITIAL_STATE_INTERACT['quests'],
    participantStatus: INITIAL_STATE_INTERACT['participantStatus'],
    participants: INITIAL_STATE_INTERACT['participants'],
    participantsOffset: INITIAL_STATE_INTERACT['participantsOffset'],
    questions: INITIAL_STATE_INTERACT['questions'],
    fetchingParticipants: INITIAL_STATE_INTERACT['fetchingParticipants'],
    collaborationResults: INITIAL_STATE_INTERACT['collaborationResults'],
    collaborationMetricsResults: INITIAL_STATE_INTERACT['collaborationMetricsResults'],
     participantsSearchText: INITIAL_STATE_INTERACT['participantsSearchText']
  }
  return s
}

/**
 * Take IInteractState and set the URL params accordingly
 */
export const setInteractStateInUrl = (s: IInteractState, groups: number[] = []): void => {
  trace(`InteractComponent - setInteractStateInUrl() - activeQuestId=${s.activeQuestId}`)
  const urlPrefix = window.location.href.split('?')[0]
  const groupsStr = groups.join(',')
  const paramsStr = `openedTab=${s.openedTab}&
settingsTab=${s.settingsTab}&
collaborationTab=${s.collaborationTab}&
activeQuestId=${s.activeQuestId}&
activeQuestionId=${s.activeQuestionId}&
hideNav=${s.hideNav}&
questIsOpened=${s.questIsOpened}&
groups=${groupsStr}`

  const newUrl = `${urlPrefix}?${paramsStr}`
  window.location.href = newUrl
}

export const setStateInUrl = (p: IUrlParams): void => {
  trace(`InteractComponent - setInteractStateInUrl() - activeQuestId=${p.activeQuestId}`)
  const urlPrefix = window.location.href.split('?')[0]
  const groupsStr = p.groups.join(',')
  const paramsStr = `openedTab=${p.openedTab}&
settingsTab=${p.settingsTab}&
collaborationTab=${p.collaborationTab}&
activeQuestId=${p.activeQuestId}&
activeQuestionId=${p.activeQuestionId}&
hideNav=${p.hideNav}&
questIsOpened=${p.questIsOpened}&
groups=${groupsStr}`

  const newUrl = `${urlPrefix}?${paramsStr}`
  window.location.href = newUrl
}


/**
 * Update a specific parameter in the URL
 */
export const updateUrlParams = (key: string, value: any) => {
  const currentUrlParams = stateFromUrl()
  currentUrlParams[key] = value
  setStateInUrl(currentUrlParams)
}
