﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { IAppState } from '../../app.state'

import { InteractComponent } from './interact.component'
import { InteractSettingsNavComponent } from './settings-nav/interact-settings-nav.component'
import { InteractQuestionnairesComponent } from './questionnaires/interact-questionnaires.component'
import { InteractQuestComponent } from './quest/interact-quest.component'
import { InteractQuestionsComponent } from './questions/interact-questions.component'
import { InteractParticipantsComponent } from './participants/participants.component'
import { InteractTestComponent} from './test/interact-test.component'
import { CollpNavComponent } from './coll-nav/coll-nav.component'
import { InteractCollComponent } from './coll/interact-coll.component'

import { ErrorDirective } from './error.directive'

import { InteractActions } from './interact.actions'
import { CombineService } from '../map/combine.service'
import { MapActions } from '../map/map.actions'
import { MapComponent } from '../map/map.component'
import { MapNavComponent } from '../map/map-nav.component'

import { FormattersModule } from '../../formatters/formatters.module'
import { WidgetsModule  } from '../widgets/widgets.module'
import { KlModule } from '../keylines/kl.module'
import { MapModule } from '../map/map.module'
;
import { DataMappingComponent } from './data-mapping/data-mapping.component'
@NgModule({
  declarations: [
    InteractComponent,
    InteractSettingsNavComponent,
    InteractQuestionnairesComponent,
    InteractQuestComponent,
    InteractQuestionsComponent,
    InteractParticipantsComponent,
    InteractTestComponent,
    CollpNavComponent,
    InteractCollComponent,
    ErrorDirective
,
    DataMappingComponent  ],
  imports: [
    NgReduxModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FormattersModule,
    WidgetsModule,
    KlModule,
    MapModule
  ],
  providers: [
    InteractActions,
    CombineService,
    MapActions
  ],
  exports: [
    InteractComponent,
    InteractSettingsNavComponent
  ]
})

export class InteractModule {
  constructor(ngRedux: NgRedux<IAppState>) {}
}
