import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ElementRef, ViewChild } from '@angular/core'
import { Observable, fromEvent } from 'rxjs'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Subscription } from 'rxjs/Subscription'

import * as _ from 'lodash'
import * as Util from '../../services/util.service'

import { BarsTableUtilService } from './bars-table-util.service'
import { ITableHeader, ITableRow } from './bars-table-util.service'
import { KNotationFormatter } from '../../formatters/k-notation.formatter'

@Component({
  selector: 'sa-app-bars-table',
  templateUrl: './bars-table.component.html',
  styleUrls: ['./bars-table.component.scss']
})

export class BarsTableComponent implements OnInit, OnDestroy {

  @ViewChild('barsTable') table: ElementRef

  @Output() onSelectedSegments: EventEmitter<{row: string, column: string}> = new EventEmitter<{row: string, column: string}>()

  /** Data to display */
  @Input() datasetSubject: BehaviorSubject<any>
  datasetSubscription: Subscription

  /** Default widget color - as hex*/
  @Input() defaultdColorHex = '#B2B5B5'

  /** Main widget color - as hex*/
  @Input() mainColorHex: string

  /** Invert the bar direction in these column numbers - make right to left instead of left to right*/
  @Input() columnsToInvert: number[]

  /** Determines column width */
  @Input() colWidth: number

  /** Should headers be centered or left aligned */
  @Input() centerHeaders = false

  /** Display percentage with bars or not */
  @Input() displayPercentage = false

  @Input() showScores = false

  /** rgba string for highlighted row */
  highlightColor: string

  /** rgba string for highlighted cell */
  highlightedCellColor: string

  /** Table headers. Holds column name, and min/max values for each column */
  headers: ITableHeader[]

  /** Row categories + data to render */
  rows: ITableRow[]

  /** Currently selected row/col - combination is selected cell */
  selectedRow = -1
  selectedCol = -1

  /** The current sort order of the selected column. Asc = false, Desc = true */
  currentSortDesc = true

  /** Currently hovered row/col - combination is selected cell */
  hoveredRow = -1
  hoveredCol = -1

  barAnimationInterval: any

  /** is data ready for display */
  dataReady = false

  /** Make headers stick or not */
  isStickyHeader = false

  /** Binded to the stick header background */
  stickyHeaderBackgroundWidth: number

  isFirstScroll = false

  aggregatorDisplayStr = ''

  constructor() {
    Observable.fromEvent(window, 'resize')
      .debounceTime(50)
      .subscribe(() => {
        this.stickyHeaderBackgroundWidth = this.table.nativeElement.clientWidth
    })
  }

  ngOnInit() {
    this.highlightColor = Util.hex2rgb(this.mainColorHex, 0.1).str
    this.highlightedCellColor = Util.hex2rgb(this.mainColorHex, 0.3).str

    this.datasetSubscription = this.datasetSubject.subscribe(param => {
      if (param === undefined || param === null || Util.isNotDefinedOrEmpty(param.rawData)) {return}

      this.headers = BarsTableUtilService.setHeaders(param.rawData)
      this.rows = BarsTableUtilService.parseRawData(param.rawData, param.agg)
      this.aggregatorDisplayStr = Util.getAggregatorDisplayStr(param.agg)

      // Sort rows by first column by default
      const sortString = BarsTableUtilService.getSortString(true)
      this.rows = BarsTableUtilService.sortRowsByColumn(0, this.rows, sortString)
      this.dataReady = true
      this.barAnimationInterval = setInterval(() => this.animateBars(), 0)
    })
  }

  ngOnDestroy() {
    this.datasetSubscription.unsubscribe()
  }

  animateBars = () => {
    let stopAnimate = true
    _.forEach(this.rows, r => {
      _.forEach(r.scores, s => {
        if (s.current_length_percent < s.col_score_percent) {
          s.current_length_percent += 1
          stopAnimate = false
        }
      })
      if (stopAnimate) {
        clearInterval(this.barAnimationInterval)
      }
    })
  }

  onClickedHeader = (col_ind: number) => {
    if (col_ind < 0) {return}

    // Toggle sort - If it is the same header clicked - toggle asc/desc. If new header - set desc
    let sortBool = true
    if (col_ind === this.selectedCol) {
      this.currentSortDesc = !this.currentSortDesc
      sortBool = this.currentSortDesc
    }
    const sortOrderStr = BarsTableUtilService.getSortString(sortBool)

    this.selectedCol = col_ind

    let selectedRowCategoryBeforeSort = ''
    if (this.selectedRow !== -1) {
      // Save current selected row if exists
      selectedRowCategoryBeforeSort = this.rows[this.selectedRow].row_category
    }

    this.rows = BarsTableUtilService.sortRowsByColumn(this.selectedCol, this.rows, sortOrderStr)

    if (this.selectedRow !== -1) {
      // Find the new index of row after sort and set it
      this.selectedRow = BarsTableUtilService.getRowIndexByCategory(selectedRowCategoryBeforeSort, this.rows)
    }

    this.selectSection(this.selectedRow, this.selectedCol)
  }

  onClickedRowCategory = (row_ind: number) => {
    this.selectedRow = row_ind
    this.selectSection(this.selectedRow, this.selectedCol)
  }

  onClickedCell = (row_ind: number, col_ind: number) => {
    this.selectedRow = row_ind
    this.selectedCol = col_ind

    this.selectSection(this.selectedRow, this.selectedCol)
  }

  onScroll = (param: any) => {
    this.isStickyHeader = param.target.scrollTop > 1 ? true : false
    if (!this.isFirstScroll) {
      this.stickyHeaderBackgroundWidth = this.table.nativeElement.clientWidth
      this.isFirstScroll = true
    }
  }

  /**
   * Main method to update state after updating selected row/col locally. Must call this
   * method to notify subscribers of the new selection
   * @param row_ind - selected row
   * @param col_ind - selected col
   */
  selectSection = (row_ind: number, col_ind: number) => {
    const row_str = this.selectedRow !== -1 ? this.rows[this.selectedRow].row_category : ''
    const col_str = this.selectedCol !== -1 ? this.headers[this.selectedCol].id.toString() : ''
    const rowData: ITableRow = (this.selectedRow !== -1 ? this.rows[this.selectedRow] : null)
    const param = {row: row_str, column: col_str, rowData: rowData}
    this.emitSelection(param)
  }

  /**
   * Notify subscribers of this which cell/s is currently selected
   * @param rowCol - which row and column is now selected
   */
  emitSelection = (rowCol: {row: string, column: string}) => {
    this.onSelectedSegments.emit(rowCol)
  }

  removeRowSelection = () => {
    this.resetSelectedRow()
    this.selectSection(this.selectedRow, this.selectedCol)
  }

  removeColSelection = () => {
    this.resetSelectedCol()
    this.selectSection(this.selectedRow, this.selectedCol)
  }

  onCellEnter = (row: number, col: number) => {
    this.hoveredRow = row
    this.hoveredCol = col
  }

  onCellLeave = () => {
    this.hoveredRow = -1
    this.hoveredCol = -1
  }

  resetSelectedRow = () => {
    this.selectedRow = -1
  }

  resetSelectedCol = () => {
    this.selectedCol = -1
  }

  resetSelection = () => {
    this.resetSelectedRow()
    this.resetSelectedCol()
    this.selectSection(this.selectedRow, this.selectedCol)
  }

  revertColumn = (colIndex: number) => {
    if (this.columnsToInvert === undefined || this.columnsToInvert.length === 0) {return false}
    return _.find(this.columnsToInvert, c => c === colIndex) !== undefined
  }

  displayScore = () => {
    return this.showScores
  }
}
