import { Component, OnInit} from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';

import {
  IGlobalState,
  IScoreRow,
  ITimeSpentStats,
  IEmployeeScoreRow
 } from '../../app.state'

import { AppActions } from '../../app.actions'
import { EmailsActions } from './emails.actions'
import { TimeSpentComponent } from './timespent.component';

import { EMAILS_VOLUME } from '../../constants'



@Component({
  selector: 'sa-emails',
  templateUrl: './timespent.component.html',
  styleUrls: ['./timespent.component.scss']
})
export class EmailsComponent extends TimeSpentComponent implements OnInit {

  tsActions: EmailsActions

  @select(['emails', 'timePickerData']) readonly emailTimePickerData$: Observable<any>;
  @select(['emails', 'scores']) readonly emailScores$: Observable<IScoreRow[]>;
  @select(['emails', 'timeSpent']) readonly emailTimeSpent$: Observable<ITimeSpentStats>;
  @select(['emails', 'employeeScores']) readonly employeeScores$: Observable<IEmployeeScoreRow[]>

  timeSpentAid = EMAILS_VOLUME

  leftStatHeading = 'Total time spent'
  rightStatHeading = 'Avg. of recipient per email'

  constructor(
    public actions: AppActions,
    public timeSpentActions: EmailsActions,
    public ngRedux: NgRedux<IGlobalState>
  ) {
    super(actions, timeSpentActions, ngRedux)

    this.tabColor = '#4577A9';
    this.tsActions = this.timeSpentActions

    this.timePickerData$ = this.emailTimePickerData$
    this.timeSpentScores$ = this.emailScores$
    this.timeSpent$ = this.emailTimeSpent$
  }
}
