﻿import { containsElement } from '@angular/animations/browser/src/render/shared'
import { IUser } from '../../app.state'


export enum UserType { NotDefined,SuperAdmin, Admin, HR, Manager }

export class UserModel implements IUser {
  email: string
  first_name: string
  last_name: string
  user_type: string
  reports_encryption_key: string
  session_timeout: number
  password_update_interval: number
  max_login_attempts: number
  required_chars_in_password: any
  product_type: string
  is_allowed_create_questionnaire: boolean
  is_allowed_add_users: boolean
  


  constructor(data: any) {
    this.email = data.email
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.user_type = data.user_type
    this.reports_encryption_key = data.reports_encryption_key
    this.session_timeout = data.session_timeout
    this.password_update_interval = data.password_update_interval
    this.max_login_attempts = data.max_login_attempts
    this.required_chars_in_password = data.required_chars_in_password
    this.product_type = data.product_type
    this.is_allowed_create_questionnaire = data.is_allowed_create_questionnaire
    this.is_allowed_add_users = data.is_allowed_add_users
  }

  /** Use this to get the user type as enum */
  getUserTypeEnum(): UserType {
    let type: UserType = UserType.NotDefined
    switch (this.user_type) {
    case 'super_admin':
      type = UserType.SuperAdmin
    break
    case 'admin':
      type = UserType.Admin
    break
    case 'hr':
      type = UserType.HR
      break
    case 'manager':
      type = UserType.Manager
      break
    }
    return type
}

  isEncryptReport(): boolean {
    return (this.reports_encryption_key !== undefined && this.reports_encryption_key !== null && this.reports_encryption_key !== '')
  }

  hasAdminPrivileges() {
    return this.getUserTypeEnum() === UserType.SuperAdmin || this.getUserTypeEnum() === UserType.Admin 
  }
  hasManageUsersPrivileges() {
    return this.getUserTypeEnum() === UserType.SuperAdmin || (this.getUserTypeEnum() === UserType.Admin && this.is_allowed_add_users)
  }

  hasSuperAdminPrivileges() {
    return this.getUserTypeEnum() === UserType.SuperAdmin
  }

  isAthorizedToCreateQuestionnaire() {
    console.log("llllll")
    console.log(this.getUserTypeEnum() === UserType.Admin && this.is_allowed_create_questionnaire)
    return (this.getUserTypeEnum() === UserType.Admin && this.is_allowed_create_questionnaire) || this.getUserTypeEnum() === UserType.SuperAdmin
  }
}
