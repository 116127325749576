import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  @Output() sliderChanged: EventEmitter<string>
  @Input() value: number
  @Input() min: number
  @Input() max: number
  @Input() step: number
  
  constructor() {
    this.sliderChanged = new EventEmitter()
   }
  
  formatLabel(value: number) {
    // this.textValue = "K = " + String(value);
  //  if (value >= 1000) {
    //  return Math.round(value / 1000) + 'k';
  //  }
    return value;
  }
  ngOnInit() {
  }
  onSlide(event) {
    this.sliderChanged.emit(event.value)
  }

}
