﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

import { LogoutService } from '../../services/logout.service'

@Component({
  selector: 'sa-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(private logoutService: LogoutService, private router: Router) {}

  ngOnInit() {}

  signout(): void {
    this.logoutService.logout();
    this.router.navigate(['login'])
  }
}
