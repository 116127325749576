import * as _ from 'lodash'
import * as util from '../../services/util.service'
import { TenBucket } from '../../types/ten-bucket'

import { IInterfacesAction, InterfacesActions } from './interfaces.actions';
import {
  IInterfacesState,
  INITIAL_STATE_INTERFACES,
  IMapData,
  IGroupsHashMap,
  IGroup,
  IInterfacesRow,
  IDynamicsProdStats
} from '../../app.state'
import * as graphService from '../../services/graph.service'
import { normalizeLinks } from '../../services/graph.service';
import { utils } from 'protractor';
import { BUCKET_OF_10 } from '../../constants';


const trace = util.traceToggle(false);

export function interfacesReducer(lastState: IInterfacesState, action: IInterfacesAction): IInterfacesState {
  if (lastState === undefined) { return INITIAL_STATE_INTERFACES}
  switch (action.type) {
    case InterfacesActions.FETCH_INTERFACES_STATS:
      trace('InterfacesReducer - FETCH_INTERFACES_STATS');
      return lastState;

    case InterfacesActions.FETCH_INTERFACES_STATS_SUCCESS:
      trace('InterfacesReducer - FETCH_INTERFACES_STATS_SUCCESS: ', action.prodStats);
      const closeness = TenBucket.createFromPercent(100 - action.prodStats.closeness)
      const synergy = TenBucket.createFromRatio(action.prodStats.synergy)
      return Object.assign({}, lastState, {prodStats: {
                                                       closeness: closeness,
                                                       synergy: synergy
                                                      } });

    case InterfacesActions.FETCH_INTERFACES_TIME_PICKER_DATA:
      trace('InterfacesReducer - FETCH_INTERFACES_TIME_PICKER_DATA');
      return lastState;
    case InterfacesActions.FETCH_INTERFACES_TIME_PICKER_DATA_SUCCESS:
      trace('InterfacesReducer - FETCH_INTERFACES_TIME_PICKER_DATA_SUCCESS');
      return Object.assign({}, lastState, {timePickerData: action.timePickerData});
    case InterfacesActions.AGGREGATOR_TYPE_CHANGED:
      trace('InterfacesReducer - AGGREGATOR_TYPE_CHANGED');
      return Object.assign({}, lastState, {aggregator: action.aggregator});
    case InterfacesActions.USER_TOGGLED_VIEW_STATE:
      trace('InterfacesReducer - USER_TOGGLED_VIEW_STATE');
      return Object.assign({}, lastState, {selectedView: action.selectedView});
    case InterfacesActions.FETCH_INTERFACES_SCORES:
      trace('InterfacesReducer - FETCH_INTERFACES_SCORES');
      return lastState;

    case InterfacesActions.FETCH_INTERFACES_SCORES_SUCCESS:
      trace('InterfacesReducer - FETCH_INTERFACES_SCORES_SUCCESS');
      const selected = action.scores[0]
      if (selected === undefined) {return lastState}
      const selectedId = selected.gid
      let empsNumber = empsNumberFromGid(action.groupsArr, selectedId)
      let selectedGroupName = groupNameFromGid(action.groupsArr, selectedId)
      let coll = TenBucket.createFromPercent(100 - selected.receiving)
      let syn = TenBucket.createFromRatio(selected.volume / (selected.volume + selected.intraffic))
      let leaderboardStats: IDynamicsProdStats = {
        closeness: coll,
        synergy: syn
      }

      return Object.assign({}, lastState,
                           {scores: action.scores,
                            selectedRow: selectedId,
                            empsNum: empsNumber,
                            selectedGroupName: selectedGroupName,
                            intraffic: selected.intraffic,
                            outTraffic: selected.volume,
                            leaderboardStats: leaderboardStats
                           }
              );

    case InterfacesActions.USER_SELECTED_ROW:
      trace('InterfacesReducer - USER_SELECTED_ROW');
      empsNumber = empsNumberFromGid(action.groupsArr, action.selectedRow)
      selectedGroupName = groupNameFromGid(action.groupsArr, action.selectedRow)
      coll = TenBucket.createFromPercent(100 - action.receiving)
      syn = TenBucket.createFromRatio(action.volume / (action.volume + action.intraffic))
      leaderboardStats = {
        closeness: coll,
        synergy: syn
      }
      return Object.assign({}, lastState,
                           {selectedRow: action.selectedRow,
                            empsNum: empsNumber,
                            selectedGroupName: selectedGroupName,
                            intraffic: action.intraffic,
                            outTraffic: action.volume,
                            leaderboardStats: leaderboardStats
                           }
              );

    case InterfacesActions.FETCH_INTERFACES_MAP:
      trace('InterfacesReducer - FETCH_INTERFACES_MAP');
      return lastState
    case InterfacesActions.FETCH_INTERFACES_MAP_SUCCESS:
      trace('InterfacesReducer - FETCH_INTERFACES_MAP_SUCCESS');
      let newState1 = addMapDataToState(lastState, action.mapData)
      newState1 = addLeaderboardDataToState(newState1, action.mapData)
      return newState1
    default:
      return lastState;
  }
}

const empsNumberFromGid = (groupsArr: IGroup[], gid: number): number => {
  const grp: IGroup = _.find(groupsArr, g => g.gid === gid)
  if (grp === undefined) { return 0 }
  return grp.accumulatedSize
}

const groupNameFromGid = (groupsArr: IGroup[], gid: number): string => {
  const grp: IGroup = _.find(groupsArr, g => g.gid === gid)
  if (grp === undefined) { return null }
  return grp.name
}

/**
 * Create a 'digestable' array for the groups' leaderboard
 */
const addLeaderboardDataToState = (lastState: IInterfacesState,
  mapResult: {nodes: any[],
              links: any[],
              selected_group?: string}): IInterfacesState => {

  const cgid: number = parseInt(mapResult.selected_group, 0)

  let rows: IInterfacesRow[] = _.map(mapResult.nodes, (n) => {
    return {
      name: n.name,
      gid: n.id,
      sending: 0,
      receiving: 0,
      volume: 0,
      intraffic: 0,
      hierarchy_size: -1
    }
  })

  const links = mapResult.links
  let totalSending = 0
  let totalReceiving = 0

  let intSnd = 0
  let intRcv = 0
  let extSnd = 0
  let extRcv = 0

  _.forEach(links, (l) => {
    if (l.source === cgid && l.target === cgid) {
      totalSending += l.volume
      totalReceiving += l.volume

      intSnd += l.volume
      intRcv += l.volume

    } else if (l.source === cgid) {
      totalSending += l.volume
      extSnd += l.volume
    } else if (l.target === cgid) {
      totalReceiving += l.volume
      extRcv += l.volume
    }
  })
  trace('extSnd: ', extSnd, ', extRcv: ', extRcv, ', intSend: ', intSnd, ', intRec: ', intRcv)

  /** Remove rows with very small traffic **/
  const others: IInterfacesRow = {
    name: 'Others',
    gid: -1,
    sending: 0,
    receiving: 0,
    volume: 0,
    intraffic: 0,
    hierarchy_size: -1
  }

  let row: IInterfacesRow
  _.forEach(links, (l) => {

    if (l.source === cgid && l.target === cgid) {
      row = _.find(rows, (r) => r.gid === l.target )
      row.sending = util.round1(100 * l.volume / totalSending)
      row.receiving = util.round1(100 * l.volume / totalReceiving)

    } else if (l.source === cgid && l.target !== -1) {
      row = _.find(rows, (r) => r.gid === l.target )
      row.sending = util.round1(100 * l.volume / totalSending)

    } else if (l.source !== -1 && l.target === cgid) {
      row = _.find(rows, (r) => r.gid === l.source )
      row.receiving = util.round1(100 * l.volume / totalReceiving)

    } else if (l.source === cgid && l.target === -1) {
      others.sending = util.round1(100 * l.volume / totalSending)

    } else if (l.source === -1 && l.target === cgid) {
      others.receiving = util.round1(100 * l.volume / totalReceiving)
    }
  })

  // Sort
  rows = _.sortBy(rows, [(o) => (o.sending * (-1)) ])

  /** place the selected group at the head of the list and others at the end */
  const crow: IInterfacesRow[] = _.remove(rows, r => r.gid === cgid )
  crow[0].selected = true
  const res = crow.concat(rows).concat(others)

  return Object.assign({}, lastState, {leaderboard: res});
}

/**
 * Create the keylines data structure
 */
const addMapDataToState = (lastState: IInterfacesState,
                           mapResult: {nodes: any[],
                                       links: any[],
                                       selected_group?: string}): IInterfacesState => {

  const nodes: IMapData[] =
    _.map(mapResult.nodes, (n) => {

      const node = {
        type: 'node',
        id: `G-${n.id}`,
        t: n.name,
        c: `#${n.col}`,
        group_name: n.group_name,
        hierarchy_size: n.hierarchy_size,
        group_size: n.group_size,
        u: '/assets/images/group.svg',
        d: 1,
        e: 1
      }

      // Place a halo (round ring) around the root employee
      if (mapResult.selected_group === n.id ) {
        node['ha0'] = {
          c: 'rgb(16, 123, 118)',
          r: 35,
          w: 10
        }
      }

      return node
    })

  let links: IMapData[] =
  _.map(mapResult.links, (n) => {
    const lnk = {
      type: 'link',
      id: `L-${n.source}-${n.target}`,
      id1: `G-${n.source}`,
      id2: `G-${n.target}`,
      w: n.volume,
      // a1: true,
      a2: true,
    }
    return lnk
  })

  // Links post handling
  links = graphService.normalizeLinks(links)
  links = graphService.unifyLinks(links)
  links = graphService.colorLinks(links, mapResult.selected_group)

  return Object.assign({}, lastState, {mapData: nodes.concat(links)});
}
