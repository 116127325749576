import { Component, Input, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms'

import * as _ from 'lodash'

import * as Texts from '../../../texts'
import { InteractActions } from '../interact.actions'
import {
  IQuest, InteractQuestState, InteractDeliveryMethod, InteractHideNames
} from '../../../app.state'

const LANGUAGES = [
  'English',
  'Hebrew'
]

@Component({
  selector: 'sa-app-int-quest',
  templateUrl: './interact-quest.component.html',
  styleUrls: ['./interact-quest.component.scss']
})
export class InteractQuestComponent implements OnInit{
  languages = LANGUAGES

  @Input() questionnaire = <IQuest>null

  questForm: FormGroup = this.fb.group({
    'name': [''],
    'delivery_method': InteractDeliveryMethod.email,
    'sms_text': [''],
    'email_text': [''],
    'email_subject': [''],
    'hideNames': [InteractHideNames.show]
  })

  /**
   * This is set to "Next" if it's the first time (like a wizard), and is set
   *  to "Save" otherwize (edit mode)
   */
  submitButtonLabel: string

  constructor(
    private interactActions: InteractActions,
    private fb: FormBuilder) {
  }

  /**
   * Check the local variable against the form, update it and submit.
   */
  onSubmit = (form: FormGroup) => {

    _.each(form.controls, (v, k) => {
      if (v.dirty) { this.questionnaire[k] = v.value }
    })
    this.interactActions.updateQuestionnaire(this.questionnaire)
  }

  ngOnInit() {
    /** Set up the language array */
    if (this.questionnaire) {
      // const lang = this.languages.splice(this.questionnaire.language_id - 1, 1)
      // this.languages.unshift( lang[0] )

      /** Set up the form here */
      this.questForm = this.fb.group({
        'name': ['', Validators.required],
        'delivery_method': [this.questionnaire.delivery_method],
        'sms_text': [
          this.questionnaire.sms_text,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200)
          ])],
        'email_text': [
          this.questionnaire.email_text,
          Validators.compose([
            Validators.required,
            Validators.maxLength(400)
          ])],
        'email_subject': [
          this.questionnaire.email_subject,
          Validators.compose([
            Validators.required,
            Validators.maxLength(100)
          ])],
        'hideNames': [this.questionnaire.hide_names]
      })

      /** Submit lable */
      this.submitButtonLabel = (this.questionnaire.state === InteractQuestState.created) ? 'NEXT' : 'SAVE'
    }
  }

  languageWasSelected = ($event) => {
    const inx = LANGUAGES.findIndex(e => e === $event) + 1
    this.questionnaire.language_id = inx
  }
  getLangsList  = (lang) => {
    if(lang == 1)
      lang = 'English'
    else if(lang ==2)
      lang = 'Hebrew'
    let myClonedLangs  = Object.assign([], this.languages);
    myClonedLangs = _.pull(myClonedLangs, lang)
    myClonedLangs.unshift(lang)
    return myClonedLangs
  }
}