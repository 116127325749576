﻿import { Component, OnInit, ElementRef } from '@angular/core'
import * as _ from 'lodash'
import { Observable, fromEvent } from 'rxjs'
// import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/fromEvent'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/do'
import 'rxjs/add/operator/switch'
import 'rxjs/add/operator/startWith'

import { select } from '@angular-redux/store'

import { IGroupsState, IGroup } from '../../app.state'
import { AppActions } from '../../app.actions'
import { NavActions } from './nav.actions'
import { DataService } from '../../services/data.service'
import * as util from '../../services/util.service'
import { UserModel } from '../user/user.model'

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @select(['global', 'navOpened']) readonly navOpened$: Observable<string>
  @select(['global', 'currentUser']) readonly currentUser$: Observable<any>
  @select(['groups']) readonly groupsTreeState$: Observable<IGroupsState>
  @select(['groups', 'numberOfSelected']) readonly numberSelected$: Observable<number>
  @select(['global', 'waitingForReport']) readonly waitingForReport$: Observable<boolean>
  @select(['global', 'currentTab']) readonly currentTab$: Observable<string>
  currentTab = ''

  groupsTree: any[]
  allGroups: IGroup[]

  constructor(
    private appActions: AppActions,
    private navActions: NavActions,
    private me: ElementRef,
    private ds: DataService) {

    this.groupsTreeState$.subscribe(state => {
      this.groupsTree = _.map(state.groups, (e) => e ).filter((e: IGroup) => !e.isHidden)
      this.allGroups = state.groups
    })

    Observable.fromEvent(this.me.nativeElement, 'keyup')
      .map((e: any) => e.target.value)
      .startWith('')
      .filter((text: string) => text.length > 1 || text.length === 0)
      .debounceTime(500)
      .subscribe((text: string) => {
        navActions.groupSearChanged(text)
    })
  }

  ngOnInit() {

    this.currentUser$.subscribe( (state: UserModel) => {
      if (state.email !== undefined && this.currentTab !== ''  && this.currentTab !== 'interact') {
        this.navActions.fetchGroups()
      }
    })

    this.currentTab$.subscribe( state => {
      this.currentTab = state
    })
  }

  selectorClass = (e) => {
    if (e.isSelected) { return '/assets/images/ic_dd_selection.svg' }
    if (!e.isSelected) { return '/assets/images/ic_dd_no-selection.svg' }
    throw new Error('Illegal group state for group: ' + e.id)
  }

  expanderClass = (e) => {
    if (e.isExpanded) { return '/assets/images/ic_dd_open2.svg'}
    return '/assets/images/ic_dd_close2.svg'
  }

  displayReportsButton = (currTab: string): boolean => {
    if (currTab === 'emails' || currTab === 'dynamics') { return true }
    return false
  }

  ///////////////////////////// Events ////////////////////////////
  navClicked = () => {
    this.appActions.navBarToggled()
  }

  reportDownLoadClicked = () => {
    this.appActions.downLoadReport()
  }

  isLeaf = (e) => {
    return e.childrenIds.length === 0
  }

  setTitle = (e: IGroup): string => {
    const parent = this.allGroups[e.parentId]
    if (parent === undefined) { return e.name }
    return `${parent.name} / ${e.name}`
  }
}
