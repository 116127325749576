import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'sa-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: {'class': 'someClass1'}
})
export class DropDownComponent implements OnInit {
  filteredList: string[]
  listIsOpened: boolean
  wasInside: boolean

  @Input() selectedItem: string
  @Input() itemsList: string[]
  @Input() selectedAction: any

  @HostListener('click')
  clicked() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickedOut() {
    if(!this.wasInside){
      this.listIsOpened=false
    }
    this.wasInside = false;
  }

  constructor() {
    this.listIsOpened = false
  }

  ngOnInit() {
    this.filteredList = this.itemsList
  }

  itemSelected(item) {
    this.selectedAction(item)
    this.filteredList = this.itemsList
    return false
  }

  arrowClicked() {
    this.listIsOpened = !this.listIsOpened
    this.wasInside = true
  }

}
