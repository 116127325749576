import { Injectable } from '@angular/core';

import * as Util from '../../services/util.service'

import * as Consts from '../../constants'

export class DataPoint {
  xPos: number;
  value: number;
  yPosPercent: number;
  label: string;

  constructor(data?: any) {
    if (data === undefined) {return}
    this.xPos = data.xPos;
    this.value = data.value;
    this.yPosPercent = data.yPosPercent;
    this.label = data.label;
  }
}

@Injectable()
export class TimePickerUtilService {

  /** Find min/max values to display on Y axis */
  static calcYAxisMinMax(min: number, max: number, stepSize: number): {min: number, max: number} {
    const res: {min: number, max: number } = { min: -1, max: -1};
    res.min = min - min % stepSize;
    if (res.min < 0) { res.min = 0 } // If negative, set min value to 0

    res.max = (max + stepSize) - max % stepSize;
    return res;
  }

  static calcYAxisStepSize(min: number, max: number): number {
    if (min > max) {return -1}

    const range = max - min;
    let stepSize = 1;
    if (range >= 0 && range < 1) {
      stepSize = 1;
    } else if (range >= 1 && range < 10) {
      stepSize = 2;
    } else if (range >= 10 && range < 50) {
      stepSize = 5;
    } else if (range >= 50) {
      stepSize = 10;
    }
    return stepSize;
  }

  static getYCoorByPercentageValue(canvasHeight: number,  yPercent: number, chartHeight: number, xAxisLegendHeight: number, marginFromTop: number): number {
    const activeAreaHeight = canvasHeight - xAxisLegendHeight - marginFromTop
    return canvasHeight - yPercent * activeAreaHeight - xAxisLegendHeight;
  }

  static getXPosForPoint(id: number, interval: number, shift: number = 0 ) {
    if (id < 0) {return -1}
    return (id * interval) + shift;
  }

  static getHightlightTextXShift(text: string): number {
    const textLength = text.length;
    let shift = 0;

    switch (textLength) {
      case 1:
      shift = -5;
      break;
      case 2:
      shift = -10;
      break;
      case 3:
      shift = -15;
      break;
      case 4:
      shift = -20;
      break;
      case 5:
      shift = -25;
      break;
      case 6:
      shift = -30;
      break;
    }
    return shift;
  }

  static getHighlightTextYPos(point: DataPoint, canvasHeight: number,  chartHeight: number, xAxisLegendHeight: number, marginFromTop: number): number {
    const pos = (point.yPosPercent > 0.5 ? -0.6 : 0.35 ) + point.yPosPercent;
    return TimePickerUtilService.getYCoorByPercentageValue(canvasHeight, pos, chartHeight, xAxisLegendHeight, marginFromTop);
  }
}
