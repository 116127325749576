import { Pipe, PipeTransform } from '@angular/core';
import * as util from '../services/util.service'
import { AjaxService } from '../services/ajax.service'

@Pipe({name: 'appendServerUrl'})
export class AppendServerUrlFormatter implements PipeTransform {
  transform(value: string): string {
    if (value === null) { return '/assets/images/missing.jpg'}
    if ( value.startsWith('http') ) { return value }
    return `${AjaxService.getServerUrl()}${value}`
  }
}
