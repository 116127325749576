import {
  Component,
  OnInit,
  Input
} from '@angular/core'

import * as _ from 'lodash'

import { InteractActions } from '../interact.actions'
import { IInteractCollaborationReportRow, IInteractCollaborationMetricsReportRow } from '../../../app.state'
import * as util from '../../../services/util.service'

export interface IProcessedReportRow {
  name?: string
  group_name: string
  score: number
  group_size?: number
  color?: string
}

@Component({
  selector: 'sa-app-int-coll',
  templateUrl: './interact-coll.component.html',
  styleUrls: ['./interact-coll.component.scss']
})
export class InteractCollComponent implements OnInit {

  @Input() set report (rep: IInteractCollaborationReportRow[]) {
    if (rep.length === 0) {return}
    this.origReport = rep
    this.processedReport = InteractCollComponent.processReport(rep, this.aggregator)
    this.maxScore = _.maxBy(this.processedReport, 'score').score
    this.avgScore = InteractCollComponent.calculateAverageScore(this.processedReport)
  }

  @Input() set networkMetricsReport (rep: IInteractCollaborationMetricsReportRow[]) {
 //   if (rep.length === 0) {return}
    this.origNetworkMetricsReport = rep
    let copy = JSON.parse(JSON.stringify(this.origNetworkMetricsReport))
    this.processedNetworkReport = InteractCollComponent.processNetworkReport(copy, this.aggregator,this.params)

  }
  @Input() set activeParams(actives: any){
    this.origActiveParams = actives
  }
  @Input() set sliderVal(val: number){
    this.sliderValue = val
    this.sliderTextValue = "K = " + String(val)
  }

  @Input() collaborationScore: number
  @Input() synergyScore: number
  @Input() centralityScore: number
  @Input() activeQuestId: number
  @Input() activeGids: number[]
  @Input() activeQuestionId: number

  origActiveParams: any
  origNetworkMetricsReport: IInteractCollaborationMetricsReportRow[]
  processedNetworkReport: IInteractCollaborationMetricsReportRow[]
  origReport: IInteractCollaborationReportRow[]
  processedReport: IProcessedReportRow[]
  maxScore: number
  avgScore: number

  aggregator = 'Employees'
  aggregatorsList = ['Employees', 'Departments']

  indices = 'Internal Champions'
  indicesList = ['Internal Champions','Isolates','Connectors', 'Bottlenecks','New Internal Champions','New Connectors']
  params = ['office','gender','rank','group','general','param_a','param_b','param_c','param_d','param_e','param_f','param_g','param_h','param_i','param_j']
  mode = 'questionnaireOnly'
  
  sliderTextValue: string
  sliderValue: number
  sliderMin = 0
  sliderMax = 2
  sliderStep = 0.1
  // =========================== Static functions ============================ //
  static calculateAverageScore = (report: IProcessedReportRow[]): number => {
    const res = report.length > 0 ? _.sumBy(report, 'score') / report.length : 0
    return util.round2(res)
  }

  static processReport = (rep: IInteractCollaborationReportRow[], agg: string): IProcessedReportRow[] => {
    if (agg === 'Employees') { return rep}

    const groupsInx: {[key: string]: IProcessedReportRow} = {}
    _.each(rep, (r) => {
      if (groupsInx[r.group_name] === undefined) {
        groupsInx[r.group_name] = {
          group_name: r.group_name,
          score: r.score,
          group_size: 1,
          color: `#${r.color}`
        }
      } else {
        groupsInx[r.group_name].score += r.score
        groupsInx[r.group_name].group_size += 1
      }
    })
    const groupsArr: IProcessedReportRow[] = _.values(groupsInx)
    return _.sortBy( groupsArr , [(o) => -o.score] )
  }

  static processNetworkReport = (rep: IInteractCollaborationMetricsReportRow[], agg: string, params: string[]): IInteractCollaborationMetricsReportRow[] => {
    if (agg === 'Employees') { return rep}
    const groupsInx: {[key: string]: IInteractCollaborationMetricsReportRow} = {}
    var groupName: string
    _.each(rep, (r) => {
      groupName = `${r.group_name}-${r.algorithm_name}`
      if (groupsInx[groupName] === undefined) {
        groupsInx[groupName] = r
        groupsInx[groupName].group_size = 1
        groupsInx[groupName].name = groupName
      } 
      else {
        groupsInx[groupName].group_size += 1
        _.each(params, (p) => { groupsInx[groupName][p] = Number(groupsInx[groupName][p]) + Number(r[p]) })
      }
    })
    _.each(groupsInx,(g) => {
      _.each(params, (p) => { g[p] = util.round3(Number(g[p]) / Number(g.group_size)) })
    })
    const groupsArr: IInteractCollaborationMetricsReportRow[] = _.values(groupsInx)
    return groupsArr


  }

  constructor(private interactActions: InteractActions) {}

  ngOnInit() {
    this.indices = 'Internal Champions'
  }

  aggregatorSelected = (agg) => {
    this.aggregator = agg
    this.processedReport = InteractCollComponent.processReport(this.origReport, this.aggregator)
    this.maxScore = _.maxBy(this.processedReport, 'score').score
    this.avgScore = InteractCollComponent.calculateAverageScore(this.processedReport)
    if(this.mode == 'questionnaireOnly'){
      let copy = JSON.parse(JSON.stringify(this.origNetworkMetricsReport))
      this.processedNetworkReport = InteractCollComponent.processNetworkReport(copy, this.aggregator,this.params)
    }
  }
  indiceSelected = (indice) => {
    this.indices = indice
  }

  factorChanged(event: string) {
    this.sliderValue = Number(event)
    this.sliderTextValue = "K = " + String(event);
  }

  saveKFactor(){
    this.interactActions.saveKFactor(this.activeQuestId,this.sliderValue,this.activeQuestionId,this.activeGids)
  }

  getRelativeWidth = (score: number): number => {
    if (_.isNaN(score)) {return 0}
    return 100 * (score / this.maxScore)
  }

  getColorNumber = (color: string) => {
    if (this.aggregator === 'Employees') {return '#FBB03B'}
    return color
  }

  getBorderStyle = (color: string) => {
    if (this.aggregator === 'Employees') {return 'solid 2px #FBB03B'}
    return `solid 2px ${color}`
  }
  selectedMetric = () => {
    switch(this.indices){
      case 'Internal Champions': { 
        return 'internal_champion'
      }
      case 'Connectors': { 
        return 'connectors'
      }
      case 'Isolates': { 
        return 'isolated'
      }
      case 'New Internal Champions': {
        return 'new_internal_champion'
      }
      case 'New Connectors': {
        return 'new_connectors'
      }
      default:
        return 'internal_champion'
    }
  }
}
