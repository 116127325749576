import { Pipe, PipeTransform } from '@angular/core';
import * as util from '../services/util.service'

@Pipe({name: 'kNotation'})
export class KNotationFormatter implements PipeTransform {
  transform(value: number): string {
    if (value === null) { return null }
    if (value < 1000) { return `${value}` }
    return `${util.round1(value / 1000)}K`
  }
}
