﻿import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { NgReduxModule} from '@angular-redux/store'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { KlModule } from '../keylines/kl.module'
import { WidgetsModule } from '../widgets/widgets.module'

import { MapComponent } from './map.component'
import { MapNavComponent } from './map-nav.component'

import { MapActions } from './map.actions'
import { CombineService } from './combine.service'

@NgModule({
  declarations: [
    MapComponent,
    MapNavComponent
  ],
  imports: [
    NgReduxModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetsModule,
    KlModule
  ],
  providers: [
    MapActions,
  ],
  exports: [
    MapComponent,
    MapNavComponent
  ]
})

export class MapModule {
  constructor() {}
}
